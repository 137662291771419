<template>
  <ui-popup style-for-body="width: 95%; padding: 10px" @close="() => emit('close')">
    <div class="cursor-pointer" style="float: right; margin-right: -5px; margin-top: -5px;" @click="() => emit('close')">
        <img src="/images/close.png" alt="">
    </div>

    <h5 class="text-center">Пересчет объектов</h5>
    
    <div class="top-settings">
        <div class="search">
            <label>Найти</label>
            <input v-model="search" class="form-control" type="search" placeholder="Поиск по всем столбцам">
        </div>

        <div class="interval">
            <label>
                Интервал
            </label>
            <ui-double-datepicker
                class="datepicker"
                style="display: flex; align-items: center;"
                v-model:date="datepickerDate"
            />
        </div>
    </div>

    <label>
        Список объектов
    </label>
    <div style="max-height: 30vh; overflow-y: scroll;">
        <table class="sortable-table freezeTable-table">
            <thead>
                <tr>
                    <th style="width: 15px; text-align: center;">
                        <input v-model="selectedAll" type="checkbox">
                    </th>
                    <th v-for="col of TABLE_HEADER" :key="col.name">{{ col.label }}</th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="obj in objectsForSelect" :key="obj.id">
                    <td style="text-align: center;">
                        <input v-model="objects" type="checkbox" :id="obj.id" :value="obj.id">
                    </td>
                    <td v-for="col of TABLE_HEADER" :key="obj.id + col.name" >
                        {{obj[col.name]}}
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
    <div v-if="objects.length" style="max-height: 20vh; overflow-y: auto;">
        <label>
            Выбранные объекты:
            [ {{ objects.join(', ') }} ]
        </label>
    </div>

    <ui-message :message="message"/>

    <div class="d-flex justify-content-center">
        <button class="btn btn-primary config-btn-primary btn-sm mt-2" @click="sendRecalculate">Выполнить пересчет</button>
    </div>
  </ui-popup>
</template>

<script setup>
import { inject, onMounted, ref, watch } from 'vue';

import UiPopup from '@/ManualApp/Components/Popups/UiPopup.vue';
import UiDoubleDatepicker from '@/App/Components/UiDoubleDatepicker.vue';
import UiMessage from '@/ManualApp/Components/Message/UiMessage.vue';

import { useRequest } from '@/ManualApp/use/request';

const emit = defineEmits(['close'])

const {send, message} = useRequest() 

const URL_ADD_RECALCULATE = 'Api/recalculateAdd'

const TABLE_HEADER = [
    {name: 'id', label: 'ID'},
    {name: 'name', label: 'Наименование'},
    {name: 'stateNumber', label: 'Гос №'},
    {name: 'vin', label: 'VIN'},
]

const search = ref('')

const globalObjectsList = inject('globalObjectsList')

const objectsForSelect = ref([])

const datepickerDate = ref([]);

const selectedAll = ref(false)
const objects = ref([])

watch(datepickerDate, (newDate, oldDate) => {
  if (!newDate[1]) newDate[1] = oldDate[1];
});

watch(selectedAll,(selected) => {
    if(selected) {
        const objs = []
        
        for(const obj of objectsForSelect.value) {
            objs.push(obj.id)
        }

        objects.value = objs
    } else {
        objects.value = []
    }
})

watch(search, setObjectsForSelect)

onMounted(() => {
    datepickerInit()
    setObjectsForSelect()
});

function setObjectsForSelect() {
    const objs = []
    
    for(const id in globalObjectsList) {
        let isShow = false
        
        for(let col of TABLE_HEADER) {
            if (globalObjectsList[id][col.name].toLowerCase().includes(search.value.toLowerCase())) {
                isShow = true
                break
            }
        }

        if (isShow) {
            objs.push(globalObjectsList[id]) 
        }
    }

    objectsForSelect.value = objs
}

function datepickerInit( ) {
  const d = new Date();

  // const from = new Date(2023, 2, 14, 0, 0, 0);
  // const to = new Date(2023, 2, 15, 0, 0, 0);
  
  const from = new Date(
    d.getFullYear(),
    d.getMonth(),
    d.getDate(),
    0,
    0,
    0,
  );
  
  const to = new Date(d.getFullYear(), d.getMonth(), d.getDate(), 0, 0, 0);
  
  datepickerDate.value = [from.toString(), to.toString()];
}

async function sendRecalculate() {
    const data = {
        ids: objects.value,
        from: new Date(datepickerDate.value[0]).getTime()/1000,
        to: new Date(datepickerDate.value[1]).getTime()/1000
    }

    if (!objects.value.length) {
        message.type = 'error'
        message.value = 'Объекты не выбраны. Выберите минимально один объект'
        return
    }

    try {
        message.type = 'success'
        message.value = 'Запрос отправлен'

        const res = await send(URL_ADD_RECALCULATE, 'POST', data)
        
        if (res.message) {
            message.type = 'error'
            message.value = ucFirst_helper(res.message)
            return
        }

        message.type = 'success'
        message.value = 'Пересчет добавлен'
    } catch (error) {
        console.error(error);
        message.type = 'error'
        message.value = 'Возникла ошибка во время добавления пересчета. Попробуйте повторить запрос позже'
    }
}
</script>

<style lang="scss">
.top-settings {
    display: flex;

    & .search {
        @media (max-width: 450px) {
            width: calc(100% + 48px);
            margin-left: 0;
        }
    }

    & .interval {
        margin-left: 15px;
        
        @media (max-width: 450px) {
            width: calc(100% + 48px);
            margin-left: 0;
        }
    }

    @media (max-width: 450px) {
        flex-direction: column;
    }
}
.datepicker {    
  display: flex;
  align-items: center;
  width: 100%;
  margin: 0;
  & .dp__pointer {
    padding: 5px 10px;
  }
}
</style>