<template>
  <div>
    <div class="template-values-window">
      <div class="template-setting-window-header">
        <div class="template-setting-window-header-name">
          <strong>Рейсы по геозонам</strong>
          <br />
          <small>Детали по нарушениям</small>
        </div>
        <!-- Картинка крестика-->
        <img
          data-element="close"
          class="close"
          src="/images/close.png"
          @click="close"
        /><!-- onclick="showHiddenWindow('none', 'shiftWork-template-setting-window')" -->
      </div>
      <div class="owerflow-auto">
        <table
          border="1"
          cellpadding="5"
        >
          <!-- <caption>
          Нарушения детали
        </caption> -->
          <thead class="sticky">
            <tr class="text-center">
              <th
                v-for="column in columns"
                :key="column"
                :data-minheight="
                  columnSettings[column].text.length > 20 ? 30 : 15
                "
                :style="columnSettings[column].style || {}"
                data-excelalignh="center"
                data-excelalignv="middle"
              >
                {{ columnSettings[column].text }}
              </th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="rowName in rowsOrder"
              :key="rowName"
            >
              <td
                v-for="column in columns"
                :key="column"
                :data-excelcolspan="columnSettings[column].colspan"
                :data-formatnum="columnSettings[column].formatNum"
                :style="getCellStyle({ rowName, column })"
                v-html="getHtmlText(rowName, column)"
              ></td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <div
      @click="close"
      data-element="close"
      id="background-wrap"
      class="background-wrap"
      style="display: flex"
    ></div>
  </div>
</template>

<script>
export default {
  props: {
    columnSettings: {
      type: Object,
      required: true,
    },
    rowsSettings: {
      type: Object,
      required: true,
    },
    columns: {
      type: Array,
      required: true,
    },
    rowsOrder: {
      type: Array,
      required: true,
    },
    rowsValues: {
      type: Object,
      required: true,
    },
    getValue: {
      type: Function,
      required: true,
    },
    showTable: {
      type: Function,
      required: true,
    },
    getCellStyle: {
      type: Function,
      required: true,
    },
  },
  methods: {
    getHtmlText(rowName, column) {
      const formatNum = this.columnSettings[column].formatNum || 0;
      const value = this.getValue({ rowName, column });
      if (formatNum === 4 && value && !isNaN(value)) {
        return formatToDisplay_helper(
          value / this.$options.SECONDS_PER_DAY,
          formatNum,
        ); // см. main_helper.js
      }
      return formatToDisplay_helper(value, formatNum); // см. main_helper.js
    },
    close() {
      this.showTable(false);
    },
  },
};
</script>

<style scoped>
table {
  border-collapse: collapse;
  border: 1px solid black;
  caption-side: top;
  margin: 0;
}
table th {
  position: sticky;
  top: 0;
}
caption {
  caption-side: top;
}
.center-tag {
  text-align: center;
}
.template-values-window {
  display: flex;
  flex-direction: column;
  padding: 4px 8px;
  width: 85vw;
  height: 92vh;
  margin: auto;
  background: #fff;
  z-index: 1003;
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
}
.my-underline {
  text-decoration: underline;
  cursor: pointer;
}
.checkbox-target {
  color: blue;
}
.owerflow-auto {
  width: 100%;
  overflow: auto;
  border: 1px solid gray;
}
</style>
