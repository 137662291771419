import { skillsManTemplate } from '../../Template/skills_man_template/skills_man_template.js';
import fetchGetDataStepByStep from './fetchGetDataStepByStep.js';
import CalculateRequestDataworker from '@/workers/CalculateRequestData.worker.js';

import ShiftWorkXML from '@/Template/shiftWork_template/ShiftWorkXML';
import { getTzh } from '@/helpers/main_helper.js';

export const url_get_aggregated = `${
  process.env.VUE_APP_BACKEND_URL || window.location.origin + '/'
}Api/getAgregationPositions`;

export class OurAggregated {
  aggregateInterval = {};
  objectsCalculate = '';
  pointer;
  shiftWorkWorkerData = {};

  // constructor(skillsMan_Template, lodashRenderHtml_helper) {
  constructor(skillsMan_Template, lodashRenderHtml_helper, globalObjectsList) {
    this.lodashRenderHtml_helper = lodashRenderHtml_helper;
    this.skillsManTemplate = skillsManTemplate;
    this.globalObjectsList = globalObjectsList;
  }

  async getAggregated({
    dataArr,
    dataSend,
    targetElement,
    buttonElement,
    templateName,
    callback,
    areaId,
    params = null,
    isGetSmenas = false,
    smenasOrigin = [],
    templateHtml = '',
    VueLoadingProcess = () => {},
    VueErrorHandler = () => {},
  } = {}) {
    let send_data_arr = [];
    this.aggregateInterval.reportBegin = dataSend['time_begin'];
    this.aggregateInterval.reportEnd = dataSend['time_end'];

    let interval = {
      timeBegin: dataSend['time_begin'], // в секундах
      timeEnd: dataSend['time_end'], // в секундах
    };

    for (let i = 0; i < dataArr['objIdArr'].length; i++) {
      send_data_arr.push(addSendData(dataArr['objIdArr'][i], dataSend));
    }

    infoShowText_helper(
      targetElement,
      'Отправляю запрос для получения данных...',
      areaId,
    );
    setElementColor_helper(buttonElement, 'blue');
    const ourAggregated_this = this;

    const timeCheck = {};
    timeCheck.begin = new Date();

    ourAggregated_this.response = [];

    const fetchDataStepByStep = new fetchGetDataStepByStep({
      url: url_get_aggregated,
      targetElement,
      buttonElement,
      areaId,
      VueLoadingProcess,
      VueErrorHandler,
      aggregateInterval: this.aggregateInterval,
      shiftWorkWorkerData: this.shiftWorkWorkerData,
      templateName,
      interval,
      callback,
      isGetSmenas,
      smenasOrigin,
      params,
    });

    const data = await fetchDataStepByStep.getDataAndCalculate(
      dataArr['objIdArr'],
      dataSend,
    );

    if (!data.error) {
      ourAggregated_this.response = data;
      ajaxPositionsDoneAll_action({ VueLoadingProcess, VueErrorHandler, globalObjectsList: this.globalObjectsList });
    } else {
      ajaxPositionsFailAll(data.error);
    }

    async function ajaxPositionsDoneAll_action({
      VueLoadingProcess,
      VueErrorHandler,
      globalObjectsList
    }) {
      // ourAggregated_this.response = data;
      if (templateName === 'shiftWork') {
        // все запросы выполнены - калькуляция всех объектов

        const objectsCalculated = await templateCalculate(
          ourAggregated_this,
          templateName,
          interval,
          callback,
        );

        // передача данных в шаблон lodash для отрисовки таблиц
        if (templateHtml !== '') {
          lodashRenderHtmlByTemplateHtml_helper(
            objectsCalculated,
            templateHtml,
            'section-reports',
          );
        } else {
          lodashRenderHtml_helper(
            objectsCalculated,
            'shiftWork-template',
            'section-reports',
          );
        }
        // очистка памяти
        ourAggregated_this.response = [];

        // информирование
        ajaxDoneActions(targetElement, buttonElement);
      }

      if (templateName === 'skillsMan') {
        // ourAggregated.objectsCalculate = templateCalculate(ourAggregated_this.response, thisClass, templateName, interval, callback);
        ourAggregated_this.objectsCalculate = ourAggregated_this.response;
        // сортировка объектов
        // ourAggregated.objectsCalculate.rows_summ.lines[0].sort(function(a, b) {
        ourAggregated_this.objectsCalculate.rows_summ.lines[0].sort(function (
          a,
          b,
        ) {
          // сортировка суммарной таблицы от большего к меньшему по нарушениям на 100 км
          if (typeof a['forfeitsRelative'] == 'string') return 1;
          if (typeof b['forfeitsRelative'] == 'string') return 0;
          return b['forfeitsRelative'] - a['forfeitsRelative'];
        });

        //приведем в тот же порядок отчеты по объектам
        let objectsArr = ourAggregated_this.objectsCalculate.objects;
        let objectsSortedArr =
          ourAggregated_this.objectsCalculate.rows_summ.lines[0];
        let objectsForSort = [];

        for (let k = 0; k < objectsArr.length; k++) {
          let sortedId = objectsArr[k].id
          for (let j = 0; j < objectsSortedArr.length; j++) {
            if (sortedId == objectsSortedArr[j].objId) {
              objectsForSort.push(objectsSortedArr[j]);
              break;
            }
          }
        }
        

        ourAggregated_this.objectsCalculate.rows_summ.lines[0] = objectsForSort;

        if (callback) {
          callback(ourAggregated_this.objectsCalculate);
          ajaxDoneActions(targetElement, buttonElement);
          return;
        }

        // передача данных в шаблон
        if (templateHtml !== '') {
          lodashRenderHtmlByTemplateHtml_helper(
            ourAggregated_this.objectsCalculate,
            templateHtml,
            'section-reports',
          );
        } else {
          lodashRenderHtml_helper(
            ourAggregated_this.objectsCalculate,
            'skills-man-template',
            'section-reports',
          );
        }
        ajaxDoneActions(targetElement, buttonElement);
      }

      if (['regularMovingGeoTemplate'].includes(templateName)) {
        ourAggregated_this.objectsCalculate = await templateCalculate(
          ourAggregated_this,
          templateName,
          interval,
          globalObjectsList
        );

        if (areaId) {
          params.areaId = areaId;
        }

        callback(ourAggregated_this.objectsCalculate, params);
      }

      if (
        [
          'getCalculated',
          'getCalculatedWithGeofences',
          // 'regularMovingGeoTemplate',
          'evacuatorMoving',
        ].includes(templateName)
      ) {
        ourAggregated_this.objectsCalculate = ourAggregated_this.response;

        if (areaId) {
          params.areaId = areaId;
        }

        callback(ourAggregated_this.objectsCalculate, params);
      }

      if (templateName === 'criticalViolations') {
        ourAggregated_this.objectsCalculate = ourAggregated_this.response;

        params.areaId = areaId;
        params.targetElement = targetElement;
        params.buttonElement = buttonElement;
        callback(ourAggregated_this.objectsCalculate, params);
      }
    }

    function ajaxPositionsFailAll(err) {
      console.error('err', err);
      // ourAggregated_this.response.forEach(function (res, index) {
      //   console.error(`ERROR, ourAggregated_this.response[${index}]: `, res);
      // });
      VueErrorHandler('Ошибка получения данных. Выполните новый запрос');
      // isDone();
      infoShowText_helper(
        targetElement,
        'Ошибка получения данных. Выполнить новый запрос?',
        areaId,
      );
      setElementColor_helper(buttonElement, 'red');
    }

    function ajaxDoneActions(targetElement, buttonElement) {
      // isDone();
      infoShowText_helper(
        targetElement,
        'Суммарный отчет построен. Выполнить новый запрос?',
      );
      setElementColor_helper(buttonElement, 'green');
    }

    async function templateCalculate(thisClass, templateName, interval, globalObjectsList) {
      const { response: objects } = thisClass;

      try {
        // все запросы выполнены - калькуляция всех объектов
        if (templateName === 'shiftWork') {
          // результат отчета по всем объектам для Работа ТС посуточно
          const objectsData = objects.map((obj) => obj.data);

          const worker = new CalculateRequestDataworker();

          const s = thisClass.shiftWorkWorkerData.smenas;
          const smenas = {
            breakdown: s.breakdown,
            setSmenas: s.setSmenas,
            smenasFlag: s.smenasFlag,
            smenasSetting: s.smenasSetting,
          };

          worker.postMessage({
            templateName,
            data: {
              Response: objectsData,
              ResponseInterval: interval,
              Report: thisClass.shiftWorkWorkerData.Report,
              smenas,
              tzh: getTzh(),
            },
          });

          let report = {};
          worker.addEventListener('message', (event) => {
            report = event.data;
            worker.terminate();
          });

          worker.addEventListener('error', ({ message }) => {
            console.error(message);
            worker.terminate();
          });

          while (!Object.keys(report).length) {
            await new Promise((res) => setTimeout(res, 500));
          }

          return report;
        }

        if (['regularMovingGeoTemplate'].includes(templateName)) {
          // в этой ф-ии callback не используется для этих отчетов
          let reportsData = [];
          let skillsManObjects = [];

          const objectIdsNotIncludesInRequest = [];
          
          for (let i = 0; i < objects.length; i++) {
            if (!objects[i].data) {
              objectIdsNotIncludesInRequest.push(objects[i].id);
              continue;
            }
            let jsonRes = JSON.parse(objects[i].data);
            let objId = jsonRes['objConf']['id'];
            let objClient = jsonRes['objConf']['client'];
            let obj, objStateNum, objModel
            if (globalObjectsList) {
              obj = globalObjectsList[objId];
              objStateNum = obj["state_number"];
              objModel = obj["model"]
            } else {
              obj = document.getElementById(objId);
              objStateNum = obj.getElementsByClassName('state-number')[0].innerHTML;
              objModel = obj.getElementsByClassName('obj-model')[0].innerHTML;
            }

            skillsManObjects.push({
              name: jsonRes.objConf.name.trim(),
              avtoNo: objStateNum,
              model: objModel,
              client: objClient,
            });

            let report = await thisClass.skillsManTemplate.calculate(
              jsonRes,
              new Date(thisClass.aggregateInterval.reportBegin * 1000),
              new Date(thisClass.aggregateInterval.reportEnd * 1000),
              objStateNum,
              objModel.trim(),
              skillsManObjects,
              i,
              isGetSmenas,
              smenasOrigin,
              params,
            );
            reportsData.push(report);

            await new Promise((res) => setTimeout(res, 0));

            const percent = Math.round((100 * (i + 1)) / objects.length);
            VueLoadingProcess(
              `Обработано ${i + 1} из ${objects.length} (${percent}%)`,
            );
          }

          return {
            objects: reportsData,
            rows_summ: thisClass.skillsManTemplate.rows_summ,
            rows_summ_forGraph_100:
              thisClass.skillsManTemplate.rows_summ_forGraph_100,
            format: formatToDisplay_helper,
            objectIdsNotIncludesInRequest,
          };
        }
      } catch (error) {
        console.error(error);
      }
    }

    function addSendData(id, dataSend) {
      let res = {
        id: id,
      };

      for (let key in dataSend) {
        // смены уже учтены
        res[key] = dataSend[key];
      }

      // часовой пояс клиента
      res['tzh'] = 0 - new Date().getTimezoneOffset() / 60;

      return res;
    }
  }

  roundStringTime(strTime, roundTo) {
    roundTo = roundTo || 10;
    let lastSymbol = strTime.substr(strTime.length - 1, strTime.length);

    if (roundTo == 10 && lastSymbol != '0') {
      // округление до 10-ти минут
      let untilLastSymbol = strTime.substr(0, strTime.length - 1);
      return untilLastSymbol + '0';
    }

    if (roundTo == 5 && !(lastSymbol == '0' || lastSymbol == '5')) {
      // округление до 5-ти минут
      let untilLastSymbol = strTime.substr(0, strTime.length - 1);
      if (+lastSymbol < 5) {
        return untilLastSymbol + '0';
      }
      return untilLastSymbol + '5';
    }

    return strTime;
  }

  callOurAggregated({
    smenasSeconds,
    option,
    templateName,
    templateHtml = '',
    form_desc,
    callback,
    shiftWorkWorkerData,
    callbackComponent,
    roundTo,
    areaId,
    isGetSmenas = false,
    smenasOrigin = [],
  } = {}) {
    this.shiftWorkWorkerData = shiftWorkWorkerData;
    smenasSeconds = smenasSeconds || [];
    option = option || 2;

    let visibleElements = objectsListFormColorAndTarget_helper(form_desc);

    if (!visibleElements) {
      return;
    }

    const dataArr = {
      objIdArr: form_desc.objects,
    };

    const dataSend = {
      time_begin: form_desc.beginTime / 1000,
      time_end: form_desc.endTime / 1000,
      option: option,
      template_name: templateName,
    };

    // добавим смены в запрос
    for (let s in smenasSeconds) {
      const nSmena = +s + 1;
      dataSend['smena_' + nSmena] = smenasSeconds[s];
    }

    const { targetSpan: targetElement, button: buttonElement } =
      visibleElements;
    const params = {
      areaId,
      targetElement,
      buttonElement,
      callbackComponent,
      isGetSmenas,
    };

    this.getAggregated({
      dataArr,
      dataSend,
      targetElement,
      buttonElement,
      templateName,
      templateHtml,
      callback,
      params,
      areaId,
      isGetSmenas,
      smenasOrigin,
    });
  }
}
