import { EventsCalculate } from '../events_calculate/events_calculate.js';
import {
  formatDateHelper,
  addCopyArray_helper,
  addCopyArr_helper,
  getRelativeValue_helper,
  addCopyObj_helper,
} from '@/helpers/main_helper.js';
const notValue = 'н/у';
const notValidate = 'нет значения';
const needLoad = 'не получено';
const notData = 'н/д';

export const skillsManTemplate = {
  calculate: function (
    REPORT,
    ReportBegin,
    ReportEnd,
    stateNum,
    objModel,
    objs,
    curIndex,
    isGetSmenas = false,
    smenasOrigin = [],
    params = {},
  ) {
    function refreshPrevValue() {
      /* написать или удалить*/
      valuesPrev = addCopyArr_helper(values);
    }
    function endWeek({
      iobj,
      obj_num,
      line_num,
      rows_summ_forGraph_100,
      codeGearBoxNumNameFunction,
      transmissionLockArr,
      sum_val,
      rows_person,
    } = {}) {
      if (is_graphics_summ_viol_to_dist && ReportInterval) {
        /* посчитали сумму нарушений с начала периода*/
        let line = violationSummCalculate({
          codeGearBoxNumNameFunction,
          setWebToTable,
          iobj,
          transmissionLockArr,
          sum_val,
          rows_person,
        });
        /* применим это к понедельной градуировке*/
        curWeek.violationCnt = line.forfeits;
        curWeek.dist = line.DDist;
        let g_forfeits_100 = 0;
        let week_dist_dif = curWeek.dist - curWeek.dist_prev;
        if (week_dist_dif > 0) {
          g_forfeits = curWeek.violationCnt - curWeek.violationCnt_prev;
          g_forfeits_100 = (100 * g_forfeits) / week_dist_dif; //Количество нарушений приведенное к 100 км пробега
        }

        //нарушений на 100 км пробега

        rows_summ_forGraph_100.lines[0][line_num]['GObj_' + obj_num] =
          g_forfeits_100;

        /*
                 индивидуальный график
                 if(is_graphics_time){
                 series_time.ViolTime.points.push({time: curSmena.begin, val: g_forfeits});
                 series_time.ViolDist.points.push({time: curSmena.begin, val:  Math.round(week_dist_dif * 10) / 10});
                 }
        
                 if(is_graphics_dist){
                 series_dist.ViolDist.points.push({dist: Math.round(distOdo * 10) / 10, val: g_forfeits});
                 }
                 posGraphCnt++;
                 */
        curWeek.dist_prev = curWeek.dist;
        curWeek.violationCnt_prev = curWeek.violationCnt;
      }
    }

    function violationSummCalculate({
      codeGearBoxNumNameFunction,
      setWebToTable,
      iobj,
      transmissionLockArr,
      sum_val, // sum_val
      rows_person,
    } = {}) {
      /* функция подсчета суммы нарушений*/
      /* переложим результат в таблицы*/
      setWebToTable(
        sum_val, // sum_val
        codeGearBoxNumNameFunction,
        iobj.gearboxCnt,
        transmissionLockArr,
      );

      const distSumm = sum_val['t2_distance'] || 0;
      const line = skillsManTemplate.calcPersonSummViolation(
        rows_person.personForDisplay,
        rows_person.person,
        distSumm,
      );
      return line;
      // /* переписано, проверить на нескольких, в том числе если объекта нет в БД статистики*/
      // const line = {};
      // line.forfeits = 0;
      // line.forfeitsRelative = 0;
      // line.DDist = distSumm / 1000; //перевод в километры
      // for (let z = 0; z < rows_person.personForDisplay.length; z++) {
      //   let groupName;
      //   for (let zz = 0; zz < rows_person.personForDisplay[z].length; zz++) {
      //     if (zz == 0) {
      //       groupName = rows_person.personForDisplay[z][zz]; //имя группировки в массиве
      //       line[groupName] = 0; //добавили сущность группировки
      //       continue; //пока пропускаем
      //     }
      //     //добавляем групповые нарушения
      //     let rowName = rows_person.personForDisplay[z][zz]; //получили имя в массиве в соответствии с заданной группировкой
      //     line[groupName] += rows_person.person[rowName].DForfeits; //суммировали значение
      //     line.forfeits += rows_person.person[rowName].DForfeits; //суммировали значение всквозную
      //     if (distSumm > 0) {
      //       rows_person.person[rowName].DForfeitsRelative =
      //         rows_person.person[rowName].DForfeits / (distSumm / 100000); //суммировали значение
      //     }
      //   }
      // }
      // if (line.DDist > 0) {
      //   line.forfeitsRelative = (100 * line.forfeits) / line.DDist;
      // }

      // return line;
    }

    // function setWebToTableOne(targetObj = {}, webArr = [], tableArrName = '', webArrName = '', type = '', divisor = 0) {
    //   // if (divisor === undefined) {
    //   //   divisor = 0;
    //   // }
    //   if (!targetObj[tableArrName]) {
    //     targetObj[tableArrName] = {};
    //   }

    //   const w_value = webArr[webArrName.toLowerCase()];
    //   if (type == "timeDivisor" && w_value && divisor) {
    //     // rows_person.person[tableArrName]["DCount"] =
    //     targetObj[tableArrName]["DCount"] =
    //       Number(w_value / 60).toFixed(1) + " минут"; //Интервал оборотов ДВС
    //     // rows_person.person[tableArrName]["DForfeits"] =
    //     targetObj[tableArrName]["DForfeits"] =
    //       1 + Math.floor(w_value / divisor);
    //     //rows_person.person[tableArrName]['DForfeitsRelative'] = rows_person.person[tableArrName]['DForfeits'] / (dist / 100000);//пробег в метрах
    //     return;
    //   }
    //   if (
    //     w_value != undefined &&
    //     // w_value != rows_person.person[tableArrName]["DCount"]
    //     w_value != targetObj[tableArrName]["DCount"]
    //   ) {
    //     // rows_person.person[tableArrName]["DCount"] = w_value;
    //     // rows_person.person[tableArrName]["DForfeits"] = w_value;
    //     targetObj[tableArrName]["DCount"] = w_value;
    //     targetObj[tableArrName]["DForfeits"] = w_value;
    //   }
    // }

    function setWebToTableTwo(
      webArr, // это sum_val
      tableArrName,
      webArrName,
      tableArrNameIn,
      type,
      divisor,
    ) {
      // @param type = 'centi' | 'deci' | 'kilo' | 'dist' | 'expence' | 'timeDiff'
      if (divisor === undefined) {
        divisor = 0;
      }
      let w_value = webArr[webArrName.toLowerCase()];
      if (type === 'timeDiff' && w_value) {
        rows_experiment[tableArrName][tableArrNameIn]['val'] = w_value / 86400; //переводим из секунд в сутки
        return;
      }

      if (
        w_value &&
        (type === 'dist' || type === 'expence' || type === 'kilo')
      ) {
        rows_experiment[tableArrName][tableArrNameIn]['val'] = w_value / 1000; //переводим из метров в километры, из миллилитров в литры
        return;
      }

      if (w_value && type === 'centi') {
        rows_experiment[tableArrName][tableArrNameIn]['val'] = w_value / 100; //переводим из метров в километры, из миллилитров в литры
        return;
      }

      if (w_value && type === 'deci') {
        rows_experiment[tableArrName][tableArrNameIn]['val'] = w_value / 10; //переводим из метров в километры, из миллилитров в литры
        return;
      }

      /*if(type == 'timeDivisor' && w_value && divisor){
                rows_experiment[tableArrName]['DCount'] = Number(w_value / 60).toFixed(1) + ' минут';//Интервал оборотов ДВС
                rows_experiment[tableArrName]['DForfeits'] = 1 + Math.floor(w_value / divisor);
                //rows_experiment[tableArrName]['DForfeitsRelative'] = rows_person.person[tableArrName]['DForfeits'] / (dist / 100000);//пробег в метрах
                return;
            }*/

      if (w_value) {
        rows_experiment[tableArrName][tableArrNameIn]['val'] = w_value;
      }
    }

    function setWebToTable(
      webArr,
      codeGearBoxNumNameFunction,
      gearBoxCnt,
      transmissionLockArr,
    ) {
      skillsManTemplate.setWebToTableOneAll(rows_person.person, webArr);

      /* таблицу 2*/
      setWebToTableTwo(webArr, 'serviceDistance', 'service_distance', 'val');
      setWebToTableTwo(
        webArr,
        'motoEngine',
        't2_motoEngine',
        'val',
        'timeDiff',
      );
      setWebToTableTwo(
        webArr,
        'motoEngineNoSpd',
        't2_motoEngineNoSpd',
        'val',
        'timeDiff',
      );
      setWebToTableTwo(
        webArr,
        'motoEngineSpd',
        't2_motoEngineSpd',
        'val',
        'timeDiff',
      );
      setWebToTableTwo(webArr, 'distance', 't2_distance', 'val', 'dist');
      setWebToTableTwo(webArr, 'canExpence', 't2_canExpence', 'val', 'expence');
      setWebToTableTwo(
        webArr,
        'canExpenceSpd',
        't2_canExpenceSpd',
        'val',
        'expence',
      );
      setWebToTableTwo(
        webArr,
        'canExpenceNoSpd',
        't2_canExpenceNoSpd',
        'val',
        'expence',
      );

      // Использование сцепления по началу движения
      setWebToTableTwo(
        webArr,
        'clutchUseMoveStartCnt',
        't2_clutch_use_move_start_cnt',
        'val',
      );
      setWebToTableTwo(
        webArr,
        'clutchUseMoveStartSlipCnt',
        't2_clutch_use_move_start_slip_cnt',
        'val',
      );
      setWebToTableTwo(
        webArr,
        'clutchUseMoveStartSlipHiCnt',
        't2_clutch_use_move_start_slip_hi_cnt',
        'val',
        'centi',
      );
      setWebToTableTwo(
        webArr,
        'clutchUseMoveStartSlipLowCnt',
        't2_clutch_use_move_start_slip_low_cnt',
        'val',
        'centi',
      );

      // Работа сцепления по началу движения
      setWebToTableTwo(
        webArr,
        'clutchUseMoveStartWorkSumm',
        't2_clutch_use_move_start_work_summ',
        'val',
        'kilo',
      );
      setWebToTableTwo(
        webArr,
        'clutchUseMoveStartExcessiveWorkSumm',
        't2_clutch_use_move_start_excessive_work_summ',
        'val',
        'kilo',
      );
      setWebToTableTwo(
        webArr,
        'clutchUseMoveStartExcessiveWorkHi',
        't2_clutch_use_move_start_excessive_work_hi',
        'val',
        'kilo',
      );
      setWebToTableTwo(
        webArr,
        'clutchUseMoveStartExcessiveWorkLow',
        't2_clutch_use_move_start_excessive_work_low',
        'val',
        'kilo',
      );

      /* работа моторного тормоза*/
      setWebToTableTwo(webArr, 'breakCount', 't2_brake_count', 'val');
      setWebToTableTwo(webArr, 'breakTime', 't2_brake_time', 'val', 'timeDiff');
      setWebToTableTwo(
        webArr,
        'pedalBreakCount',
        't2_pedal_brake_count',
        'val',
      );
      setWebToTableTwo(
        webArr,
        'pedalBreakTime',
        't2_pedal_brake_time',
        'val',
        'timeDiff',
      );
      setWebToTableTwo(webArr, 'engineBreakCnt', 't2_engineBreakCnt', 'val');
      setWebToTableTwo(
        webArr,
        'engineBreakTime',
        't2_engineBreakTime',
        'val',
        'timeDiff',
      );
      setWebToTableTwo(webArr, 'absCount', 't2_abs_brake_count', 'val');

      /* table2 статистика КПП переключений*/
      setWebToTableTwo(webArr, 'swithGearBoxCnt', 't2_swithGearBoxCnt', 'val');
      setWebToTableTwo(webArr, 'swithClutchCnt', 't2_swithClutchCnt', 'val');
      setWebToTableTwo(
        webArr,
        'swithClutchBroken',
        't2_swithClutchBroken',
        'val',
        'timeDiff',
      );

      setWebToTableTwo(
        webArr,
        'clutchWorkSumm',
        't2_clutc_work_summ',
        'val',
        'kilo',
      );

      /* использование КПП по пробегу и началу движения*/
      for (let ii = -2; ii <= gearBoxCnt; ii++) {
        /* этот массив имен будет использован для выгрузки в таблицу */
        let kp = codeGearBoxNumNameFunction(ii);
        let gearboxArrName = String('GearBoxNum_' + kp);
        setWebToTableTwo(
          webArr,
          gearboxArrName,
          String(gearboxArrName + '_dist'),
          'dist',
          'dist',
        );
        setWebToTableTwo(
          webArr,
          gearboxArrName,
          String(gearboxArrName + '_beginMove'),
          'cntBeginMove',
        );

        setWebToTableTwo(
          webArr,
          gearboxArrName,
          String(gearboxArrName + '_cnt_rpm_clutch_slip'),
          'cntRpmClutchSlip',
          'centi',
        );
        setWebToTableTwo(
          webArr,
          gearboxArrName,
          String(gearboxArrName + '_clutch_slip_excessive_work'),
          'clutchSlipExcessiveWork',
          'kilo',
        );
        setWebToTableTwo(
          webArr,
          gearboxArrName,
          String(gearboxArrName + '_clutch_slip_excessive_work_low'),
          'clutchSlipExcessiveWorkLow',
          'kilo',
        );
        setWebToTableTwo(
          webArr,
          gearboxArrName,
          String(gearboxArrName + '_accel_move_begin_measurements_count'),
          'accelMoveBeginMeasurementsCount',
        );
        setWebToTableTwo(
          webArr,
          gearboxArrName,
          String(gearboxArrName + '_accel_move_begin_measurements_summ'),
          'accelMoveBeginMeasurementsSumm',
        );
        setWebToTableTwo(
          webArr,
          gearboxArrName,
          String(
            gearboxArrName + '_accelmovebeginwithclutchslipmeasurements_count',
          ),
          'accelMoveBeginWithClutchSlipMeasurementsCount',
        );
        setWebToTableTwo(
          webArr,
          gearboxArrName,
          String(
            gearboxArrName + '_accelmovebeginwithclutchslipmeasurements_summ',
          ),
          'accelMoveBeginWithClutchSlipMeasurementsSumm',
        );
      }

      /* Использование режимов трансмиссии*/
      for (let i = 0; i < transmissionLockArr.length; i++) {
        //для каждой блокировки создаем свои показатели
        let transmissionLock_name = transmissionLockArr[i].nameArr;
        setWebToTableTwo(
          webArr,
          transmissionLock_name,
          String(transmissionLock_name + '_cnt'),
          'count',
        );
        setWebToTableTwo(
          webArr,
          transmissionLock_name,
          String(transmissionLock_name + '_time'),
          'time',
          'timeDiff',
        );
        setWebToTableTwo(
          webArr,
          transmissionLock_name,
          String(transmissionLock_name + '_dist'),
          'dist',
          'dist',
          'dist',
        );
      }
      /*
            foreach($this->blocksMan_arrNames as $transmissionLock_name){
                setWebToTableTwo(webArr, $transmissionLock_name, $transmissionLock_name . '_cnt', 'count');
                setWebToTableTwo(webArr, $transmissionLock_name, $transmissionLock_name . '_time', 'time', 0, 'timeDiff');
                setWebToTableTwo(webArr, $transmissionLock_name, $transmissionLock_name . '_dist', 'dist');
            }*/

      /* КОМ PTO коробка отбора мощности*/
      setWebToTableTwo(webArr, 'ptoCnt', 't2_ptoCnt', 'val');
      setWebToTableTwo(webArr, 'ptoTime', 't2_ptoTime', 'val', 'timeDiff');
      setWebToTableTwo(
        webArr,
        'ptoEngineRpm800_1000Time',
        't2_ptoEngineRpm800_1000Time',
        'val',
        'timeDiff',
      );

      /* Неэффективная эксплуатация ТС:*/
      setWebToTableTwo(
        webArr,
        'rpm1900_2200Time',
        't2_rpm1900_2200Time',
        'val',
        'timeDiff',
      );
      setWebToTableTwo(
        webArr,
        'swithClutchNoSpdCountTime',
        't2_swithClutchNoSpdCountTime',
        'val',
        'timeDiff',
      );

      setWebToTableTwo(
        webArr,
        'clutchSlipExcessiveRpmCnt',
        't2_clutch_slip_excessive_rpm_cnt',
        'val',
        'centi',
      );
      setWebToTableTwo(
        webArr,
        'clutchSlipVehicleSlipTime',
        't2_clutch_slip_vehicle_slip_time',
        'val',
        'timeDiff',
      );
      setWebToTableTwo(
        webArr,
        'clutchSlipVehicleSlipRpmCnt',
        't2_clutch_slip_vehicle_slip_rpm_cnt',
        'val',
        'centi',
      );
      setWebToTableTwo(
        webArr,
        'clutchSlipVehicleSlipWork',
        't2_clutch_slip_vehicle_slip_work',
        'val',
        'kilo',
      );
      setWebToTableTwo(
        webArr,
        'clutchSlipVehicleSlipLowRpmCnt',
        't2_clutch_slip_vehicle_slip_low_rpm_cnt',
        'val',
        'centi',
      );
      setWebToTableTwo(
        webArr,
        'clutchSlipVehicleSlipLowWork',
        't2_clutch_slip_vehicle_slip_low_work',
        'val',
        'kilo',
      );
      setWebToTableTwo(webArr, 'kickdownCnt', 't2_kickdown_cnt', 'val');

      // setWebToTableTwo(webArr, 'rpmNo800_1000less1200Cnt', 't2_rpmNo800_1000less1200Cnt', 'val');
      setWebToTableTwo(
        webArr,
        'rpmNo800_1000less1200Time',
        't2_rpmNo800_1000less1200Time',
        'val',
        'timeDiff',
      );
      setWebToTableTwo(
        webArr,
        'swithGearBoxWrongCnt',
        't2_swithGearBoxWrongCnt',
        'val',
      );
      setWebToTableTwo(
        webArr,
        'swithGearBoxWrongPercent',
        't2_swithGearBoxWrongPercent',
        'val',
      );
      setWebToTableTwo(
        webArr,
        'swithClutchInsufficientCnt',
        't2_swithClutchInsufficientCnt',
        'val',
      );

      /* Нарушения при эксплуатации ТС:*/
      setWebToTableTwo(
        webArr,
        'rpmMore2200Time',
        't2_rpmMore2200Time',
        'val',
        'timeDiff',
      );
      setWebToTableTwo(
        webArr,
        'rpmMore1200PtoTime',
        't2_rpmMore1200PtoTime',
        'val',
        'timeDiff',
      );

      setWebToTableTwo(
        webArr,
        'clutchSlipBadRpmCnt',
        't2_clutch_slip_bad_rpm_cnt',
        'val',
        'centi',
      );
      setWebToTableTwo(
        webArr,
        'clutchSlipCriticalCnt',
        't2_clutch_slip_critical_cnt',
        'val',
        'centi',
      );
      setWebToTableTwo(
        webArr,
        'clutchSlipCriticalTime',
        't2_clutch_slip_critical_time',
        'val',
        'timeDiff',
      );

      // setWebToTableTwo(webArr, 'clutchSlipCnt', 't2_clutchSlipCnt', 'val');
      // setWebToTableTwo(webArr, 'clutchSlipTime', 't2_clutchSlipTime', 'val', 'timeDiff');

      setWebToTableTwo(
        webArr,
        'startSpdGearBoxMore2Cnt',
        't2_startSpdGearBoxMore2Cnt',
        'val',
      );
      setWebToTableTwo(
        webArr,
        'loweringSpdMore25Distance',
        't2_loweringSpdMore25Distance',
        'val',
        'dist',
      );
      setWebToTableTwo(
        webArr,
        'loweringSpdMore25Time',
        't2_loweringSpdMore25Time',
        'val',
        'timeDiff',
      );
      setWebToTableTwo(
        webArr,
        'crosswheelSpdMore7Distance',
        't2_crosswheelSpdMore7Distance',
        'val',
        'dist',
      );
      setWebToTableTwo(
        webArr,
        'crosswheelSpdMore7Time',
        't2_crosswheelSpdMore7Time',
        'val',
        'timeDiff',
      );
      setWebToTableTwo(
        webArr,
        'ptoSpdDistance',
        't2_ptoSpdDistance',
        'val',
        'dist',
      );
      setWebToTableTwo(
        webArr,
        'ptoSpdTime',
        't2_ptoSpdTime',
        'val',
        'timeDiff',
      );

      setWebToTableTwo(
        webArr,
        'handleBreakMoveCount',
        't2_handle_break_move_count',
        'val',
      );
      setWebToTableTwo(
        webArr,
        'handleBreakMoveTime',
        't2_handle_break_move_time',
        'val',
        'timeDiff',
      );
      setWebToTableTwo(
        webArr,
        'lowOilLampCount',
        't2_low_oil_lamp_count',
        'val',
      );
      setWebToTableTwo(
        webArr,
        'lowOilLampTime',
        't2_low_oil_lamp_time',
        'val',
        'timeDiff',
      );
      setWebToTableTwo(
        webArr,
        'hightCoolTempLampCount',
        't2_hight_cool_temp_lamp_count',
        'val',
      );
      setWebToTableTwo(webArr, 'cnt_real_pos', 'cnt_real_pos', 'val');
      setWebToTableTwo(
        webArr,
        'cnt_pos_missed',
        'cnt_pos_missed',
        'val',
        'deci',
      );

      // setWebToTableTwo(webArr, 'reserve_1', 't2_reserve_1', 'val');
      // setWebToTableTwo(webArr, 'reserve_2', 't2_reserve_2', 'val');
      // setWebToTableTwo(webArr, 'reserve_3', 't2_reserve_3', 'val');
      // setWebToTableTwo(webArr, 'reserve_4', 't2_reserve_4', 'val');
      // setWebToTableTwo(webArr, 'reserve_5', 't2_reserve_5', 'val');
      // setWebToTableTwo(webArr, 'reserve_6', 't2_reserve_6', 'val');
      // setWebToTableTwo(webArr, 'reserve_7', 't2_reserve_7', 'val');
      // setWebToTableTwo(webArr, 'reserve_8', 't2_reserve_8', 'val');
      // setWebToTableTwo(webArr, 'reserve_9', 't2_reserve_9', 'val');
      // setWebToTableTwo(webArr, 'reserve_10', 't2_reserve_10', 'val');
      // setWebToTableTwo(webArr, 'reserve_11', 't2_reserve_11', 'val');
      // setWebToTableTwo(webArr, 'reserve_12', 't2_reserve_12', 'val');

      /* вычисление производных показателей*/
      if (rows_experiment.motoEngine.val.val > 0) {
        rows_experiment.motoEngine.percent.val = 1;
        rows_experiment.motoEngineNoSpd.percent.val =
          rows_experiment.motoEngineNoSpd.val.val /
          rows_experiment.motoEngine.val.val;
        rows_experiment.motoEngineSpd.percent.val =
          rows_experiment.motoEngineSpd.val.val /
          rows_experiment.motoEngine.val.val;
      }
      if (rows_experiment.motoEngineSpd.val.val > 0) {
        rows_experiment.avgSpd.val.val =
          rows_experiment.distance.val.val /
          (rows_experiment.motoEngineSpd.val.val * 24);
        // расход топлива
        rows_experiment.canExpenceLHourMove.val.val =
          rows_experiment.canExpenceSpd.val.val /
          (rows_experiment.motoEngineSpd.val.val * 24);
        rows_experiment.rpm1900_2200Percent.val.val =
          rows_experiment.rpm1900_2200Time.val.val /
          rows_experiment.motoEngineSpd.val.val;
      }
      if (rows_experiment.distance.val.val > 0) {
        rows_experiment.swithGearBoxCntPerDist.val.val =
          rows_experiment.swithGearBoxCnt.val.val /
          rows_experiment.distance.val.val;
        // расход топлива
        rows_experiment.canExpence100km.val.val =
          (100 * rows_experiment.canExpence.val.val) /
          rows_experiment.distance.val.val;
        rows_experiment.canExpence100kmMove.val.val =
          (100 * rows_experiment.canExpenceSpd.val.val) /
          rows_experiment.distance.val.val;
        // kickdownCnt и absCount и ptoCnt pedalBreakTime engineBreakTime на 100км
        rows_experiment.kickdownCnt100km.val.val =
          (100 * rows_experiment.kickdownCnt.val.val) /
          rows_experiment.distance.val.val;
        rows_experiment.absCount100km.val.val =
          (100 * rows_experiment.absCount.val.val) /
          rows_experiment.distance.val.val;
        rows_experiment.ptoCnt100km.val.val =
          (100 * rows_experiment.ptoCnt.val.val) /
          rows_experiment.distance.val.val;
        rows_experiment.pedalBreakTime100km.val.val =
          (100 * rows_experiment.pedalBreakTime.val.val) /
          rows_experiment.distance.val.val;
        rows_experiment.engineBreakTime100km.val.val =
          (100 * rows_experiment.engineBreakTime.val.val) /
          rows_experiment.distance.val.val;
      }

      if (rows_experiment.motoEngineNoSpd.val.val) {
        // расход топлива
        rows_experiment.canExpenceLHourIdle.val.val =
          rows_experiment.canExpenceNoSpd.val.val /
          (rows_experiment.motoEngineNoSpd.val.val * 24);
      }

      if (rows_experiment.clutchUseMoveStartCnt.val.val) {
        // Доля с пробуксовкой //190720
        rows_experiment.clutchUseMoveStartSlipCntPercent.val.val =
          rows_experiment.clutchUseMoveStartSlipCnt.val.val /
          rows_experiment.clutchUseMoveStartCnt.val.val;
        // Средняя работа на одно начало движения
        rows_experiment.clutchWorkSummAvgStart.val.val =
          rows_experiment.clutchWorkSumm.val.val /
          rows_experiment.clutchUseMoveStartCnt.val.val;
      }

      // торможения
      if (rows_experiment.breakCount.val.val) {
        rows_experiment.engineBreakCntPercent.val.val =
          rows_experiment.engineBreakCnt.val.val /
          rows_experiment.breakCount.val.val;
      }

      if (rows_experiment.breakTime.val.val) {
        rows_experiment.engineBreakTimePercent.val.val =
          rows_experiment.engineBreakTime.val.val /
          rows_experiment.breakTime.val.val;
      }

      // работа сцепления при старте
      if (rows_experiment.clutchUseMoveStartWorkSumm.val.val > 0) {
        rows_experiment.clutchUseMoveStartExcessiveWorkPercent.val.val =
          rows_experiment.clutchUseMoveStartExcessiveWorkSumm.val.val /
          rows_experiment.clutchUseMoveStartWorkSumm.val.val;
        // rows_experiment.clutchUseMoveStartExcessiveWorkLowPercent.val.val = rows_experiment.clutchUseMoveStartExcessiveWorkLow.val.val / rows_experiment.clutchUseMoveStartWorkSumm.val.val;
        // rows_experiment.clutchSlipVehicleSlipWorkPercent.val.val = rows_experiment.clutchSlipVehicleSlipWork.val.val / rows_experiment.clutchUseMoveStartWorkSumm.val.val;
      }
      if (rows_experiment.clutchUseMoveStartExcessiveWorkSumm.val.val > 0) {
        rows_experiment.clutchUseMoveStartExcessiveWorkLowPercent.val.val =
          rows_experiment.clutchUseMoveStartExcessiveWorkLow.val.val /
          rows_experiment.clutchUseMoveStartExcessiveWorkSumm.val.val;
        rows_experiment.clutchSlipVehicleSlipWorkPercent.val.val =
          rows_experiment.clutchSlipVehicleSlipWork.val.val /
          rows_experiment.clutchUseMoveStartExcessiveWorkSumm.val.val;
      }

      let beginSpdAtGearbox = 0;
      let z = 1;
      for (z = 1; z < rows_experiment.header.GearBoxNumNameArr.length; z++) {
        beginSpdAtGearbox +=
          rows_experiment[rows_experiment.header.GearBoxNumNameArr[z]]
            .cntBeginMove.val;
      }

      // предачи КПП
      for (z = 1; z < rows_experiment.header.GearBoxNumNameArr.length; z++) {
        if (rows_experiment.distance.val.val > 0) {
          rows_experiment[
            rows_experiment.header.GearBoxNumNameArr[z]
          ].distPercent.val =
            rows_experiment[rows_experiment.header.GearBoxNumNameArr[z]].dist
              .val / rows_experiment.distance.val.val;
        }

        if (beginSpdAtGearbox > 0) {
          rows_experiment[
            rows_experiment.header.GearBoxNumNameArr[z]
          ].cntBeginMovePercent.val =
            rows_experiment[rows_experiment.header.GearBoxNumNameArr[z]]
              .cntBeginMove.val / beginSpdAtGearbox;
        }

        //190720
        if (rows_experiment.clutchSlipExcessiveRpmCnt.val.val > 0) {
          rows_experiment[
            rows_experiment.header.GearBoxNumNameArr[z]
          ].cntRpmClutchSlipPercent.val =
            rows_experiment[rows_experiment.header.GearBoxNumNameArr[z]]
              .cntRpmClutchSlip.val /
            rows_experiment.clutchSlipExcessiveRpmCnt.val.val;
        }

        if (
          rows_experiment[rows_experiment.header.GearBoxNumNameArr[z]]
            .accelMoveBeginMeasurementsCount.val &&
          rows_experiment[rows_experiment.header.GearBoxNumNameArr[z]]
            .cntBeginMove.val
        ) {
          rows_experiment[
            rows_experiment.header.GearBoxNumNameArr[z]
          ].avgAccelMoveBegin.val =
            rows_experiment[rows_experiment.header.GearBoxNumNameArr[z]]
              .accelMoveBeginMeasurementsSumm.val /
            rows_experiment[rows_experiment.header.GearBoxNumNameArr[z]]
              .accelMoveBeginMeasurementsCount.val /
            100;
        }

        if (
          rows_experiment[rows_experiment.header.GearBoxNumNameArr[z]]
            .accelMoveBeginWithClutchSlipMeasurementsCount.val
        ) {
          rows_experiment[
            rows_experiment.header.GearBoxNumNameArr[z]
          ].avgAccelMoveBeginWithClutchSlip.val =
            rows_experiment[rows_experiment.header.GearBoxNumNameArr[z]]
              .accelMoveBeginWithClutchSlipMeasurementsSumm.val /
            rows_experiment[rows_experiment.header.GearBoxNumNameArr[z]]
              .accelMoveBeginWithClutchSlipMeasurementsCount.val /
            100;
        }

        if (rows_experiment.clutchUseMoveStartExcessiveWorkSumm.val.val) {
          rows_experiment[
            rows_experiment.header.GearBoxNumNameArr[z]
          ].clutchSlipExcessiveWorkPercent.val =
            rows_experiment[rows_experiment.header.GearBoxNumNameArr[z]]
              .clutchSlipExcessiveWork.val /
            rows_experiment.clutchUseMoveStartExcessiveWorkSumm.val.val;
        }
      }

      //блокировки
      for (z = 0; z < rows_experiment.header.transmissionLockArr.length; z++) {
        if (rows_experiment.distance.val.val > 0) {
          rows_experiment[
            rows_experiment.header.transmissionLockArr[z].nameArr
          ].distPercent.val =
            rows_experiment[
              rows_experiment.header.transmissionLockArr[z].nameArr
            ].dist.val / rows_experiment.distance.val.val;
        }
      }

      //pto
      if (rows_experiment.ptoTime.val.val > 0) {
        rows_experiment.ptoEngineRpm800_1000Percent.val.val =
          rows_experiment.ptoEngineRpm800_1000Time.val.val /
          rows_experiment.ptoTime.val.val;
        rows_experiment.rpmMore1200PtoPercent.val.val =
          rows_experiment.rpmMore1200PtoTime.val.val /
          rows_experiment.ptoTime.val.val;
      }

      //доля неправильных переключений
      if (rows_experiment.swithGearBoxCnt.val.val > 0) {
        rows_experiment.swithGearBoxWrongPercent.val.val =
          rows_experiment.swithGearBoxWrongCnt.val.val /
          rows_experiment.swithGearBoxCnt.val.val;
      }
    }

    /*---- Calculate begin ----*/

    if (!curIndex) {
      this.rows_summ_forGraph_100 = this.addDesignTable_summ_forGraph(
        objs,
        ReportBegin,
        ReportEnd,
      );
      //персональный лист
      this.rows_summ = {};
      this.rows_summ = this.addDesignTable_summ();
      this.rows_summ.lines = [];
      this.rows_summ.lines.push([]); //чтобы все строки были как одна детализация, потом можно это изменить
    }

    let iobj = {};
    iobj.view = {};

    const smenasFlag = isGetSmenas;
    let smenasSetting = this.getSmenas(
      ReportBegin,
      ReportEnd,
      smenasFlag,
      smenasOrigin,
    );
    let is_graphics_summ_viol_to_dist = true;
    let ReportInterval = ReportEnd - ReportBegin;

    let [periods, smenasStr, w_smenasArr] = smenasSetting;

    //проверка раскладки смен (цикл ниже)
    let badSmenasFlag = false;

    for (let i = 0; i < periods.length - 1; i++) {
      if (periods[i].TimeEnd > periods[i + 1].TimeBegin) {
        iobj.error = 'Смены заданы неверно, проверьте время для смен:';
        badSmenasFlag = true;
        break;
      }
    }

    let z;
    for (z = 0; z < smenasStr.length; z++) {
      if (z == 0) {
        iobj.view.smenasStr = smenasStr[z];
        // ws_summ.Cell(++r_row_summ, 1).Value = smenasStr[z];
      } else {
        iobj.view.smenasStr += '; ' + smenasStr[z];
        // ws_summ.Cell(r_row_summ, 1).Value += '; ' + smenasStr[z];
      }
    }

    // obj = Report.GetObject(i);
    // r_row_experiment = 0;//для выгрузки на новый лист
    // r_row_detail_experiment = 0;//для выгрузки на новый лист
    // col_experiment = 1;//для выгрузки на новый лист
    // col_detail_experiment = 1;
    // r_row_detail = 0;//для выгрузки на новый лист
    // col_detail = 1;//для выгрузки на новый лист
    // r_row_person = 0;
    // col_person = 1;

    iobj.name = REPORT.objConf.name.trim();
    iobj.id = REPORT.objConf.id;
    // let ObjId = REPORT.objConf.id;

    // Report.Message(iobj.name + ' positions loading (загрузка данных) по объекту ' + iobj.name + ': ' + formatDate(new Date(), 'dd.mm.yyyy hh:nn:ss'));
    iobj.avtoNo = stateNum || 'г/н ' + notValue;
    iobj.avtoNo = iobj.avtoNo.replace(/ /g, '&nbsp;');

    iobj.avtoModel = objModel || needLoad;
    // iobj.avtoModel = needLoad;w_posArr
    iobj.vin = needLoad;
    iobj.client = REPORT.objConf.client;
    iobj.parent_code = REPORT.objConf.parent_code;
    iobj.owner = needLoad;

    iobj.moveTime = 0;
    iobj.days = [];
    iobj.summDays = [];
    iobj.realPosCnt = 0;
    iobj.gearboxType = notValidate;
    // iobj.isGalileodky = Boolean(obj.Version == 8460809);

    iobj.gearboxCnt = notValidate;
    iobj.gearboxCntForCalculation = 17;
    iobj.accelLimit = 50;
    iobj.isClutch = false;

    iobj.error = '';

    /* получение данных с WEB ресурса*/
    // w_result = []; //it is REPORT now
    let w_posArr = [];
    let w_poscount = 0;
    // sRequest = '';
    // var response = getRequest(ObjId, Report.Time1, Report.Time2, w_smenasRes);
    // if (response != 'Array') { //если select пустой, то в json окажется это
    //     try {
    //         eval('w_result = ' + response);
    //     } catch (e) {}
    // }
    /* обработаем ответ*/
    if (
      !('error' in REPORT) ||
      REPORT['error'] != 0 ||
      !('posArray' in REPORT) ||
      !('objConf' in REPORT)
    ) {
      /* случилась ошибка*/
      let err = '';
      if ('error' in REPORT) {
        err = ' (' + REPORT['error'] + ')';
      }
      iobj.error +=
        ' Ошибка при загрузке данных по объекту ' + iobj.name + err + ';';
      console.error(
        iobj.name +
          ' error loading (ошибка загрузки данных) по объекту ' +
          iobj.name +
          ': ' +
          formatDateHelper(new Date(), 'dd.mm.yyyy hh:nn:ss'),
      );
      return iobj;
    }

    if (REPORT['posArray']) {
      w_posArr = REPORT['posArray'];
      w_poscount = w_posArr.length;
    }

    /* получим конфигурацию*/
    let w_conf = REPORT['objConf'];
    /* тип КПП*/
    if (w_conf['gearbox_name']) {
      iobj.gearboxType = w_conf['gearbox_name'].trim();
    }
    if (~iobj['gearboxType'].indexOf('МКПП')) {
      iobj.accelLimit = 50;
    }
    if (w_conf['gearbox_is_clutch'] == 1) {
      iobj.isClutch = true;
    }
    /* кол-во передач*/
    if ('gearbox_cnt' in w_conf) {
      let w_gCnt = this.filterInt(w_conf['gearbox_cnt']);
      if (w_gCnt != 'NaN' && w_gCnt > 0 && w_gCnt < 25) {
        iobj.gearboxCntForCalculation = w_gCnt;
        iobj.gearboxCnt = w_gCnt;
      }
    }

    /* получим блокировки*/
    let transmissionLockArr = this.getTransmissionLockArr(w_conf);

    //персональный лист
    let rows_person = {};
    rows_person = this.addDesignTable_individual(iobj.accelLimit);

    //персональный лист создание
    // ws_person = addList(iobj.name);
    // ws_person.Column(1).Width = 6;
    let kppTypeStr;
    if (iobj.gearboxType == notValidate) {
      kppTypeStr = ' тип КПП не установлен';
    } else {
      kppTypeStr = ' ' + iobj.gearboxType;
    }

    iobj.view['t_name'] =
      '  Показатели эксплуатации ТС ' +
      iobj.name +
      ' / ' +
      iobj.avtoNo +
      kppTypeStr;
    iobj.view['t_interval'] =
      '  за период  с ' +
      formatDateHelper(new Date(ReportBegin), 'dd.mm.yyyy hh:nn:ss') +
      ' по ' +
      formatDateHelper(new Date(ReportEnd), 'dd.mm.yyyy hh:nn:ss');
    // ws_person.Cell(++r_row_person, 1).Value = '  Показатели эксплуатации ТС ' + iobj.name + ' / ' + iobj.avtoNo + kppTypeStr;
    // ws_person.Cell(r_row_person, 1).Style.Font.Bold = true;
    // ws_person.Cell(++r_row_person, 1).Value = '  за период  с ' + formatDate(new Date(ReportBegin), 'dd.mm.yyyy hh:nn:ss') + ' по ' + formatDate(new Date(ReportEnd), 'dd.mm.yyyy hh:nn:ss');

    // if(is_ws_experiment){
    //     ws_experiment = addList("EX_" + iobj.name);//лист из экспериментальной части
    // }

    //для графиков
    var series_time = {};
    series_time = {};
    var series_dist = {};
    series_dist = {};

    let is_graphics_dist = true;
    let is_graphics_time = true;
    if (is_graphics_dist) {
      series_dist.ViolDist = this.addSerie(
        'Cyan',
        'Количество нарушений приведенное к 100 км пробега / пробег',
        'Пробег, км',
        'Количество нарушений приведенное к 100 км пробега',
      );
    }
    if (is_graphics_time) {
      series_time.ViolTime = this.addSerie(
        'Cyan',
        'Количество нарушений приведенное к 100 км пробега, пробег / время',
        'Время',
        'Количество нарушений, ед.',
      );
      series_time.ViolDist = this.addSerie(
        'Darkgreen',
        'Количество нарушений приведенное к 100 км пробега, пробег / время',
        'Время',
        'Пробег за сутки/смену, км',
      );
    }

    let rows_experiment = {}; //создается всегда чтобы не дублировать логику детализации
    /* пересмотреть*/
    rows_experiment = this.addDesignTable_experiment(
      iobj.gearboxCntForCalculation,
      this.codeGearBoxNumName,
      transmissionLockArr,
    );
    //экспериментальная часть ниже
    // if(is_ws_experiment){
    //     ws_experiment.Cell(++r_row_experiment, 3).Value = 'Эксплуатация ТС ' + iobj.name + ' / ' + iobj.avtoModel + ' за период  с ' + formatDate(new Date(ReportBegin), 'dd.mm.yyyy hh:nn:ss') + ' по ' + formatDate(new Date(ReportEnd), 'dd.mm.yyyy hh:nn:ss');
    //     ws_experiment.Cell(r_row_experiment, 3).Style.Font.Bold = true;
    //     ws_experiment.Cell(++r_row_experiment, 2).Value = 'Модель/тип ТС: ' + iobj.avtoModel + ', VIN: ' + iobj.vin;
    //     //ws_experiment.Cell(++r_row_experiment, 1).Value = 'Модель/мощность ДВС:';
    //     ws_experiment.Cell(++r_row_experiment, 2).Value = 'Тип КПП/число передач: ' + iobj.gearboxType + ' /' + iobj.gearboxCnt;
    //     col_experiment = 2;
    //     ws_experiment.Cell(++r_row_experiment, col_experiment).Value = 'Наличие внедорожного режима:';
    //     var addString = '';
    //     if (rows_experiment.header.transmissionLockNameArr.length > 1) {
    //         for (var z = 1; z < rows_experiment.header.transmissionLockNameArr.length; z++) {
    //             if (addString.length > 80) {
    //                 ws_experiment.Cell(col_experiment == 2 ? r_row_experiment : r_row_experiment + 1, col_experiment).Value += addString;
    //                 addString = '';
    //                 col_experiment += 2;
    //             }
    //             addString += (addString.length == 0 ? ' ' : ", ") + rows_experiment.header[rows_experiment.header.transmissionLockNameArr[z]].name;
    //         }
    //     } else {
    //         ws_experiment.Cell(r_row_experiment, 1).Value += ' ' + notValidate;
    //     }
    //     ws_experiment.Cell(col_experiment == 2 ? r_row_experiment : r_row_experiment + 1, col_experiment).Value += addString;

    // }
    //экспериментальная часть выше

    let v_time;
    let next_time;
    let values = {};
    let valuesPrev = {};
    values = {};
    values.periodNum = -1;
    values.periodNumWasCalc = -1;
    values.periodCalculatedCount = 0;
    values.periodHasBeenCalculated = [];
    valuesPrev = {};
    valuesPrev.periodNum = -1;

    let violationSmenas = [];
    // violationSmenas = [];

    let curSmena = this.reset_curSmena({}, true);
    let posGraphCnt = 0; //кол-во точек на графике

    let curWeek;
    curWeek = {};
    curWeek.violationCnt = 0;
    curWeek.violationCnt_prev = 0;
    curWeek.dist = 0;
    curWeek.dist_prev = 0;
    curWeek.line_num = 0;
    curWeek.line_num_prev = 0;
    /* все столбцы с нарушениями*/

    let g_viol_cnt = 0;
    let g_forfeits = 0;
    let g_time_percent = 0;

    let begin_val = {}; //первая строка - не нулевая, если не с начала суток
    let days_val = {}; //запоминает суммы при переходе суток, кроме первой строки, запоминается после просмотра строки
    //var cur_val = [];//значение текущей строки, оно же pos
    let sum_val = {}; //итоговое значение sum_val = cur_val - begin_val + days_val = pos - begin_val + days_val
    let isValOfTheDay = false;
    // //распечатаем результат debug
    // if(is_debuggList){
    //     let ws_debug = addList('ws_debug');
    //     let ws_debug_row = 1;
    //     let ws_debug_col = 1;
    // }

    const eventsCalculate = new EventsCalculate(params, {
      arrKeyViolations: addCopyArray_helper(this.arrKeyViolations),
      arrKeyNoSum: addCopyArr_helper(this.arrKeyNoSum),
      arrKeyNoSummGetLast: addCopyArr_helper(this.arrKeyNoSummGetLast),
      setWebToTableOneAll: this.setWebToTableOneAll,
      mathArray: this.mathArray,
      calcPersonSummViolation: this.calcPersonSummViolation,
      personForDisplay: rows_person.personForDisplay,
      w_poscount,
      ReportBegin,
      ReportEnd,
    });
    let pos;
    let canOdometer = 0;

    /*---- начало обхода строк ----*/
    for (let j = 0; j < w_poscount; j++) {
      if (isValOfTheDay && j > 0) {
        /* предыдущие значения pos*/
        days_val = this.mathArray(
          days_val,
          pos,
          '+',
          this.arrKeyNoSum,
          this.arrKeyNoSummGetLast,
          'last',
        );
      }
      /* текущие значения pos*/
      pos = this.getPosValues(w_posArr[j]);
      v_time = pos['time'];
      if (j + 1 < w_poscount) {
        next_time = w_posArr[j + 1]['time'];
      } else {
        next_time = v_time;
      }
      isValOfTheDay = Boolean(pos['seconds_of_the_day'] == 86400);
      if (j == 0) {
        /* начальные значения*/
        begin_val = this.getFirtPosIsValOfTheDay(
          pos,
          this.arrKeyNoSum,
          false /*isValOfTheDay*/,
        ); //пробуем начальные значения писать всегда

        curSmena.begin = v_time;
      }

      values.periodNum = this.getPeriodNum(v_time, periods, values.periodNum); //получим текущий номер смены

      /* проверка смены на завершение*/
      if (values.periodNum != valuesPrev.periodNum && 'time' in sum_val) {
        //разрешаем соотвутствующим нарушениям вести подсчет нарушений в смене
        curSmena = this.endSmena({
          values,
          curSmena,
          periodNum: valuesPrev.periodNum,
          sum_val,
          arrKeyViolations: this.arrKeyViolations,
          periods,
          rows_person,
          rows_experiment,
          violationSmenas,
          v_time,
          codeGearBoxNumNameFunction: this.codeGearBoxNumName,
          setWebToTable,
          iobj,
          violationSummCalculate,
          transmissionLockArr,
          isGetSmenas,
        });
      }

      /* суммарные значения за весь период*/
      sum_val = this.mathArray(
        pos,
        begin_val,
        '-',
        this.arrKeyNoSum,
        this.arrKeyNoSummGetLast,
        'first',
      );
      sum_val = this.mathArray(
        sum_val,
        days_val,
        '+',
        this.arrKeyNoSum,
        this.arrKeyNoSummGetLast,
        'first',
      );
      sum_val.can_odometer = pos.can_odometer || canOdometer;
      canOdometer = sum_val.can_odometer;
      /* сумманые значение по строке посчитаны*/
      //получаем номер недели в строке
      for (let zz = 0; zz < this.rows_summ_forGraph_100.lines[0].length; zz++) {
        if (
          next_time * 1000 >
            this.rows_summ_forGraph_100.lines[0][zz].line_begin &&
          next_time * 1000 <= this.rows_summ_forGraph_100.lines[0][zz].line_end
        ) {
          curWeek.line_num = zz;
          if (curWeek.line_num != curWeek.line_num_prev) {
            endWeek({
              iobj,
              obj_num: curIndex,
              line_num: curWeek.line_num_prev,
              rows_summ_forGraph_100: this.rows_summ_forGraph_100,
              codeGearBoxNumNameFunction: this.codeGearBoxNumName,
              transmissionLockArr,
              sum_val,
              rows_person,
            });
            curWeek.line_num_prev = curWeek.line_num;
          }
        }
      }
      if (j == 0) {
        curSmena.begin = v_time;
      }

      eventsCalculate.calculateRows(pos, sum_val, j);

      refreshPrevValue();
      // // Show progress
      // if (objcnt > 0 && w_poscount > 0) {
      //     per = 100. * (1 / objcnt * (i + (j / w_poscount)));
      //     if (Math.floor(per, 0) > Math.floor(intper, 0)) {
      //         Report.SetProgress(per);
      //         intper = per;
      //     }
      // }
    }

    const cnt_real_pos = sum_val['cnt_real_pos'] || 0;
    // КАН одометр на начало отчета
    let dDistBegin = notValue;
    if (w_poscount > 0 && cnt_real_pos > 0) {
      if (begin_val['can_odometer']) {
        dDistBegin = begin_val['can_odometer'] / 1000; //
      } else {
        if (
          'beginValues' in REPORT &&
          'distBegin' in REPORT['beginValues'] &&
          REPORT['beginValues']['distBegin'] > 0
        ) {
          dDistBegin = REPORT['beginValues']['distBegin'] / 1000;
        } else {
          dDistBegin = notValue;
        }
      }
    }

    /*---- окончание обхода строк ----*/
    const eventsRows = eventsCalculate.getEventsRows();
    const regularMovingRows = eventsCalculate.getRegularMovingRows();
    const evacuatorMovingRows = eventsCalculate.getEvacuatorMovingRows();
    const evacuatorMovingDaysRows =
      eventsCalculate.getEvacuatorMovingDaysRows(dDistBegin);

    if (values.periodNumWasCalc != values.periodNum) {
      curSmena = this.endSmena({
        values,
        curSmena,
        periodNum: valuesPrev.periodNum,
        sum_val,
        arrKeyViolations: this.arrKeyViolations,
        periods,
        rows_person,
        rows_experiment,
        violationSmenas,
        v_time: 0,
        codeGearBoxNumNameFunction: this.codeGearBoxNumName,
        setWebToTable,
        iobj,
        violationSummCalculate,
        transmissionLockArr,
        isGetSmenas,
      });
    }

    if (isGetSmenas && periods.length !== values.periodCalculatedCount) {
      const periodsLength = periods.length;
      for (let periodNum = 0; periodNum < periodsLength; periodNum++) {
        if (!values.periodHasBeenCalculated.includes(periodNum)) {
          // этот период не попал в интервал позиций
          curSmena = this.endSmena({
            values,
            curSmena,
            periodNum,
            sum_val,
            arrKeyViolations: this.arrKeyViolations,
            periods,
            rows_person,
            rows_experiment,
            violationSmenas,
            v_time: 0,
            codeGearBoxNumNameFunction: this.codeGearBoxNumName,
            setWebToTable,
            iobj,
            violationSummCalculate,
            transmissionLockArr,
            isGetSmenas,
            isInsideInPositions: false, // вне позиций
          });
        }
      }
    }

    //вычисления перед выгрузкой
    //для таблицы понедельной
    let timeBegin = new Date(ReportBegin);
    let acceptTimeEnd = new Date(ReportEnd);

    let percentPosReceive = notData;

    //подготовка строки с суммой по группам нарушений
    let line = {};
    line = violationSummCalculate({
      codeGearBoxNumNameFunction: this.codeGearBoxNumName,
      setWebToTable,
      iobj,
      transmissionLockArr,
      sum_val,
      rows_person,
    });
    line.DModel = iobj.avtoModel;
    line.DAvtoNo = iobj.avtoNo;
    line.objId = iobj.id; // для сортировки в дальнейшем
    if (w_poscount > 0 && cnt_real_pos > 0) {
      line.notData = false;
      iobj.notData = false;

      endWeek({
        iobj,
        obj_num: curIndex,
        line_num: curWeek.line_num_prev,
        rows_summ_forGraph_100: this.rows_summ_forGraph_100,
        codeGearBoxNumNameFunction: this.codeGearBoxNumName,
        transmissionLockArr,
        sum_val,
        rows_person,
      });

      iobj.realPosCnt = cnt_real_pos;
      let cnt_pos_missed = sum_val['cnt_pos_missed'] / 10;
      let cntPosRecord = cnt_real_pos + cnt_pos_missed;

      if (cntPosRecord > 0) {
        //процент прихода данных
        percentPosReceive = 1 - cnt_pos_missed / cntPosRecord;
      }

      let lastRealPower = sum_val['last_real_power'] / 1000;
      let lastRealBattery = sum_val['last_real_buttery'] / 1000;
      let lastRealTime = sum_val['last_real_time'];

      if (
        iobj.realPosCnt > 0 &&
        acceptTimeEnd > lastRealTime &&
        timeBegin < lastRealTime &&
        acceptTimeEnd - lastRealTime > 40 * 1000 * 60 &&
        lastRealPower > 12 &&
        lastRealBattery > 3.8
      ) {
        let valueTimeReceive = acceptTimeEnd - lastRealTime;
        let coefficientTimeReceive =
          (acceptTimeEnd - timeBegin - valueTimeReceive) /
          (acceptTimeEnd - timeBegin);
        percentPosReceive *= coefficientTimeReceive;
      }
      /* заполнение строки данными */
      line.canExpence100km = rows_experiment.canExpence100km.val.val;
      line.DDistBegin = dDistBegin;

      // if (begin_val['can_odometer']) {
      //   line.DDistBegin = begin_val['can_odometer'] / 1000; //
      // } else {
      //   if (
      //     'beginValues' in REPORT &&
      //     'distBegin' in REPORT['beginValues'] &&
      //     REPORT['beginValues']['distBegin'] > 0
      //   ) {
      //     line.DDistBegin = REPORT['beginValues']['distBegin'] / 1000;
      //   } else {
      //     line.DDistBegin = notValue;
      //   }
      // }
      if (begin_val['can_moto']) {
        line.DMotoBegin = begin_val['can_moto'] / 3600; // пишем в секундах, а надо в часах
      } else {
        if (
          'beginValues' in REPORT &&
          'motoBegin' in REPORT['beginValues'] &&
          REPORT['beginValues']['motoBegin'] > 0
        ) {
          line.DMotoBegin = REPORT['beginValues']['motoBegin'] / 3600; // пишем в секундах, а надо в часах
        } else {
          line.DMotoBegin = notValue;
        }
      }
    } else {
      /*  заполнение строки нет данных*/
      line.DDist = notData;
      line.DDistBegin = notData;
      line.canExpence100km = notData;
      line.DMotoBegin = notData;
      line.notData = true;
      iobj.notData = true;
    }

    line.DReceivedPercent = percentPosReceive;
    //добавление строки в суммарный лист
    this.rows_summ.lines[0].push(addCopyArr_helper(line)); //все строки добавляются как одна детализация, чтобы не разделять жирной чертой
    line = {};

    rows_experiment.view = {};
    rows_experiment.view.list_name =
      'Эксплуатация ТС ' +
      iobj.name +
      ' / ' +
      iobj.avtoModel +
      ' за период  с ' +
      formatDateHelper(new Date(ReportBegin), 'dd.mm.yyyy hh:nn:ss') +
      ' по ' +
      formatDateHelper(new Date(ReportEnd), 'dd.mm.yyyy hh:nn:ss');
    rows_experiment.view.locksList = '';
    let addString = '';
    if (rows_experiment.header.transmissionLockNameArr.length > 1) {
      for (
        let z = 1;
        z < rows_experiment.header.transmissionLockNameArr.length;
        z++
      ) {
        if (addString.length > 80) {
          rows_experiment.view.locksList += addString + '<br>';
          addString = '';
        }
        addString +=
          (addString.length == 0 ? ' ' : ', ') +
          rows_experiment.header[
            rows_experiment.header.transmissionLockNameArr[z]
          ].name;
      }
    } else {
      rows_experiment.view.locksList += ' ' + notValidate;
    }
    rows_experiment.view.locksList += addString;

    iobj.rows_experiment = rows_experiment;
    iobj.rows_person = rows_person;
    iobj.periods = periods;
    iobj.smenasStr = smenasStr;
    iobj.isSplitSmenas = Boolean(smenasOrigin.length);
    iobj.eventsRows = eventsRows;
    iobj.regularMovingRows = regularMovingRows;
    iobj.evacuatorMovingRows = evacuatorMovingRows;
    iobj.evacuatorMovingDaysRows = evacuatorMovingDaysRows;

    return iobj;
  },

  rows_summ_forGraph_100: {},
  rows_summ: {},

  addSerie: function (color, s_name, s_name_x, s_name_y) {
    var serie = {};
    serie.title = s_name;
    serie.xName = s_name_x;
    serie.yName = s_name_y;
    serie.sign = 0;
    serie.color = color;
    serie.points = [];
    serie.points2 = [];
    return serie;
  },

  setWebToTableOneAll(targetObj = {}, webArr = {}) {
    /* перекладывание результата в таблицу номер 1*/
    const setWebToTableOne = (
      targetObj = {},
      webArr = {},
      tableArrName = '',
      webArrName = '',
      type = '',
      divisor = 0,
    ) => {
      // if (divisor === undefined) {
      //   divisor = 0;
      // }
      if (!targetObj[tableArrName]) {
        targetObj[tableArrName] = {};
      }

      const w_value = webArr[webArrName.toLowerCase()];
      if (type == 'timeDivisor' && w_value && divisor) {
        // rows_person.person[tableArrName]["DCount"] =
        targetObj[tableArrName]['DCount'] =
          Number(w_value / 60).toFixed(1) + ' минут'; //Интервал оборотов ДВС
        // rows_person.person[tableArrName]["DForfeits"] =
        targetObj[tableArrName]['DForfeits'] =
          1 + Math.floor(w_value / divisor);
        //rows_person.person[tableArrName]['DForfeitsRelative'] = rows_person.person[tableArrName]['DForfeits'] / (dist / 100000);//пробег в метрах
        return;
      }
      if (
        w_value != undefined &&
        // w_value != rows_person.person[tableArrName]["DCount"]
        w_value != targetObj[tableArrName]['DCount']
      ) {
        // rows_person.person[tableArrName]["DCount"] = w_value;
        // rows_person.person[tableArrName]["DForfeits"] = w_value;
        targetObj[tableArrName]['DCount'] = w_value;
        targetObj[tableArrName]['DForfeits'] = w_value;
      }
    };

    setWebToTableOne(
      targetObj,
      webArr,
      'tempOilAndAntifreeze',
      't1_tempOilAndAntifreeze',
    );
    setWebToTableOne(
      targetObj,
      webArr,
      'tempOilInPallet',
      't1_tempOilInPallet',
    );
    setWebToTableOne(targetObj, webArr, 'starterWork', 't1_starterWork');
    setWebToTableOne(targetObj, webArr, 'tempMode', 't1_tempMode');
    /*
     *это не нужно забирать т.к. рассчитывается отдельно
     *setWebToTableOne(webArr, 'specificIdling', 't1_specificIdling');
     */
    setWebToTableOne(targetObj, webArr, 'useRegulateRdc', 't1_useRegulateRdc');
    setWebToTableOne(
      targetObj,
      webArr,
      'rightGearForStart',
      't1_rightGearForStart',
    );
    setWebToTableOne(
      targetObj,
      webArr,
      'timothyInManualMode',
      't1_timothyInManualMode',
    );
    setWebToTableOne(
      targetObj,
      webArr,
      'timothyChangingD_Dx',
      't1_timothyChangingD_Dx',
    );
    setWebToTableOne(targetObj, webArr, 'applicationDX', 't1_applicationDX');
    setWebToTableOne(targetObj, webArr, 'slipping', 't1_slipping');
    setWebToTableOne(
      targetObj,
      webArr,
      'clutchSlipStart',
      't1_clutchSlipStart',
    );
    setWebToTableOne(
      targetObj,
      webArr,
      'diffTransmOverload',
      't1_diffTransmOverload',
    );
    setWebToTableOne(
      targetObj,
      webArr,
      'rpmStartSpdMore1200Cnt',
      't1_rpmStartSpdMore1200Cnt',
    );
    setWebToTableOne(
      targetObj,
      webArr,
      'accelSharplyCnt',
      't1_accelSharplyCnt',
    );
    setWebToTableOne(
      targetObj,
      webArr,
      'swithClutchNoSpdCnt',
      't1_swithClutchNoSpdCnt',
    );
    setWebToTableOne(targetObj, webArr, 'excessRpm', 't1_excessRpm');
    setWebToTableOne(targetObj, webArr, 'gearshiftRpm', 't1_gearshiftRpm');
    setWebToTableOne(
      targetObj,
      webArr,
      'upperToLowerRange',
      't1_upperToLowerRange',
    );
    setWebToTableOne(
      targetObj,
      webArr,
      'upperToLowerRangeAndGearshift',
      't1_upperToLowerRangeAndGearshift',
    );
    setWebToTableOne(targetObj, webArr, 'switchLock', 't1_switchLock');
    setWebToTableOne(targetObj, webArr, 'switchLower', 't1_switchLower');
    setWebToTableOne(targetObj, webArr, 'backAxlesLock', 't1_backAxlesLock');
    setWebToTableOne(targetObj, webArr, 'frontAxlesLock', 't1_frontAxlesLock');
    setWebToTableOne(targetObj, webArr, 'backWheelsLock', 't1_backWheelsLock');
    setWebToTableOne(targetObj, webArr, 'frontWheelsLock', 't1_frontWheelsLock');
    setWebToTableOne(
      targetObj,
      webArr,
      'moveLockCrossWheel',
      't1_moveLockCrossWheel',
    );
    setWebToTableOne(
      targetObj,
      webArr,
      'rpmSpdLess440CntG',
      't1_rpmSpdLess440CntG',
    );
    setWebToTableOne(
      targetObj,
      webArr,
      'swithClutchInsufficientCntG',
      't1_swithClutchInsufficientCntG',
    );
    setWebToTableOne(
      targetObj,
      webArr,
      'engineStalledspdCntG',
      't1_engineStalledspdCntG',
    );
    setWebToTableOne(
      targetObj,
      webArr,
      'swithGearBoxRewersSpdCnt',
      't1_swithGearBoxRewersSpdCnt',
    );
    setWebToTableOne(
      targetObj,
      webArr,
      'ptoRpmViolation',
      't1_ptoRpmViolation',
      'timeDivisor',
      60,
    );
    setWebToTableOne(targetObj, webArr, 'ptoKrenUnload', 't1_ptoKrenUnload');
    /* объединенное нарушение - УСТАРЕЛО, теперь оно едино*/
    //calcViolation_ptoSpdDistanceG_can_pto_gearbox(webArr);
    setWebToTableOne(
      targetObj,
      webArr,
      'ptoSpdDistanceG',
      't1_ptoSpdDistanceG',
    ); //не умножаем, потому как ложим в БД в метрах: targetObj.ptoSpdDistanceG.DCount = Math.floor(can.ptoSpdDistanceG * 1000);
    setWebToTableOne(
      targetObj,
      webArr,
      'can_pto_gearbox',
      't1_can_pto_gearbox',
    ); //не умножаем, потому как ложим в БД в секундах
    setWebToTableOne(
      targetObj,
      webArr,
      'rollforwardNeutral',
      't1_rollforwardNeutral',
      'timeDivisor',
      10,
    ); //
    setWebToTableOne(
      targetObj,
      webArr,
      'moveClutchOpen',
      't1_moveClutchOpen',
      'timeDivisor',
      10,
    ); //
    setWebToTableOne(
      targetObj,
      webArr,
      'workCruiseAndSpeedLimiter',
      't1_workCruiseAndSpeedLimiter',
    );
    setWebToTableOne(
      targetObj,
      webArr,
      'ineffectiveRpmWithNoMotoBrake',
      't1_ineffectiveRpmWithNoMotoBrake',
    );
    setWebToTableOne(targetObj, webArr, 'useMotoBrake', 't1_useMotoBrake');
  },

  calcPersonSummViolation(personForDisplay, personTable, distSumm) {
    /* переписано, проверить на нескольких, в том числе если объекта нет в БД статистики*/
    const line = {
      forfeits: 0, // сумма нарушений
      forfeitsRelative: 0,
      DDist: distSumm / 1000, //перевод в километры
    };

    for (let z = 0; z < personForDisplay.length; z++) {
      let groupName;
      for (let zz = 0; zz < personForDisplay[z].length; zz++) {
        if (zz == 0) {
          groupName = personForDisplay[z][zz]; //имя группировки в массиве
          line[groupName] = 0; //добавили сущность группировки
          continue; //пока пропускаем
        }
        //добавляем групповые нарушения
        const rowName = personForDisplay[z][zz]; //получили имя в массиве в соответствии с заданной группировкой
        line[groupName] += personTable[rowName].DForfeits; //суммировали значение
        line.forfeits += personTable[rowName].DForfeits; //суммировали значение всквозную
        if (distSumm > 0) {
          personTable[rowName].DForfeitsRelative =
            personTable[rowName].DForfeits / (distSumm / 100000); //суммировали значение
        }
      }
    }

    if (line.DDist > 0) {
      line.forfeitsRelative = (100 * line.forfeits) / line.DDist;
    }

    return line;
  },

  codeGearBoxNumName: function (_z) {
    return _z < 0 ? 1000 - 10 * _z : 10 * _z;
  },

  getTransmissionLockArr: function (w_conf) {
    /* получим блокировки*/
    let transmissionLockArr = [];
    for (let z = 0; z < 11; z++) {
      let w_colName = 'transmission_lock_' + z + '_name';
      if (!(w_colName in w_conf)) {
        continue;
      }

      if (w_conf[w_colName].trim().length > 2) {
        transmissionLockArr.push({
          nameLock: w_conf[w_colName].trim(),
          nameArr: 'transmission_lock_' + z,
          num: z,
        });
      }
    }

    return transmissionLockArr;
  },

  filterInt: function (value) {
    if (/^(\-|\+)?([0-9]+|Infinity)$/.test(value)) {
      return Number(value);
    }
    return NaN;
  },

  addDesignTable_individual: function (accelLimit) {
    //создание структуры таблиц статистики
    //шапка отчета
    function addHeaderDict(
      name,
      minWidth,
      colspan,
      rowspan,
      minHeight,
      alignmentH,
      format,
      rowAlignmentH,
    ) {
      return {
        name: name ? name : 'NULL',
        minWidth: minWidth ? minWidth : 0,
        minHeight: minHeight ? minHeight : 0,
        colspan: colspan ? colspan : 0,
        rowspan: rowspan ? rowspan : 0,
        alignmentH: alignmentH != undefined ? alignmentH : -1,
        format: format ? format : 0, //используется при выгрузке детализации
        rowAlignmentH: rowAlignmentH != undefined ? rowAlignmentH : -1, //используется при выгрузке детализации
        colnum: 0, //номер столбца в шапке таблицы, используется далее, тут оставлять 0
        rownum: 0, //номер троки в шапке таблицы, используется далее при транспонировании таблицы, тут оставлять 0
        cellStep: 0, //объединение ячеек по вертикали для строки, не являющейся шапкой, используется при расчетах, тут оставлять 0
      };
      /*
             wsws.Cell(row, 20).Style.Alignment.Horizontal = 7; //выравнивание по правому краю
             wsws.Cell(row, 20).Style.Alignment.Horizontal = 2; //выравнивание по ширине
             wsws.Cell(row, 20).Style.Alignment.Horizontal = 3; //выравнивание по левому краю
             wsws.Cell(row, 20).Style.Alignment.Horizontal = 0; //выравнивание по центру
             */
    }

    //строка
    function addRowDict(header, format, val, rowspan, colspan) {
      return {
        header: header ? header : false,
        format: format ? format : 0,
        val: val ? val : 0,
        rowspan: rowspan ? rowspan : 0,
        colspan: colspan ? colspan : 0,
      };
    }
    function addHeaderRowDefaultDict(
      rows,
      rowArrname,
      addHeaderDictArr,
      addRowDictArr,
    ) {
      rows.header[rowArrname] = addHeaderDict(
        addHeaderDictArr.Hname,
        addHeaderDictArr.HminWidth,
        addHeaderDictArr.Hcolspan,
        addHeaderDictArr.Hrowspan,
        addHeaderDictArr.HminHeight,
        addHeaderDictArr.HalignmentH,
        addHeaderDictArr.Rformat,
        addHeaderDictArr.RalignmentH,
      );
      if (addRowDictArr) {
        rows[rowArrname] = {};
        rows[rowArrname].val = addRowDict(
          rowArrname,
          addRowDictArr.Rformat,
          addRowDictArr.Rval,
          addRowDictArr.Rrowspan,
          addRowDictArr.Rrcolspan,
        );
      }
    }
    //создание сущности
    var rows = {};
    //объявление ШАПОК таблиц
    //объявление таблицы ДЕТАЛИЗАЦИЯ, строки для нее будут созданы далее
    rows.header = {};
    addHeaderRowDefaultDict(rows, 'DNum', {
      Hname: '№ п/п',
      HminWidth: 8,
      Hcolspan: 0,
      Hrowspan: 0,
      HminHeight: 0,
      HalignmentH: 0,
      Rformat: 0,
      RalignmentH: 7,
    }); //заголовок (шапка)
    addHeaderRowDefaultDict(rows, 'DSkill', {
      Hname: 'Навык',
      HminWidth: 51,
      Hcolspan: 4,
      Hrowspan: 0,
      HminHeight: 0,
      HalignmentH: 0,
      Rformat: 0.5,
      RalignmentH: -1,
    }); //заголовок (шапка)
    //addHeaderRowDefaultDict(rows, 'DType', {Hname: "Оценка", HminWidth: 20, Hcolspan: 0, Hrowspan: 0, HminHeight: 0, HalignmentH: 0, Rformat: 0, RalignmentH: -1});//заголовок (шапка)
    addHeaderRowDefaultDict(rows, 'DCount', {
      Hname: 'Количество нарушений (deleted)',
      HminWidth: 12,
      Hcolspan: 2,
      Hrowspan: 0,
      HminHeight: 0,
      HalignmentH: 0,
      Rformat: 0,
      RalignmentH: 7,
    }); //заголовок (шапка)
    //addHeaderRowDefaultDict(rows, 'DForfeits', {Hname: "Сумма штрафных баллов", HminWidth: 12, Hcolspan: 0, Hrowspan: 0, HminHeight: 0, HalignmentH: 0, Rformat: 12, RalignmentH: 7});//заголовок (шапка)
    addHeaderRowDefaultDict(rows, 'DForfeits', {
      Hname: 'Количество нарушений',
      HminWidth: 12,
      Hcolspan: 2,
      Hrowspan: 0,
      HminHeight: 0,
      HalignmentH: 0,
      Rformat: 12,
      RalignmentH: 7,
    }); //заголовок (шапка)
    //addHeaderRowDefaultDict(rows, 'DForfeitsRelative', {Hname: "Сумма баллов приведенная к 100 км пробега", HminWidth: 16, Hcolspan: 0, Hrowspan: 0, HminHeight: 0, HalignmentH: 0, Rformat: 12, RalignmentH: 7});//заголовок (шапка)
    addHeaderRowDefaultDict(rows, 'DForfeitsRelative', {
      Hname: 'Количество нарушений приведенное к 100 км пробега',
      HminWidth: 25,
      Hcolspan: 2,
      Hrowspan: 0,
      HminHeight: 0,
      HalignmentH: 0,
      Rformat: 12,
      RalignmentH: 7,
    }); //заголовок (шапка)
    var roughly = 'грубое нарушение';
    var ineffectively = 'не эффективно';
    var violation = 'нарушение';
    rows.detailSetting = [];
    //var detailName = [];
    rows.detailSetting.push([
      'startEngineGroup',
      'Запуск двигателя',
      '1.',
      '', //arrName, displayName, number, type
      'tempOilAndAntifreeze',
      'Уровень масла и ОЖ',
      '1.1.',
      roughly,
      'tempOilInPallet',
      'Температура масла в поддоне',
      '1.2.',
      '',
      'starterWork',
      'Работа стартера',
      '1.3.',
      '',
    ]);
    rows.detailSetting.push([
      'motoIdlingGroup',
      'Работа ДВС на холостом ходу',
      '2.',
      '',
      'tempMode',
      'Тепловой режим',
      '2.1.',
      '',
      'specificIdling',
      'Удельное время работы на х.х.',
      '2.2.',
      '',
    ]);
    rows.detailSetting.push([
      'moveStartGroup',
      'Начало движения',
      '3.',
      '',
      'useRegulateRdc',
      'Для МКПП: использование регулирования оборотов EDC (при трогании "компьютер" сам "дает газу")',
      '3.1.',
      '',
      'rightGearForStart',
      'Правильный выбор передач для трогания',
      '3.2.',
      roughly,
      'timothyInManualMode',
      'Типматик в ручном режиме',
      '3.3.',
      roughly,
      'rpmStartSpdMore1200Cnt',
      'Обороты ДВС более 1200 при начале движения',
      '3.4.',
      '',
      'accelSharplyCnt',
      'Резкое, более ' +
        accelLimit +
        '% в сек., нажатие акселератора при начале движения',
      '3.5.',
      '',
      'swithClutchNoSpdCnt',
      'Простой с разомкнутым сцеплением более 1 мин.',
      '3.6.',
      '',
      'clutchSlipStart',
      'Трогание с излишней пробуксовкой сцепления',
      '3.7.',
      roughly,
      'diffTransmOverload',
      'Работа дифференциалов трансмиссии при скорости 0 км/ч',
      '3.8.',
      '',
      'timothyChangingD_Dx',
      'Смена диапазона типматика D/Dx',
      '3.9.',
      roughly,
      'applicationDX',
      'Для Тип Матика: Применение диапазона Dx обязательно',
      '3.10.',
      '',
      'slipping',
      'Буксование (пережег сцепления, разогрев до 100 градусов)',
      '3.11.',
      roughly,
    ]);
    rows.detailSetting.push([
      'moveGroup',
      'Движение',
      '4.',
      '',
      'excessRpm',
      'Превышение допустимых оборотов ДВС',
      '4.1.',
      roughly,
      'gearshiftRpm',
      'Обороты ДВС при переключении передач',
      '4.2.',
      '',
      'upperToLowerRange',
      'Переход с верхнего на нижний ряд КПП',
      '4.3.',
      '',
      'upperToLowerRangeAndGearshift',
      'Двойной выжим, перегазовка при переключении передач',
      '4.4.',
      '',
      'moveLockCrossWheel',
      'Движение с включенной межколесной блокировкой',
      '4.5.',
      roughly,
      'rpmSpdLess440CntG',
      'При движении обороты ДВС опустились ниже 440 об/мин',
      '4.6.',
      '',
      'swithClutchInsufficientCntG',
      'Для механической КПП: неполное включение сцепления',
      '4.7.',
      roughly,
      'engineStalledspdCntG',
      'Заглох при движении',
      '4.8.',
      '',
      'swithGearBoxRewersSpdCnt',
      'Переключение КПП с переднего на задний ход при скорости > 0',
      '4.9.',
      roughly,
      'switchLock',
      'Включение/выключение блокировок (на скорости или с нажатой педалью газа)',
      '4.10.',
      roughly,
      'switchLower',
      'Вкл/выкл пониженной передачи РКП (на скорости или с нажатой педалью газа)',
      '4.11.',
      '',
      'backAxlesLock',
      'Нет вкл. межосевой блокировки задней тележки',
      '4.12.',
      '',
      'frontAxlesLock',
      'Нет вкл. межосевой блокировки переднего моста',
      '4.13.',
      '',
      'backWheelsLock',
      'Нет вкл. межколесного дифференциала задней тележки',
      '4.14.',
      '',
      'frontWheelsLock',
      'Нет вкл. межколесного дифференциала передней оси',
      '4.15.',
      '',
      //'swithClutchNoSpdTimeG','Простой с разомкнутым сцеплением более 1 мин., общее время', '4.8.', ineffectively,
    ]);
    rows.detailSetting.push([
      'drivingStyleGroup',
      'Стиль вождения',
      '5.',
      '',
      'rollforwardNeutral',
      'Движение накатом на нейтральной передаче',
      '5.1.',
      '',
      'moveClutchOpen',
      'Движение с нажатой педалью сцепления',
      '5.2.',
      '',
      //'moveGearboxOnAndAccelOff','Движение с включенной передачей и отпущенной педалью газа (экономия топлива),  в сравнении с движением на нейтральной передаче и с нажатым сцеплением', '5.3.', ineffectively,
      'workCruiseAndSpeedLimiter',
      'Работа с круиз-контролем и ограничителем скорости',
      '5.3.',
      '',
      'ineffectiveRpmWithNoMotoBrake',
      'Неэффективные обороты более 1900 при движении без применения моторного тормоза',
      '5.4.',
      '',
      'useMotoBrake',
      'Использование моторного тормоза',
      '5.5.',
      '',
      //'clutchSlipCntG','Критическая пробуксовка сцепления, количество', '5.11_EX', roughly,
      //'acceleration_Deceleration','Разгон/торможение', '5.14.', violation,//Удалено по письму от 12.01.2018
      //'sharpAcceleration_Deceleration','Резкие ускорения/торможения', '5.15.', violation,//Удалено по письму от 12.01.2018
      //'durationAcceleration_Deceleration','Длительность разгона/торможения', '5.16.', violation,//Удалено по письму от 12.01.2018
      //'passageTurns','Прохождение поворотов', '5.17.', violation,//Удалено по письму от 12.01.2018
      //'passageObstacles','Прохождение препятствий', '5.18.', violation,//Удалено по письму от 12.01.2018
      //'accelSharplyViolationCnt','Резкие нажатия педали газа', '5.19.', violation//Перенесено по письму от 12.01.2018
    ]);
    rows.detailSetting.push([
      'ptoWorkKomGroup',
      'Работа КОМ',
      '6.',
      '',
      'ptoRpmViolation',
      'Интервал оборотов ДВС',
      '6.1.',
      '',
      //'ptoRpmRoughly','Интервал оборотов ДВС', '6.2.', roughly,
      //'ptoGearbox', 'Передача КПП', '6.2.', roughly,
      'ptoKrenUnload',
      'Превышение крена при разгрузке',
      '6.2.',
      '',
      //'ptoSpdDistanceG','Для самосвалов: пробег с включенным КОМ, км.', '6.4.', violation
      'ptoSpdDistanceG',
      'Движение с включенным КОМ',
      '6.3.',
      roughly,
      'can_pto_gearbox',
      'Объединенное нарушение, не выводится',
      '6.-',
      '',
    ]);

    //rows.detailSetting.push(detailName);
    //настройка группировки столбцов
    rows.personForDisplay = [
      //именно для этих имен будет посчитана суммарная таблица и выведен лист индивидуальный
      [
        'startEngineGroup',
        'tempOilAndAntifreeze',
        'tempOilInPallet',
        'starterWork',
      ], //по шаблону: [groupname, name, name]
      ['motoIdlingGroup', 'tempMode', 'specificIdling'],
      [
        'moveStartGroup',
        'useRegulateRdc',
        'rightGearForStart',
        'timothyInManualMode',
        'rpmStartSpdMore1200Cnt',
        'accelSharplyCnt',
        'swithClutchNoSpdCnt',
        'clutchSlipStart',
        'diffTransmOverload',
        'timothyChangingD_Dx',
        'applicationDX',
        'slipping',
      ],
      [
        'moveGroup',
        'excessRpm',
        'gearshiftRpm',
        'upperToLowerRange',
        'upperToLowerRangeAndGearshift',
        'moveLockCrossWheel',
        'rpmSpdLess440CntG',
        'swithClutchInsufficientCntG',
        'engineStalledspdCntG',
        'swithGearBoxRewersSpdCnt',
        'switchLock',
        'switchLower',
        'backAxlesLock',
        'frontAxlesLock',
        'backWheelsLock',
        'frontWheelsLock',
      ],
      [
        'drivingStyleGroup',
        'rollforwardNeutral',
        'moveClutchOpen',
        /*'moveGearboxOnAndAccelOff',*/ 'workCruiseAndSpeedLimiter',
        'ineffectiveRpmWithNoMotoBrake',
        'useMotoBrake' /*'clutchSlipCntG',  'diffTransmOverload'*/,
        /*'swithClutchNoSpdTimeG', 'rpmSpdLess440CntG', 'swithClutchInsufficientCntG', 'engineStalledspdCntG',*/
        /*'acceleration_Deceleration', 'sharpAcceleration_Deceleration',*/
        /*'durationAcceleration_Deceleration', 'passageTurns', 'passageObstacles', 'accelSharplyViolationCnt'*/
      ],
      [
        'ptoWorkKomGroup',
        'ptoRpmViolation',
        /*'ptoRpmRoughly', 'ptoGearbox', */ 'ptoKrenUnload',
        'ptoSpdDistanceG',
      ],
    ];

    rows.person = {};
    for (var z = 0; z < rows.detailSetting.length; z++) {
      let groupName;
      for (var zz = 0; zz < rows.detailSetting[z].length - 3; zz += 4) {
        if (zz == 0) groupName = rows.detailSetting[z][zz];
        var rowName = rows.detailSetting[z][zz];
        rows.person[rowName] = {};
        rows.person[rowName].DSkill = rows.detailSetting[z][zz + 1];
        rows.person[rowName].DNum = rows.detailSetting[z][zz + 2]; //DNum - it is header name (columnName)
        rows.person[rowName].IsRoughly = Boolean(
          rows.detailSetting[z][zz + 3] == roughly,
        ); //это нарушение должно быть выделено красным цветом (только текст, не заливка)
        if (groupName == rowName) {
          rows.person[rowName].DCount = '';
          rows.person[rowName].DForfeits = '';
          rows.person[rowName].DForfeitsRelative = '';
          rows.person[rowName].DSkill_isbold = true;
          rows.person[rowName].DNum_isbold = true;
        } else {
          rows.person[rowName].DCount = 0;
          rows.person[rowName].DForfeits = 0;
          rows.person[rowName].DForfeitsRelative = 0;
        }
      }
    }

    /*решено использовать заголовки экспериментальной части, чтобы не дублировать логику
        for(var i = 0; i < transmissionLockArr.length; i++){
            if(i==0){
                rows.header['transmissionLock_name'] = addHeaderDict('Название режима трансмиссии', 0, 2, 0, 30);//если есть хотябы один - добавится и таблица
                rows.header.transmissionLockNameArr.push('transmissionLock_name');//для разворачивания вытянутых имен из конфигурации уже при выгрузке таблицы
            }
            rows.person[rows.header.transmissionLockArr[i].nameArr] = addHeaderDict(String(rows.header.transmissionLockArr[i].nameLock), 0, 2);//заполнение шапки с названием блокировок
            rows.header.transmissionLockNameArr.push(rows.header.transmissionLockArr[i].nameArr);//для разворачивания вытянутых имен из конфигурации уже при выгрузке таблицы
        }*/

    //объявлние СТРОК таблиц
    //первая таблица
    /*format
         0.5: wsws.Cell(r_row_, col_).SetValueRaw(val);
         1 formatDate(new Date(val), 'dd.mm.yyyy hh:nn:ss');
         2 formatDate(new Date(val), 'dd.mm.yyyy');
         3 formatDate(new Date(val), 'hh:nn:ss');
         4 formatTime(val, 'tt:nn:ss');
         case 5:
         ws.Cell(rnum, cnum).Value = val;
         ws.Cell(rnum, cnum).Style.NumberFormat.Format = '0.0';
         case 6:
         ws.Cell(rnum, cnum).Value = val;
         ws.Cell(rnum, cnum).Style.NumberFormat.Format = '0.0%';
         */

    // какие показатели разбивать по сменам
    rows.periodSplitRows = ['slipping'];

    // какие показатели разбивать по сменам и они зависят от других значений
    rows.periodSplitDependentRows = {};

    return rows;
  },

  addDesignTable_summ: function () {
    //создание структуры таблиц статистики
    //шапка отчета
    function addHeaderDict(
      name,
      minWidth,
      colspan,
      rowspan,
      minHeight,
      alignmentH,
      format,
      rowAlignmentH,
    ) {
      return {
        name: name ? name : 'NULL',
        minWidth: minWidth ? minWidth : 0,
        minHeight: minHeight ? minHeight : 0,
        colspan: colspan ? colspan : 0,
        rowspan: rowspan ? rowspan : 0,
        alignmentH: alignmentH != undefined ? alignmentH : -1,
        format: format ? format : 0, //используется при выгрузке детализации
        rowAlignmentH: rowAlignmentH != undefined ? rowAlignmentH : -1, //используется при выгрузке детализации
        colnum: 0, //номер столбца в шапке таблицы, используется далее, тут оставлять 0
        rownum: 0, //номер троки в шапке таблицы, используется далее при транспонировании таблицы, тут оставлять 0
        cellStep: 0, //объединение ячеек по вертикали для строки, не являющейся шапкой, используется при расчетах, тут оставлять 0
      };
      /*
            wsws.Cell(row, 20).Style.Alignment.Horizontal = 7; //выравнивание по правому краю
            wsws.Cell(row, 20).Style.Alignment.Horizontal = 2; //выравнивание по ширине
            wsws.Cell(row, 20).Style.Alignment.Horizontal = 3; //выравнивание по левому краю
            wsws.Cell(row, 20).Style.Alignment.Horizontal = 0; //выравнивание по центру
            */
    }

    //строка
    function addRowDict(header, format, val, rowspan, colspan) {
      return {
        header: header ? header : false,
        format: format ? format : 0,
        val: val ? val : 0,
        rowspan: rowspan ? rowspan : 0,
        colspan: colspan ? colspan : 0,
      };
    }
    function addHeaderRowDefaultDict(
      rows,
      rowArrname,
      addHeaderDictArr,
      addRowDictArr,
    ) {
      rows.header[rowArrname] = addHeaderDict(
        addHeaderDictArr.Hname,
        addHeaderDictArr.HminWidth,
        addHeaderDictArr.Hcolspan,
        addHeaderDictArr.Hrowspan,
        addHeaderDictArr.HminHeight,
        addHeaderDictArr.HalignmentH,
        addHeaderDictArr.Rformat,
        addHeaderDictArr.RalignmentH,
      );
      if (addRowDictArr) {
        rows[rowArrname] = {};
        rows[rowArrname].val = addRowDict(
          rowArrname,
          addRowDictArr.Rformat,
          addRowDictArr.Rval,
          addRowDictArr.Rrowspan,
          addRowDictArr.Rrcolspan,
        );
      }
    }
    //создание сущности
    var rows = {};
    //объявление ШАПОК таблиц
    //объявление таблицы ДЕТАЛИЗАЦИЯ, строки для нее будут созданы далее
    rows.header = {};
    addHeaderRowDefaultDict(rows, 'DModel', {
      Hname: 'Модель ТС',
      HminWidth: 20,
      Hcolspan: 0,
      Hrowspan: 0,
      HminHeight: 0,
      HalignmentH: 0,
      Rformat: 0,
      RalignmentH: -1,
    }); //заголовок (шапка)
    addHeaderRowDefaultDict(rows, 'DAvtoNo', {
      Hname: 'Гос номер',
      HminWidth: 14,
      Hcolspan: 0,
      Hrowspan: 0,
      HminHeight: 0,
      HalignmentH: 0,
      Rformat: 0.5,
      RalignmentH: -1,
    }); //заголовок (шапка)
    addHeaderRowDefaultDict(rows, 'DDist', {
      Hname: 'Пробег, км',
      HminWidth: 11,
      Hcolspan: 0,
      Hrowspan: 0,
      HminHeight: 0,
      HalignmentH: 0,
      Rformat: 13,
      RalignmentH: -1,
    }); //заголовок (шапка)
    addHeaderRowDefaultDict(rows, 'canExpence100km', {
      Hname: 'Общий расход л/100 км, CAN',
      HminWidth: 11,
      Hcolspan: 0,
      Hrowspan: 0,
      HminHeight: 0,
      HalignmentH: 0,
      Rformat: 13,
      RalignmentH: -1,
    }); //заголовок (шапка)
    addHeaderRowDefaultDict(rows, 'DDistBegin', {
      Hname: 'Общий пробег а/м на начало периода, км',
      HminWidth: 16,
      Hcolspan: 0,
      Hrowspan: 0,
      HminHeight: 0,
      HalignmentH: 0,
      Rformat: 13,
      RalignmentH: -1,
    }); //заголовок (шапка)
    addHeaderRowDefaultDict(rows, 'DMotoBegin', {
      Hname: 'Наработка ДВС на начало периода, м/часов',
      HminWidth: 19,
      Hcolspan: 0,
      Hrowspan: 0,
      HminHeight: 0,
      HalignmentH: 0,
      Rformat: 5,
      RalignmentH: -1,
    }); //заголовок (шапка)
    addHeaderRowDefaultDict(rows, 'DReceivedPercent', {
      Hname: '% прихода данных',
      HminWidth: 11,
      Hcolspan: 0,
      Hrowspan: 0,
      HminHeight: 0,
      HalignmentH: 0,
      Rformat: 6,
      RalignmentH: -1,
    }); //заголовок (шапка)
    addHeaderRowDefaultDict(rows, 'startEngineGroup', {
      Hname: 'Запуск ДВС',
      HminWidth: 15,
      Hcolspan: 0,
      Hrowspan: 0,
      HminHeight: 0,
      HalignmentH: 0,
      Rformat: 12,
      RalignmentH: -1,
    }); //заголовок (шапка)
    addHeaderRowDefaultDict(rows, 'motoIdlingGroup', {
      Hname: 'Работа ДВС на х. х.',
      HminWidth: 15,
      Hcolspan: 0,
      Hrowspan: 0,
      HminHeight: 0,
      HalignmentH: 0,
      Rformat: 12,
      RalignmentH: -1,
    }); //заголовок (шапка)
    addHeaderRowDefaultDict(rows, 'moveStartGroup', {
      Hname: 'Начало движения',
      HminWidth: 15,
      Hcolspan: 0,
      Hrowspan: 0,
      HminHeight: 0,
      HalignmentH: 0,
      Rformat: 12,
      RalignmentH: -1,
    }); //заголовок (шапка)
    addHeaderRowDefaultDict(rows, 'moveGroup', {
      Hname: 'Движение',
      HminWidth: 15,
      Hcolspan: 0,
      Hrowspan: 0,
      HminHeight: 0,
      HalignmentH: 0,
      Rformat: 12,
      RalignmentH: -1,
    }); //заголовок (шапка)
    addHeaderRowDefaultDict(rows, 'ptoWorkKomGroup', {
      Hname: 'Работа КОМ',
      HminWidth: 15,
      Hcolspan: 0,
      Hrowspan: 0,
      HminHeight: 0,
      HalignmentH: 0,
      Rformat: 12,
      RalignmentH: -1,
    }); //заголовок (шапка)
    addHeaderRowDefaultDict(rows, 'drivingStyleGroup', {
      Hname: 'Стиль вождения',
      HminWidth: 15,
      Hcolspan: 0,
      Hrowspan: 0,
      HminHeight: 0,
      HalignmentH: 0,
      Rformat: 12,
      RalignmentH: -1,
    }); //заголовок (шапка)
    //addHeaderRowDefaultDict(rows, 'forfeits', {Hname: "Сумма баллов", HminWidth: 15, Hcolspan: 0, Hrowspan: 0, HminHeight: 0, HalignmentH: 0, Rformat: 12, RalignmentH: -1});//заголовок (шапка)
    addHeaderRowDefaultDict(rows, 'forfeits', {
      Hname: 'Сумма нарушений',
      HminWidth: 15,
      Hcolspan: 0,
      Hrowspan: 0,
      HminHeight: 0,
      HalignmentH: 0,
      Rformat: 12,
      RalignmentH: -1,
    }); //заголовок (шапка)
    //addHeaderRowDefaultDict(rows, 'forfeitsRelative', {Hname: "Баллы с учетом пробега", HminWidth: 15, Hcolspan: 0, Hrowspan: 0, HminHeight: 0, HalignmentH: 0, Rformat: 12, RalignmentH: -1});//заголовок (шапка)
    addHeaderRowDefaultDict(rows, 'forfeitsRelative', {
      Hname: 'Нарушения на 100 км пробега',
      HminWidth: 15,
      Hcolspan: 0,
      Hrowspan: 0,
      HminHeight: 0,
      HalignmentH: 0,
      Rformat: 13,
      RalignmentH: -1,
    }); //заголовок (шапка)
    rows.settingsSumm = [
      'DModel',
      'DAvtoNo',
      'DDist',
      'canExpence100km',
      'DDistBegin',
      'DMotoBegin',
      'DReceivedPercent',
      'startEngineGroup',
      'motoIdlingGroup',
      'moveStartGroup',
      'moveGroup',
      'ptoWorkKomGroup',
      'drivingStyleGroup',
      'forfeits',
      'forfeitsRelative',
    ];
    //объявлние СТРОК таблиц
    //первая таблица
    /*format
        0.5: wsws.Cell(r_row_, col_).SetValueRaw(val);
        1 formatDate(new Date(val), 'dd.mm.yyyy hh:nn:ss');
        2 formatDate(new Date(val), 'dd.mm.yyyy');
        3 formatDate(new Date(val), 'hh:nn:ss');
        4 formatTime(val, 'tt:nn:ss');
        case 5:
        ws.Cell(rnum, cnum).Value = val;
        ws.Cell(rnum, cnum).Style.NumberFormat.Format = '0.0';
        case 6:
        ws.Cell(rnum, cnum).Value = val;
        ws.Cell(rnum, cnum).Style.NumberFormat.Format = '0.0%';
        */
    return rows;
  },

  addDesignTable_experiment: function (
    gearBoxCount,
    codeGearBoxNumNameFunction,
    transmissionLockArr,
  ) {
    //создание структуры таблиц статистики
    //шапка отчета
    function addHeaderDict(
      name = 'NULL',
      minWidth = 0,
      colspan = 0,
      rowspan = 0,
      minHeight = 0,
      alignmentH = -1,
      format = 0,
      rowAlignmentH = -1,
      fontsize = 12,
    ) {
      return {
        name,
        minWidth,
        minHeight,
        colspan,
        rowspan,
        alignmentH,
        format, //используется при выгрузке детализации
        rowAlignmentH, //используется при выгрузке детализации
        colnum: 0, //номер столбца в шапке таблицы, используется далее, тут оставлять 0
        rownum: 0, //номер троки в шапке таблицы, используется далее при транспонировании таблицы, тут оставлять 0
        cellStep: 0, //объединение ячеек по вертикали для строки, не являющейся шапкой, используется при расчетах, тут оставлять 0
        fontsize,
      };
      /*
             wsws.Cell(row, 20).Style.Alignment.Horizontal = 7; //выравнивание по правому краю
             wsws.Cell(row, 20).Style.Alignment.Horizontal = 2; //выравнивание по ширине
             wsws.Cell(row, 20).Style.Alignment.Horizontal = 3; //выравнивание по левому краю
             wsws.Cell(row, 20).Style.Alignment.Horizontal = 0; //выравнивание по центру
             */
    }

    //строка
    function addRowDict(header, format, val, rowspan, colspan) {
      return {
        header: header ? header : false,
        format: format ? format : 0,
        val: val ? val : 0,
        rowspan: rowspan ? rowspan : 0,
        colspan: colspan ? colspan : 0,
      };
    }

    function addHeaderRowDefaultDict(
      rows,
      rowArrname,
      addHeaderDictArr,
      addRowDictArr,
    ) {
      rows.header[rowArrname] = addHeaderDict(
        addHeaderDictArr.Hname,
        addHeaderDictArr.HminWidth,
        addHeaderDictArr.Hcolspan,
        addHeaderDictArr.Hrowspan,
        addHeaderDictArr.HminHeight,
        addHeaderDictArr.HalignmentH,
        addHeaderDictArr.Rformat,
        addHeaderDictArr.RalignmentH,
      );
      if (addRowDictArr) {
        rows[rowArrname] = {};
        rows[rowArrname].val = addRowDict(
          rowArrname,
          addRowDictArr.Rformat,
          addRowDictArr.Rval,
          addRowDictArr.Rrowspan,
          addRowDictArr.Rrcolspan,
        );
      }
    }

    //объявление ШАПОК таблиц
    //первая таблица
    let rows = {};
    rows.header = {};
    rows.header.motoEngine = addHeaderDict(
      'Общее время работы ДВС',
      21,
      2,
      0,
      33,
    );
    rows.header.motoEngineNoSpd = addHeaderDict(
      'Работа ДВС при скорости=0',
      21,
      2,
      0,
    );
    rows.header.motoEngineSpd = addHeaderDict(
      'Работа ДВС при скорости>0',
      21,
      2,
      0,
    );
    rows.header.distance = addHeaderDict('Общий пробег, км.', 14, 0, 2);
    rows.header.avgSpd = addHeaderDict(
      'Средняя скорость движения, км/час',
      14,
      0,
      2,
      0,
      -1,
      0,
      -1,
      11,
    );
    rows.header.canExpence = addHeaderDict(
      'Расход топлива по CAN, л',
      14,
      0,
      2,
    );
    rows.header.canExpenceSpd = addHeaderDict(
      'Расход топлива по CAN в движении, л',
      14,
      0,
      2,
    );
    rows.header.canExpenceNoSpd = addHeaderDict(
      'Расход топлива по CAN на стоянке, л',
      14,
      0,
      2,
    );

    // расход топлива по CAN удельные значения 190720
    rows.header.canExpence100km = addHeaderDict(
      'Общий расход л/100 км, CAN',
      14,
      3,
      0,
    );
    rows.header.canExpence100kmMove = addHeaderDict(
      'Расход л/100 км. в движ., CAN',
      14,
      3,
      0,
    );
    rows.header.canExpenceLHourMove = addHeaderDict(
      'Расход л/час в движении, CAN',
      14,
      4,
      0,
    );
    rows.header.canExpenceLHourIdle = addHeaderDict(
      'Расход л/час при стоянке, CAN',
      14,
      4,
      0,
    );
    rows.header.serviceDistance = addHeaderDict('Пробег до ТО, км', 14, 2, 0);

    //вторая таблица ШАПКА
    rows.header.swithGearBoxCnt = addHeaderDict(
      'Переключение КПП, количество',
      14,
      7,
      0,
      0,
      0,
      12,
    );
    rows.header.swithGearBoxCntPerDist = addHeaderDict(
      'Число переключений КПП на 1 км. пути, количество',
      14,
      7,
      0,
      0,
      0,
      5,
    );
    rows.header.swithClutchCnt = addHeaderDict(
      'Работа сцепления (размыкание), количество',
      14,
      7,
      0,
      0,
      0,
      12,
    );
    rows.header.swithClutchBroken = addHeaderDict(
      'Сцепление разомкнуто, время',
      14,
      7,
      0,
      0,
      0,
      4,
    );
    rows.header.clutchWorkSumm = addHeaderDict(
      'Суммарная работа сцепления, кДж',
      14,
      7,
      0,
    ); //190720

    //третья таблица ШАПКА Использование КПП по пробегу + четвертая Использование КПП по началу движения

    rows.header['GearBoxNum_name'] = addHeaderDict(
      '№ передачи',
      0,
      2,
      0,
      0,
      0,
      12,
    );
    rows.header.GearBoxNumNameArr = []; //для выгрузки таблицы на лист будет использован
    rows.header.GearBoxNumNameArr.push('GearBoxNum_name'); //для выгрузки таблицы на лист будет использован
    for (var ii = -2; ii <= gearBoxCount; ii++) {
      //if(ii == -0.5 || ii == 0.5) continue;
      var z = codeGearBoxNumNameFunction(ii);
      //rows.header['GearBoxNum_' + z] = addHeaderDict(String("\'" + ii));
      rows.header['GearBoxNum_' + z] = addHeaderDict(
        String(' ' + ii),
        0,
        0,
        0,
        0,
        0,
        12,
      ); //с применением SetValueRaw
      rows.header.GearBoxNumNameArr.push('GearBoxNum_' + z); //для выгрузки таблицы на лист будет использован
    }

    //пятая таблица ШАПКА
    /*function FindTransmissionLock(obj){
            var arr_ = [];
            var z=0;
            for(var i = 0; i < 10; i ++){
                if (String(obj.DeviceParam(0x45, i, 0x1003)).toUpperCase() == "X"){//(уровнемеры, номер с 0, преобразование)
                    var _arr = {};
                    _arr.lvNum = i;
                    _arr.nameLock = String(obj.DeviceParam(0x45, i, 0x1001));
                    _arr.nameArr = String('transmissionLock_' + z);
                    _arr.value = -1;//для рассчетов по позициям
                    _arr.valuePrev = -1;//для рассчетов по позициям
                    z++;
                    arr_.push(_arr);
                    _arr=[];
                }
            }
            return arr_;
        }*/

    rows.header.transmissionLockArr = transmissionLockArr; // вытаскиваем настройку блокировок трансмиссии объекта, в поле Преобразование должно быть X
    rows.header.transmissionLockNameArr = [];
    for (let i = 0; i < rows.header.transmissionLockArr.length; i++) {
      if (i == 0) {
        rows.header['transmissionLock_name'] = addHeaderDict(
          'Название режима трансмиссии',
          0,
          2,
          0,
          49,
        ); //если есть хотябы один - добавится и таблица
        rows.header.transmissionLockNameArr.push('transmissionLock_name'); //для разворачивания вытянутых имен из конфигурации уже при выгрузке таблицы
      }
      rows.header[rows.header.transmissionLockArr[i].nameArr] = addHeaderDict(
        String(rows.header.transmissionLockArr[i].nameLock),
        0,
        2,
      ); //заполнение шапки с названием блокировок
      rows.header.transmissionLockNameArr.push(
        rows.header.transmissionLockArr[i].nameArr,
      ); //для разворачивания вытянутых имен из конфигурации уже при выгрузке таблицы
    }

    //шестая таблица ШАПКА //190720
    rows.header.breakCount = addHeaderDict(
      'Торможений при движении всего (моторный и ножной тормоз), кол-во',
      14,
      8,
    );
    rows.header.breakTime = addHeaderDict(
      'Торможений при движении всего (моторный и ножной тормоз), время',
      14,
      8,
    );
    rows.header.pedalBreakCount = addHeaderDict(
      'Ножной тормоз, кол-во сработок при движении',
      14,
      8,
    );
    rows.header.pedalBreakTime = addHeaderDict(
      'Ножной тормоз, сработка при движении, время',
      14,
      8,
    );

    rows.header.engineBreakCnt = addHeaderDict(
      'Моторный тормоз, кол-во сработок при движении',
      14,
      8,
    );
    rows.header.engineBreakTime = addHeaderDict(
      'Моторный тормоз, время работы при движении',
      14,
      8,
    );

    rows.header.engineBreakCntPercent = addHeaderDict(
      'Доля моторного тормоза по кол-ву торможений при движении',
      14,
      8,
    );
    rows.header.engineBreakTimePercent = addHeaderDict(
      'Доля моторного тормоза по времени торможения при движении',
      14,
      8,
    );
    rows.header.absCount = addHeaderDict(
      'Сработка ABS при торможении, кол-во',
      14,
      8,
    );

    //седьмая таблица ШАПКА И СТРОКИ
    addHeaderRowDefaultDict(
      rows,
      'ptoCnt' /*название в массиве*/,
      {
        Hname: 'Работа КОМ, кол-во включений' /*название в шапке*/,
        HminWidth: 14,
        Hcolspan: 4,
        Hrowspan: 0,
        HminHeight: 33,
        HalignmentH: -1,
      }, //заголовок (шапка)
      { Rformat: 12, Rval: 0, Rrowspan: 0, Rrcolspan: 2 },
    ); //строки
    addHeaderRowDefaultDict(
      rows,
      'ptoTime' /*название в массиве*/,
      {
        Hname: 'Работа КОМ, время работы' /*название в шапке*/,
        HminWidth: 14,
        Hcolspan: 3,
        Hrowspan: 0,
        HminHeight: 0,
        HalignmentH: -1,
      }, //заголовок (шапка)
      { Rformat: 4, Rval: 0, Rrowspan: 0, Rrcolspan: 2 },
    ); //строки
    addHeaderRowDefaultDict(
      rows,
      'ptoEngineRpm800_1000Time' /*название в массиве*/,
      {
        Hname:
          'ДВС 800-1000 об/мин при КОМ (предпочтительные обороты), время' /*название в шапке*/,
        HminWidth: 14,
        Hcolspan: 5,
        Hrowspan: 0,
        HminHeight: 0,
        HalignmentH: -1,
      }, //заголовок (шапка)
      { Rformat: 4, Rval: 0, Rrowspan: 0, Rrcolspan: 4 },
    ); //строки
    addHeaderRowDefaultDict(
      rows,
      'ptoEngineRpm800_1000Percent' /*название в массиве*/,
      {
        Hname: 'Доля работы КОМ при ДВС 800-1000 об/мин.' /*название в шапке*/,
        HminWidth: 14,
        Hcolspan: 4,
        Hrowspan: 0,
        HminHeight: 0,
        HalignmentH: -1,
      }, //заголовок (шапка)
      { Rformat: 6, Rval: 0, Rrowspan: 0, Rrcolspan: 4 },
    ); //строки
    addHeaderRowDefaultDict(
      rows,
      'rpmMore1200PtoPercent' /*название в массиве*/,
      {
        Hname:
          'Обороты ДВС более 1200 при работе КОМ, доля от общего времени работы КОМ, %' /*название в шапке*/,
        HminWidth: 14,
        Hcolspan: 4,
        Hrowspan: 0,
        HminHeight: 0,
        HalignmentH: -1,
      }, //заголовок (шапка)
      { Rformat: 6, Rval: 0, Rrowspan: 0, Rrcolspan: 4 },
    ); //строки

    //восьмая таблица ШАПКА И СТРОКИ
    addHeaderRowDefaultDict(
      rows,
      'rpm1900_2200Time' /*название в массиве*/,
      {
        Hname:
          'Обороты ДВС в диапазоне 1900 - 2400 об/мин, время' /*название в шапке*/,
        HminWidth: 14,
        Hcolspan: 15,
        Hrowspan: 0,
        HminHeight: 0,
        HalignmentH: -1,
      }, //заголовок (шапка)
      { Rformat: 4, Rval: 0, Rrowspan: 0, Rrcolspan: 2 },
    ); //строки
    addHeaderRowDefaultDict(
      rows,
      'rpm1900_2200Percent' /*название в массиве*/,
      {
        Hname:
          'Обороты ДВС в диапазоне 1900 - 2400 об/мин, %' /*название в шапке*/,
        HminWidth: 14,
        Hcolspan: 15,
        Hrowspan: 0,
        HminHeight: 0,
        HalignmentH: -1,
      }, //заголовок (шапка)
      { Rformat: 6, Rval: 0, Rrowspan: 0, Rrcolspan: 2 },
    ); //строки
    /*addHeaderRowDefaultDict(rows, 'swithClutchNoSpdCnt',
            {Hname: "Простой с разомкнутым сцеплением более 1 мин., количество", HminWidth: 14, Hcolspan: 15, Hrowspan: 0, HminHeight: 0, HalignmentH: -1},//заголовок (шапка)
            {Rformat: 0, Rval: 0, Rrowspan: 0, Rrcolspan: 2}); //строки*/
    addHeaderRowDefaultDict(
      rows,
      'swithClutchNoSpdCountTime',
      {
        Hname: 'Простой с разомкнутым сцеплением более 1 мин., общее время',
        HminWidth: 14,
        Hcolspan: 15,
        Hrowspan: 0,
        HminHeight: 0,
        HalignmentH: -1,
      }, //заголовок (шапка)
      { Rformat: 4, Rval: 0, Rrowspan: 0, Rrcolspan: 2 },
    ); //строки

    //190720
    addHeaderRowDefaultDict(
      rows,
      'clutchSlipExcessiveRpmCnt',
      {
        Hname: 'Излишняя пробуксовка сцепления, оборотов',
        HminWidth: 14,
        Hcolspan: 15,
        Hrowspan: 0,
        HminHeight: 0,
        HalignmentH: -1,
      }, //заголовок (шапка)
      { Rformat: 5, Rval: 0, Rrowspan: 0, Rrcolspan: 2 },
    ); //строки
    addHeaderRowDefaultDict(
      rows,
      'clutchSlipVehicleSlipTime',
      {
        Hname:
          'Буксование, пробуксовка при нагреве дисков более 100 градусов, время',
        HminWidth: 14,
        Hcolspan: 15,
        Hrowspan: 0,
        HminHeight: 0,
        HalignmentH: -1,
      }, //заголовок (шапка)
      { Rformat: 4, Rval: 0, Rrowspan: 0, Rrcolspan: 2 },
    ); //строки
    addHeaderRowDefaultDict(
      rows,
      'clutchSlipVehicleSlipRpmCnt',
      {
        Hname:
          'Буксование, пробуксовка при нагреве дисков более 100 градусов, оборотов',
        HminWidth: 14,
        Hcolspan: 15,
        Hrowspan: 0,
        HminHeight: 0,
        HalignmentH: -1,
      }, //заголовок (шапка)
      { Rformat: 5, Rval: 0, Rrowspan: 0, Rrcolspan: 2 },
    ); //строки
    addHeaderRowDefaultDict(
      rows,
      'clutchSlipVehicleSlipWork',
      {
        Hname:
          'Буксование, работа при буксовании - нагреве дисков более 100 градусов, кДж',
        HminWidth: 14,
        Hcolspan: 15,
        Hrowspan: 0,
        HminHeight: 0,
        HalignmentH: -1,
      }, //заголовок (шапка)
      { Rformat: 5, Rval: 0, Rrowspan: 0, Rrcolspan: 2 },
    ); //строки
    addHeaderRowDefaultDict(
      rows,
      'clutchSlipVehicleSlipLowRpmCnt',
      {
        Hname:
          'Буксование на понижающей, при нагреве дисков более 100 градусов, оборотов',
        HminWidth: 14,
        Hcolspan: 15,
        Hrowspan: 0,
        HminHeight: 0,
        HalignmentH: -1,
      }, //заголовок (шапка)
      { Rformat: 5, Rval: 0, Rrowspan: 0, Rrcolspan: 2 },
    ); //строки
    addHeaderRowDefaultDict(
      rows,
      'clutchSlipVehicleSlipLowWork',
      {
        Hname:
          'Буксование на понижающей, при нагреве дисков более 100 градусов, работа сцепления, кДж',
        HminWidth: 14,
        Hcolspan: 15,
        Hrowspan: 0,
        HminHeight: 0,
        HalignmentH: -1,
      }, //заголовок (шапка)
      { Rformat: 5, Rval: 0, Rrowspan: 0, Rrcolspan: 2 },
    ); //строки
    addHeaderRowDefaultDict(
      rows,
      'clutchSlipVehicleSlipWorkPercent',
      {
        Hname:
          'Доля работы сцепления при буксовании, при нагреве дисков более 100 градусов, %',
        HminWidth: 14,
        Hcolspan: 15,
        Hrowspan: 0,
        HminHeight: 0,
        HalignmentH: -1,
      }, //заголовок (шапка)
      { Rformat: 6, Rval: 0, Rrowspan: 0, Rrcolspan: 2 },
    ); //строки

    // addHeaderRowDefaultDict(rows, 'rpmNo800_1000less1200Cnt',
    //     {Hname: "Обороты ДВС вне диапазона 800 - 1000 об/мин., но менее 1200 при включенном КОМ, кол-во", HminWidth: 14, Hcolspan: 15, Hrowspan: 0, HminHeight: 0, HalignmentH: -1},//заголовок (шапка)
    //     {Rformat: 12, Rval: 0, Rrowspan: 0, Rrcolspan: 2}); //строки

    addHeaderRowDefaultDict(
      rows,
      'rpmNo800_1000less1200Time',
      {
        Hname:
          'Обороты ДВС вне диапазона 800 - 1000 об/мин., но менее 1200 при включенном КОМ, общее время',
        HminWidth: 14,
        Hcolspan: 15,
        Hrowspan: 0,
        HminHeight: 0,
        HalignmentH: -1,
      }, //заголовок (шапка)
      { Rformat: 4, Rval: 0, Rrowspan: 0, Rrcolspan: 2 },
    ); //строки

    /*
     *удалено по письму от 27 03 19
     *addHeaderRowDefaultDict(rows, 'rpmSpdLess440Cnt',
     *    {Hname: "При движении обороты ДВС опустились ниже 440 об/мин, кол-во", HminWidth: 14, Hcolspan: 15, Hrowspan: 0, HminHeight: 0, HalignmentH: -1},//заголовок (шапка)
     *    {Rformat: 0, Rval: 0, Rrowspan: 0, Rrcolspan: 2}); //строки
     **/
    /*addHeaderRowDefaultDict(rows, 'rpmStartSpdMore1200Cnt',
            {Hname: "Обороты ДВС более 1200 при начале движения, количество", HminWidth: 14, Hcolspan: 15, Hrowspan: 0, HminHeight: 0, HalignmentH: -1},//заголовок (шапка)
            {Rformat: 0, Rval: 0, Rrowspan: 0, Rrcolspan: 2}); //строки*/
    /*addHeaderRowDefaultDict(rows, 'accelSharplyCnt',
            {Hname: "Резкое, более " + accelLimit + "% в сек., нажатие акселерометра при начале движения, кол-во", HminWidth: 14, Hcolspan: 15, Hrowspan: 0, HminHeight: 0, HalignmentH: -1},//заголовок (шапка)
            {Rformat: 0, Rval: 0, Rrowspan: 0, Rrcolspan: 2}); //строки*/
    /**
     *удалено по письму от 27 03 19
     * addHeaderRowDefaultDict(rows, 'startSpdBadRoadNoLockCnt',
     *  {Hname: "Начало движения в тяжелых дорожных условиях, без включения понижающей, блокировок", HminWidth: 14, Hcolspan: 15, Hrowspan: 0, HminHeight: 0, HalignmentH: -1},//заголовок (шапка)
     *  {Rformat: 0, Rval: 0, Rrowspan: 0, Rrcolspan: 2}); //строки
     **/
    addHeaderRowDefaultDict(
      rows,
      'swithGearBoxWrongCnt',
      {
        Hname:
          'Для механической КПП: неправильные переключения КПП, после переключения КПП обороты ДВС менее 800 или более 1900, кол-во',
        HminWidth: 14,
        Hcolspan: 15,
        Hrowspan: 0,
        HminHeight: 0,
        HalignmentH: -1,
      }, //заголовок (шапка)
      { Rformat: 12, Rval: 0, Rrowspan: 0, Rrcolspan: 2 },
    ); //строки
    addHeaderRowDefaultDict(
      rows,
      'swithGearBoxWrongPercent',
      {
        Hname: 'Доля неправильных переключений КПП,%',
        HminWidth: 14,
        Hcolspan: 15,
        Hrowspan: 0,
        HminHeight: 0,
        HalignmentH: -1,
      }, //заголовок (шапка)
      { Rformat: 6, Rval: 0, Rrowspan: 0, Rrcolspan: 2 },
    ); //строки
    /**
     *Удалено по письму от 27 03 19
     * addHeaderRowDefaultDict(rows, 'accelSharplyViolationCnt',
     *{Hname: "Резкие нажатия педали газа", HminWidth: 14, Hcolspan: 15, Hrowspan: 0, HminHeight: 0, HalignmentH: -1},//заголовок (шапка)
     *{Rformat: 0, Rval: 0, Rrowspan: 0, Rrcolspan: 2}); //строки
     */

    addHeaderRowDefaultDict(
      rows,
      'swithClutchInsufficientCnt',
      {
        Hname: 'Для механической КПП: неполное включение сцепления, кол-во',
        HminWidth: 14,
        Hcolspan: 15,
        Hrowspan: 0,
        HminHeight: 0,
        HalignmentH: -1,
      }, //заголовок (шапка)
      { Rformat: 12, Rval: 0, Rrowspan: 0, Rrcolspan: 2 },
    ); //строки

    //190720
    addHeaderRowDefaultDict(
      rows,
      'kickdownCnt',
      {
        Hname: 'Сработка режима kickdown, кол-во',
        HminWidth: 14,
        Hcolspan: 15,
        Hrowspan: 0,
        HminHeight: 0,
        HalignmentH: -1,
      }, //заголовок (шапка)
      { Rformat: 12, Rval: 0, Rrowspan: 0, Rrcolspan: 2 },
    ); //строки

    //9-th таблица ШАПКА И СТРОКИ
    addHeaderRowDefaultDict(
      rows,
      'rpmMore2200Time',
      {
        Hname: 'Обороты ДВС более 2400, время',
        HminWidth: 14,
        Hcolspan: 15,
        Hrowspan: 0,
        HminHeight: 0,
        HalignmentH: -1,
      }, //заголовок (шапка)
      { Rformat: 4, Rval: 0, Rrowspan: 0, Rrcolspan: 2 },
    ); //строки
    addHeaderRowDefaultDict(
      rows,
      'rpmMore1200PtoTime',
      {
        Hname: 'Обороты ДВС более 1200 при включенном КОМ, время',
        HminWidth: 14,
        Hcolspan: 15,
        Hrowspan: 0,
        HminHeight: 0,
        HalignmentH: -1,
      }, //заголовок (шапка)
      { Rformat: 4, Rval: 0, Rrowspan: 0, Rrcolspan: 2 },
    ); //строки
    /**
     * удалено по письму от 27 03 19
     * addHeaderRowDefaultDict(rows, 'engineStartColdCnt', {Hname: "Включение ДВС при температуре охл. жидкости менее минус 20 градусов по цельсию", HminWidth: 14, Hcolspan: 15, Hrowspan: 0, HminHeight: 0, HalignmentH: -1},//заголовок (шапка)
     *   {Rformat: 0, Rval: 0, Rrowspan: 0, Rrcolspan: 2}); //строки
     **/
    /**
     * удалено по письму от 27 03 19
     * addHeaderRowDefaultDict(rows, 'engineStalledspdCnt', {Hname: "Заглох при движении, количество", HminWidth: 14, Hcolspan: 15, Hrowspan: 0, HminHeight: 0, HalignmentH: -1},//заголовок (шапка)
     *   {Rformat: 0, Rval: 0, Rrowspan: 0, Rrcolspan: 2}); //строки
     */

    // addHeaderRowDefaultDict(rows, 'clutchSlipCnt', {Hname: "Критическая пробуксовка сцепления, количество", HminWidth: 14, Hcolspan: 15, Hrowspan: 0, HminHeight: 0, HalignmentH: -1},//заголовок (шапка)
    //     {Rformat: 12, Rval: 0, Rrowspan: 0, Rrcolspan: 2}); //строки
    // addHeaderRowDefaultDict(rows, 'clutchSlipTime', {Hname: "Критическая пробуксовка сцепления, общее время", HminWidth: 14, Hcolspan: 15, Hrowspan: 0, HminHeight: 0, HalignmentH: -1},//заголовок (шапка)
    //     {Rformat: 4, Rval: 0, Rrowspan: 0, Rrcolspan: 2}); //строки

    //190720 clutchSlipCnt - clutchSlipBadRpmCnt
    addHeaderRowDefaultDict(
      rows,
      'clutchSlipBadRpmCnt',
      {
        Hname:
          'Пробуксовка сцепления при крутящем моменте более 85% от максимального, оборотов',
        HminWidth: 14,
        Hcolspan: 15,
        Hrowspan: 0,
        HminHeight: 0,
        HalignmentH: -1,
      }, //заголовок (шапка)
      { Rformat: 5, Rval: 0, Rrowspan: 0, Rrcolspan: 2 },
    ); //строки
    addHeaderRowDefaultDict(
      rows,
      'clutchSlipCriticalCnt',
      {
        Hname:
          'Критическая пробуксовка сцепления (перегрев более 200 градусов), оборотов',
        HminWidth: 14,
        Hcolspan: 15,
        Hrowspan: 0,
        HminHeight: 0,
        HalignmentH: -1,
      }, //заголовок (шапка)
      { Rformat: 5, Rval: 0, Rrowspan: 0, Rrcolspan: 2 },
    ); //строки
    addHeaderRowDefaultDict(
      rows,
      'clutchSlipCriticalTime',
      {
        Hname:
          'Критическая пробуксовка сцепления (перегрев более 200 градусов), время',
        HminWidth: 14,
        Hcolspan: 15,
        Hrowspan: 0,
        HminHeight: 0,
        HalignmentH: -1,
      }, //заголовок (шапка)
      { Rformat: 4, Rval: 0, Rrowspan: 0, Rrcolspan: 2 },
    ); //строки

    addHeaderRowDefaultDict(
      rows,
      'startSpdGearBoxMore2Cnt',
      {
        Hname: 'Начало движения на неправильной передаче, количество',
        HminWidth: 14,
        Hcolspan: 15,
        Hrowspan: 0,
        HminHeight: 0,
        HalignmentH: -1,
      }, //заголовок (шапка)
      { Rformat: 12, Rval: 0, Rrowspan: 0, Rrcolspan: 2 },
    ); //строки

    /*addHeaderRowDefaultDict(rows, 'swithGearBoxRewersSpdCnt', {Hname: "Переключение КПП с переднего на задний ход при скорости >0, количество", HminWidth: 14, Hcolspan: 15, Hrowspan: 0, HminHeight: 0, HalignmentH: -1},//заголовок (шапка)
            {Rformat: 0, Rval: 0, Rrowspan: 0, Rrcolspan: 2}); //строки*/
    /**
     * удалено по письму от 27 03 19
     * addHeaderRowDefaultDict(rows, 'swithLockSpdCnt', {Hname: "Включение/выключение блокировок трансмиссии  на скорости более 7 - ми км/час, количество", HminWidth: 14, Hcolspan: 15, Hrowspan: 0, HminHeight: 0, HalignmentH: -1},//заголовок (шапка)
     *   {Rformat: 0, Rval: 0, Rrowspan: 0, Rrcolspan: 2}); //строки
     * addHeaderRowDefaultDict(rows, 'swithLockAccelCnt', {Hname: "Включение/выключение блокировок трансмиссии с нажатой педалью газа, количество", HminWidth: 14, Hcolspan: 15, Hrowspan: 0, HminHeight: 0, HalignmentH: -1},//заголовок (шапка)
     *   {Rformat: 0, Rval: 0, Rrowspan: 0, Rrcolspan: 2}); //строки
     **/

    //190720
    addHeaderRowDefaultDict(
      rows,
      'loweringSpdMore25Distance',
      {
        Hname: 'Пробег на понижающей передаче на скорости > 40 км/час, км.',
        HminWidth: 14,
        Hcolspan: 15,
        Hrowspan: 0,
        HminHeight: 0,
        HalignmentH: -1,
      }, //заголовок (шапка)
      { Rformat: 7, Rval: 0, Rrowspan: 0, Rrcolspan: 2 },
    ); //строки
    addHeaderRowDefaultDict(
      rows,
      'loweringSpdMore25Time',
      {
        Hname: 'Работа понижающей передачи на скорости > 40 км/час., время',
        HminWidth: 14,
        Hcolspan: 15,
        Hrowspan: 0,
        HminHeight: 0,
        HalignmentH: -1,
      }, //заголовок (шапка)
      { Rformat: 4, Rval: 0, Rrowspan: 0, Rrcolspan: 2 },
    ); //строки
    // считается более 10, а выводится как "более 7"
    addHeaderRowDefaultDict(
      rows,
      'crosswheelSpdMore7Distance',
      {
        Hname: 'Пробег с включенной межколесной блокировкой > 7 км/час, км.',
        HminWidth: 14,
        Hcolspan: 15,
        Hrowspan: 0,
        HminHeight: 0,
        HalignmentH: -1,
      }, //заголовок (шапка)
      { Rformat: 7, Rval: 0, Rrowspan: 0, Rrcolspan: 2 },
    ); //строки
    addHeaderRowDefaultDict(
      rows,
      'crosswheelSpdMore7Time',
      {
        Hname:
          'Движение с включенной межколесной блокировкой > 7 км/час, время',
        HminWidth: 14,
        Hcolspan: 15,
        Hrowspan: 0,
        HminHeight: 0,
        HalignmentH: -1,
      }, //заголовок (шапка)
      { Rformat: 4, Rval: 0, Rrowspan: 0, Rrcolspan: 2 },
    ); //строки
    addHeaderRowDefaultDict(
      rows,
      'ptoSpdDistance',
      {
        Hname: 'Пробег с включенным КОМ, км.',
        HminWidth: 14,
        Hcolspan: 15,
        Hrowspan: 0,
        HminHeight: 0,
        HalignmentH: -1,
      }, //заголовок (шапка)
      { Rformat: 7, Rval: 0, Rrowspan: 0, Rrcolspan: 2 },
    ); //строки
    addHeaderRowDefaultDict(
      rows,
      'ptoSpdTime',
      {
        Hname: 'Движение с включенным КОМ, время',
        HminWidth: 14,
        Hcolspan: 15,
        Hrowspan: 0,
        HminHeight: 0,
        HalignmentH: -1,
      }, //заголовок (шапка)
      { Rformat: 4, Rval: 0, Rrowspan: 0, Rrcolspan: 2 },
    ); //строки
    addHeaderRowDefaultDict(
      rows,
      'handleBreakMoveCount',
      {
        Hname: 'Движение с включенным ручным тормозом, кол-во',
        HminWidth: 14,
        Hcolspan: 15,
        Hrowspan: 0,
        HminHeight: 0,
        HalignmentH: -1,
      }, //заголовок (шапка)
      { Rformat: 12, Rval: 0, Rrowspan: 0, Rrcolspan: 2 },
    ); //строки
    addHeaderRowDefaultDict(
      rows,
      'handleBreakMoveTime',
      {
        Hname: 'Движение с включенным ручным тормозом, общее время',
        HminWidth: 14,
        Hcolspan: 15,
        Hrowspan: 0,
        HminHeight: 0,
        HalignmentH: -1,
      }, //заголовок (шапка)
      { Rformat: 4, Rval: 0, Rrowspan: 0, Rrcolspan: 2 },
    ); //строки
    addHeaderRowDefaultDict(
      rows,
      'lowOilLampCount',
      {
        Hname: 'Сработка контрольной лампы аварийного давления масла, кол-во',
        HminWidth: 14,
        Hcolspan: 15,
        Hrowspan: 0,
        HminHeight: 0,
        HalignmentH: -1,
      }, //заголовок (шапка)
      { Rformat: 12, Rval: 0, Rrowspan: 0, Rrcolspan: 2 },
    ); //строки
    addHeaderRowDefaultDict(
      rows,
      'lowOilLampTime',
      {
        Hname:
          'Сработка контрольной лампы аварийного давления масла, общее время',
        HminWidth: 14,
        Hcolspan: 15,
        Hrowspan: 0,
        HminHeight: 0,
        HalignmentH: -1,
      }, //заголовок (шапка)
      { Rformat: 4, Rval: 0, Rrowspan: 0, Rrcolspan: 2 },
    ); //строки
    addHeaderRowDefaultDict(
      rows,
      'hightCoolTempLampCount',
      {
        Hname: 'Сработка контрольной лампы температуры ДВС, кол-во',
        HminWidth: 14,
        Hcolspan: 15,
        Hrowspan: 0,
        HminHeight: 0,
        HalignmentH: -1,
      }, //заголовок (шапка)
      { Rformat: 12, Rval: 0, Rrowspan: 0, Rrcolspan: 2 },
    ); //строки
    addHeaderRowDefaultDict(
      rows,
      'hightCoolTempLampTime',
      {
        Hname: 'Сработка контрольной лампы температуры ДВС, общее время',
        HminWidth: 14,
        Hcolspan: 15,
        Hrowspan: 0,
        HminHeight: 0,
        HalignmentH: -1,
      }, //заголовок (шапка)
      { Rformat: 4, Rval: 0, Rrowspan: 0, Rrcolspan: 2 },
    ); //строки

    addHeaderRowDefaultDict(
      rows,
      'cnt_real_pos',
      {
        Hname: 'Получено позиций, кол-во',
        HminWidth: 14,
        Hcolspan: 15,
        Hrowspan: 0,
        HminHeight: 0,
        HalignmentH: -1,
      }, //заголовок (шапка)
      { Rformat: 0, Rval: 0, Rrowspan: 0, Rrcolspan: 2 },
    ); //строки
    addHeaderRowDefaultDict(
      rows,
      'cnt_pos_missed',
      {
        Hname: 'Потеряно позиций, кол-во',
        HminWidth: 14,
        Hcolspan: 15,
        Hrowspan: 0,
        HminHeight: 0,
        HalignmentH: -1,
      }, //заголовок (шапка)
      { Rformat: 0, Rval: 0, Rrowspan: 0, Rrcolspan: 2 },
    ); //строки
    addHeaderRowDefaultDict(
      rows,
      'receivedPercent',
      {
        Hname: 'Получение данных, %',
        HminWidth: 14,
        Hcolspan: 15,
        Hrowspan: 0,
        HminHeight: 0,
        HalignmentH: -1,
      }, //заголовок (шапка)
      { Rformat: 6, Rval: 0, Rrowspan: 0, Rrcolspan: 2 },
    ); //строки
    addHeaderRowDefaultDict(
      rows,
      'kickdownCnt100km',
      {
        Hname: 'Сработка режима kickdown, на 100км.',
        HminWidth: 14,
        Hcolspan: 15,
        Hrowspan: 0,
        HminHeight: 0,
        HalignmentH: -1,
      }, //заголовок (шапка)
      { Rformat: 14, Rval: 0, Rrowspan: 0, Rrcolspan: 2 },
    ); //строки
    addHeaderRowDefaultDict(
      rows,
      'absCount100km',
      {
        Hname: 'Сработка ABS при торможении, на 100км.',
        HminWidth: 14,
        Hcolspan: 15,
        Hrowspan: 0,
        HminHeight: 0,
        HalignmentH: -1,
      }, //заголовок (шапка)
      { Rformat: 14, Rval: 0, Rrowspan: 0, Rrcolspan: 2 },
    ); //строки
    addHeaderRowDefaultDict(
      rows,
      'ptoCnt100km',
      {
        Hname: 'Работа КОМ, кол-во включений на 100км.',
        HminWidth: 14,
        Hcolspan: 15,
        Hrowspan: 0,
        HminHeight: 0,
        HalignmentH: -1,
      }, //заголовок (шапка)
      { Rformat: 14, Rval: 0, Rrowspan: 0, Rrcolspan: 2 },
    ); //строки

    /**
     * Удалено по письму от 27 03 19
     * addHeaderRowDefaultDict(rows, 'ptoActivateKrenCnt', {Hname: "Для самосвалов: число включений КОМ при угле крена более 5 градусов, количество", HminWidth: 14, Hcolspan: 15, Hrowspan: 0, HminHeight: 0, HalignmentH: -1},//заголовок (шапка)
     *   {Rformat: 0, Rval: 0, Rrowspan: 0, Rrcolspan: 2}); //строки
     **/

    /*ws.Cell(row, 20).Style.Alignment.Horizontal = 7; //выравнивание по правому краю
         ws.Cell(row, 20).Style.Alignment.Horizontal = 2; //выравнивание по ширине
         ws.Cell(row, 20).Style.Alignment.Horizontal = 3; //выравнивание по левому краю
         ws.Cell(row, 20).Style.Alignment.Horizontal = 0; //выравнивание по центру*/

    //объявление таблицы ДЕТАЛИЗАЦИЯ, строки для нее будут созданы при обходе позиций

    //addHeaderRowDefaultDict(rows, 'DKren', {Hname: "Угол крена", HminWidth: 7, Hcolspan: 0, Hrowspan: 0, HminHeight: 0, HalignmentH: -1, Rformat: 5, RalignmentH: -1});//заголовок (шапка)
    //addHeaderRowDefaultDict(rows, 'DTangage', {Hname: "Угол тангажа", HminWidth: 7, Hcolspan: 0, Hrowspan: 0, HminHeight: 0, HalignmentH: -1, Rformat: 5, RalignmentH: -1});//заголовок (шапка)
    //addHeaderRowDefaultDict(rows, 'DPTOWork', {Hname: "Работа КОМ", HminWidth: 10, Hcolspan: 0, Hrowspan: 0, HminHeight: 0, HalignmentH: -1, Rformat: 0, RalignmentH: 7});//заголовок (шапка)
    //addHeaderRowDefaultDict(rows, 'DCanDist', {Hname: "CAN пробег", HminWidth: 13, Hcolspan: 0, Hrowspan: 0, HminHeight: 0, HalignmentH: -1, Rformat: 9, RalignmentH: -1});//заголовок (шапка) 9 - три после запятой
    //addHeaderRowDefaultDict(rows, 'DTime', {Hname: "Время", HminWidth: 20, Hcolspan: 0, Hrowspan: 0, HminHeight: 0, HalignmentH: -1, Rformat: 1, RalignmentH: -1});//заголовок (шапка)
    //addHeaderRowDefaultDict(rows, 'DCanSpeed', {Hname: "CAN cкорость", HminWidth: 9, Hcolspan: 0, Hrowspan: 0, HminHeight: 0, HalignmentH: -1, Rformat: 0, RalignmentH: -1});//заголовок (шапка)
    //addHeaderRowDefaultDict(rows, 'DCanEngineTemperature', {Hname: "CAN темп. двигателя", HminWidth: 10, Hcolspan: 0, Hrowspan: 0, HminHeight: 0, HalignmentH: -1, Rformat: 0, RalignmentH: -1});//заголовок (шапка)
    //addHeaderRowDefaultDict(rows, 'DCanAccelerate', {Hname: "CAN положение педали газа", HminWidth: 13, Hcolspan: 0, Hrowspan: 0, HminHeight: 0, HalignmentH: -1, Rformat: 11, RalignmentH: -1});//заголовок (шапка)
    //addHeaderRowDefaultDict(rows, 'DCanRmp', {Hname: "CAN обороты двигателя", HminWidth: 10, Hcolspan: 0, Hrowspan: 0, HminHeight: 0, HalignmentH: -1, Rformat: 0, RalignmentH: -1});//заголовок (шапка)
    //addHeaderRowDefaultDict(rows, 'DCanLoad', {Hname: "CAN нагрузка на двигатель", HminWidth: 12, Hcolspan: 0, Hrowspan: 0, HminHeight: 0, HalignmentH: -1, Rformat: 11, RalignmentH: -1});//заголовок (шапка)
    //addHeaderRowDefaultDict(rows, 'DCanClutch', {Hname: "Сцепление, разомкнуто/сомкнуто", HminWidth: 13, Hcolspan: 0, Hrowspan: 0, HminHeight: 0, HalignmentH: -1, Rformat: 0, RalignmentH: -1});//заголовок (шапка)
    //addHeaderRowDefaultDict(rows, 'DCanGearbox', {Hname: "CAN АКПП, ПЕРЕДАЧА", HminWidth: 11, Hcolspan: 0, Hrowspan: 0, HminHeight: 0, HalignmentH: -1, Rformat: 0, RalignmentH: -1});//заголовок (шапка)
    //addHeaderRowDefaultDict(rows, 'DGearRatioSpdRpm', {Hname: "Передаточное, от ДВС к CAN скорости", HminWidth: 13.5, Hcolspan: 0, Hrowspan: 0, HminHeight: 0, HalignmentH: -1, Rformat: 5, RalignmentH: 7});//заголовок (шапка)
    //addHeaderRowDefaultDict(rows, 'DAcceleration', {Hname: "Ускорение, м/с2", HminWidth: 9, Hcolspan: 0, Hrowspan: 0, HminHeight: 0, HalignmentH: -1, Rformat: 5, RalignmentH: 7});//заголовок (шапка)
    //addHeaderRowDefaultDict(rows, 'DEngineBreakes', {Hname: "Моторный тормоз", HminWidth: 7, Hcolspan: 0, Hrowspan: 0, HminHeight: 0, HalignmentH: -1, Rformat: 5, RalignmentH: 7});//заголовок (шапка)
    //-for(var z=0; z < rows.header.transmissionLockArr.length; z++){
    //addHeaderRowDefaultDict(rows, 'D' + rows.header.transmissionLockArr[z].nameArr, {Hname: rows.header.transmissionLockArr[z].nameLock, HminWidth: 17, Hcolspan: 0, Hrowspan: 0, HminHeight: 0, HalignmentH: -1, RalignmentH: 7});//заголовок (шапка) ДЕТАЛИЗАЦИИ
    //}
    //addHeaderRowDefaultDict(rows, 'DComment', {Hname: 'Неэффектифная/неправильная эксплуатация, комментарий', HminWidth: 30, Hcolspan: 0, Hrowspan: 0, HminHeight: 0, HalignmentH: -1});//заголовок (шапка)

    //объявлние СТРОК таблиц
    //первая таблица
    /*format
         1 formatDate(new Date(val), 'dd.mm.yyyy hh:nn:ss');
         2 formatDate(new Date(val), 'dd.mm.yyyy');
         3 formatDate(new Date(val), 'hh:nn:ss');
         4 formatTime(val, 'tt:nn:ss');
         case 5:
         ws.Cell(rnum, cnum).Value = val;
         ws.Cell(rnum, cnum).Style.NumberFormat.Format = '0.0';
         case 6:
         ws.Cell(rnum, cnum).Value = val;
         ws.Cell(rnum, cnum).Style.NumberFormat.Format = '0.0%';
         */
    rows.motoEngine = {};
    rows.motoEngine.val = addRowDict('motoEngine', 4);
    rows.motoEngine.percent = addRowDict('motoEngine', 6);
    rows.motoEngineSpd = {};
    rows.motoEngineSpd.val = addRowDict('motoEngineSpd', 4);
    rows.motoEngineSpd.percent = addRowDict('motoEngineSpd', 6);
    rows.motoEngineNoSpd = {};
    rows.motoEngineNoSpd.val = addRowDict('motoEngineNoSpd', 4);
    rows.motoEngineNoSpd.percent = addRowDict('motoEngineNoSpd', 6);
    rows.distance = {};
    rows.distance.val = addRowDict('distance', 13, 0, 2);
    rows.avgSpd = {};
    rows.avgSpd.val = addRowDict('avgSpd', 13, 0, 2);
    rows.canExpence = {};
    rows.canExpence.val = addRowDict('canExpence', 13, 0, 2);
    rows.canExpenceSpd = {};
    rows.canExpenceSpd.val = addRowDict('canExpenceSpd', 13, 0, 2);
    rows.canExpenceNoSpd = {};
    rows.canExpenceNoSpd.val = addRowDict('canExpenceNoSpd', 13, 0, 2);

    // расход по CAN //190720
    rows.serviceDistance = {};
    rows.serviceDistance.val = addRowDict('serviceDistance', 12, 0, 0, 3);
    rows.canExpence100km = {};
    rows.canExpence100km.val = addRowDict('canExpence100km', 13);
    rows.canExpence100kmMove = {};
    rows.canExpence100kmMove.val = addRowDict('canExpence100kmMove', 13);
    rows.canExpence100kmMove = {};
    rows.canExpence100kmMove.val = addRowDict('canExpence100kmMove', 13);
    rows.canExpenceLHourMove = {};
    rows.canExpenceLHourMove.val = addRowDict('canExpenceLHourMove', 13);
    rows.canExpenceLHourIdle = {};
    rows.canExpenceLHourIdle.val = addRowDict('canExpenceLHourIdle', 13);

    //two table
    rows.swithGearBoxCnt = {};
    rows.swithGearBoxCnt.val = addRowDict('swithGearBoxCnt', 12, 0, 0, 3);
    rows.swithGearBoxCntPerDist = {};
    rows.swithGearBoxCntPerDist.val = addRowDict(
      'swithGearBoxCntPerDist',
      13,
      0,
      0,
      3,
    );
    rows.swithClutchCnt = {};
    rows.swithClutchCnt.val = addRowDict('swithClutchCnt', 12, 0, 0, 3);
    rows.swithClutchBroken = {};
    rows.swithClutchBroken.val = addRowDict('swithClutchBroken', 4, 0, 0, 3);
    //190720
    rows.clutchWorkSumm = {};
    rows.clutchWorkSumm.val = addRowDict('clutchWorkSumm', 13, 0, 0, 3);

    //Использование сцепления по началу движения //190720
    addHeaderRowDefaultDict(
      rows,
      'clutchUseMoveStartCnt',
      {
        Hname: 'Всего начало движения, кол-во',
        HminWidth: 11.5,
        Hcolspan: 3,
        Hrowspan: 0,
        HminHeight: 0,
        HalignmentH: -1,
        Rformat: 0,
        RalignmentH: -1,
      },
      { Rformat: 12, Rval: 0, Rrowspan: 0, Rrcolspan: 0 },
    ); //заголовок (шапка)
    addHeaderRowDefaultDict(
      rows,
      'clutchUseMoveStartSlipCnt',
      {
        Hname: 'Из них с пробукс., кол-во',
        HminWidth: 11.5,
        Hcolspan: 3,
        Hrowspan: 0,
        HminHeight: 0,
        HalignmentH: -1,
        Rformat: 0,
        RalignmentH: -1,
      },
      { Rformat: 12, Rval: 0, Rrowspan: 0, Rrcolspan: 0 },
    ); //заголовок (шапка)
    addHeaderRowDefaultDict(
      rows,
      'clutchUseMoveStartSlipCntPercent',
      {
        Hname: 'Доля с пробуксовкой',
        HminWidth: 11.5,
        Hcolspan: 3,
        Hrowspan: 0,
        HminHeight: 0,
        HalignmentH: -1,
        Rformat: 0,
        RalignmentH: -1,
      },
      { Rformat: 6, Rval: 0, Rrowspan: 0, Rrcolspan: 0 },
    ); //заголовок (шапка)
    addHeaderRowDefaultDict(
      rows,
      'clutchUseMoveStartSlipHiCnt',
      {
        Hname: 'Пробукс. на повышающ., обороты',
        HminWidth: 11.5,
        Hcolspan: 4,
        Hrowspan: 0,
        HminHeight: 0,
        HalignmentH: -1,
        Rformat: 0,
        RalignmentH: -1,
      },
      { Rformat: 13, Rval: 0, Rrowspan: 0, Rrcolspan: 0 },
    ); //заголовок (шапка)
    addHeaderRowDefaultDict(
      rows,
      'clutchUseMoveStartSlipLowCnt',
      {
        Hname: 'Пробукс. на понижающ., обороты',
        HminWidth: 11.5,
        Hcolspan: 4,
        Hrowspan: 0,
        HminHeight: 0,
        HalignmentH: -1,
        Rformat: 0,
        RalignmentH: -1,
      },
      { Rformat: 13, Rval: 0, Rrowspan: 0, Rrcolspan: 0 },
    ); //заголовок (шапка)

    // работа сцепления при начале движения //190720
    addHeaderRowDefaultDict(
      rows,
      'clutchUseMoveStartWorkSumm',
      {
        Hname: 'Работа сцепления выполняемая при начале движения, кДж',
        HminWidth: 11.5,
        Hcolspan: 9,
        Hrowspan: 0,
        HminHeight: 0,
        HalignmentH: -1,
        Rformat: 0,
        RalignmentH: -1,
      },
      { Rformat: 13, Rval: 0, Rrowspan: 0, Rrcolspan: 2 },
    ); //заголовок (шапка)
    addHeaderRowDefaultDict(
      rows,
      'clutchUseMoveStartExcessiveWorkSumm',
      {
        Hname:
          'Работа сцепления при начале движения в излишних пробуксовках, кДж',
        HminWidth: 11.5,
        Hcolspan: 9,
        Hrowspan: 0,
        HminHeight: 0,
        HalignmentH: -1,
        Rformat: 0,
        RalignmentH: -1,
      },
      { Rformat: 13, Rval: 0, Rrowspan: 0, Rrcolspan: 2 },
    ); //заголовок (шапка)
    addHeaderRowDefaultDict(
      rows,
      'clutchUseMoveStartExcessiveWorkPercent',
      {
        Hname:
          'Доля работы сцепления при начале движения в излишних пробуксовках, %',
        HminWidth: 11.5,
        Hcolspan: 9,
        Hrowspan: 0,
        HminHeight: 0,
        HalignmentH: -1,
        Rformat: 0,
        RalignmentH: -1,
      },
      { Rformat: 6, Rval: 0, Rrowspan: 0, Rrcolspan: 2 },
    ); //заголовок (шапка)
    addHeaderRowDefaultDict(
      rows,
      'clutchUseMoveStartExcessiveWorkHi',
      {
        Hname: 'Работа сцепления в излишних пробуксовках на повышающей, кДж',
        HminWidth: 11.5,
        Hcolspan: 9,
        Hrowspan: 0,
        HminHeight: 0,
        HalignmentH: -1,
        Rformat: 0,
        RalignmentH: -1,
      },
      { Rformat: 13, Rval: 0, Rrowspan: 0, Rrcolspan: 2 },
    ); //заголовок (шапка)
    addHeaderRowDefaultDict(
      rows,
      'clutchUseMoveStartExcessiveWorkLow',
      {
        Hname: 'Работа сцепления в излишних пробуксовках на понижающей, кДж',
        HminWidth: 11.5,
        Hcolspan: 9,
        Hrowspan: 0,
        HminHeight: 0,
        HalignmentH: -1,
        Rformat: 0,
        RalignmentH: -1,
      },
      { Rformat: 13, Rval: 0, Rrowspan: 0, Rrcolspan: 2 },
    ); //заголовок (шапка)
    addHeaderRowDefaultDict(
      rows,
      'clutchUseMoveStartExcessiveWorkLowPercent',
      {
        Hname: 'Доля работы сцепления в излишних пробуксовках на понижающей, %',
        HminWidth: 11.5,
        Hcolspan: 9,
        Hrowspan: 0,
        HminHeight: 0,
        HalignmentH: -1,
        Rformat: 0,
        RalignmentH: -1,
      },
      { Rformat: 6, Rval: 0, Rrowspan: 0, Rrcolspan: 2 },
    ); //заголовок (шапка)

    //третья таблица Использование КПП по пробегу + четвертая Использование КПП по началу движения
    //rows.GearBoxNum = [];
    //rows.GearBoxNum.distName = addRowDict("GearBoxNum", 0, 'пробег');
    for (let ii = -2; ii <= gearBoxCount; ii++) {
      //if(ii == -0.5 || ii == 0.5) continue;
      const kp = codeGearBoxNumNameFunction(ii);
      rows['GearBoxNum_' + kp] = {};
      rows['GearBoxNum_' + kp].dist = addRowDict('GearBoxNum_' + kp, 13);
      rows['GearBoxNum_' + kp].distPercent = addRowDict('GearBoxNum_' + kp, 6);
      rows['GearBoxNum_' + kp].cntBeginMove = addRowDict(
        'GearBoxNum_' + kp,
        12,
      );
      rows['GearBoxNum_' + kp].cntBeginMovePercent = addRowDict(
        'GearBoxNum_' + kp,
        6,
      );

      //190720
      rows['GearBoxNum_' + kp].cntRpmClutchSlip = addRowDict(
        'GearBoxNum_' + kp,
        12,
      );
      rows['GearBoxNum_' + kp].cntRpmClutchSlipPercent = addRowDict(
        'GearBoxNum_' + kp,
        6,
      );
      rows['GearBoxNum_' + kp].clutchSlipExcessiveWork = addRowDict(
        'GearBoxNum_' + kp,
        13,
      );
      rows['GearBoxNum_' + kp].clutchSlipExcessiveWorkPercent = addRowDict(
        'GearBoxNum_' + kp,
        6,
      );
      rows['GearBoxNum_' + kp].clutchSlipExcessiveWorkLow = addRowDict(
        'GearBoxNum_' + kp,
        13,
      );

      rows['GearBoxNum_' + kp].accelMoveBeginMeasurementsCount = addRowDict(
        'GearBoxNum_' + kp,
        12,
      );
      rows['GearBoxNum_' + kp].accelMoveBeginMeasurementsSumm = addRowDict(
        'GearBoxNum_' + kp,
        13,
      );
      rows['GearBoxNum_' + kp].accelMoveBeginWithClutchSlipMeasurementsCount =
        addRowDict('GearBoxNum_' + kp, 12);
      rows['GearBoxNum_' + kp].accelMoveBeginWithClutchSlipMeasurementsSumm =
        addRowDict('GearBoxNum_' + kp, 13);
      rows['GearBoxNum_' + kp].avgAccelMoveBegin = addRowDict(
        'GearBoxNum_' + kp,
        6,
      );
      rows['GearBoxNum_' + kp].avgAccelMoveBeginWithClutchSlip = addRowDict(
        'GearBoxNum_' + kp,
        6,
      );

      if (ii == -2) {
        rows['GearBoxNum_name'] = {}; //название строк
        rows['GearBoxNum_name'].dist = addRowDict(
          'GearBoxNum_name',
          0,
          'Пробег',
        );
        rows['GearBoxNum_name'].distPercent = addRowDict(
          'GearBoxNum_name',
          0,
          'Доля от общего, %',
        );
        rows['GearBoxNum_name'].cntBeginMove = addRowDict(
          'GearBoxNum_name',
          12,
          'Начало движения, кол-во',
        );
        rows['GearBoxNum_name'].cntBeginMovePercent = addRowDict(
          'GearBoxNum_name',
          0,
          'Доля от общего, %',
        );

        //190720
        rows['GearBoxNum_name'].cntRpmClutchSlip = addRowDict(
          'GearBoxNum_name',
          0,
          'Излишняя пробуксовка, обор.',
          0,
          0,
          34,
        );
        rows['GearBoxNum_name'].cntRpmClutchSlipPercent = addRowDict(
          'GearBoxNum_name',
          0,
          'Доля от общего, %',
        );
        rows['GearBoxNum_name'].clutchSlipExcessiveWork = addRowDict(
          'GearBoxNum_name',
          0,
          'Работа при излишней пробуксовке, кДж',
          0,
          0,
          51,
        );
        rows['GearBoxNum_name'].clutchSlipExcessiveWorkPercent = addRowDict(
          'GearBoxNum_name',
          0,
          'Доля от общей излишней работы сцепления, %',
          0,
          0,
          51,
        );
        rows['GearBoxNum_name'].clutchSlipExcessiveWorkLow = addRowDict(
          'GearBoxNum_name',
          0,
          'Из них, излишняя пробуксовка на понижающей, кДж',
          0,
          0,
          51,
        );

        rows['GearBoxNum_name'].accelMoveBeginMeasurementsCount = addRowDict(
          'GearBoxNum_name',
          0,
          'число замеров акс. при начале движения',
        );
        rows['GearBoxNum_name'].accelMoveBeginMeasurementsSumm = addRowDict(
          'GearBoxNum_name',
          0,
          'сумма замеров акс. при начале движения',
        );
        rows['GearBoxNum_name'].accelMoveBeginWithClutchSlipMeasurementsCount =
          addRowDict(
            'GearBoxNum_name',
            0,
            'число замеров акс. при начале движения, при пробуксовке',
          );
        rows['GearBoxNum_name'].accelMoveBeginWithClutchSlipMeasurementsSumm =
          addRowDict(
            'GearBoxNum_name',
            0,
            'сумма замеров акс. при начале движения, при пробуксовке',
          );
        rows['GearBoxNum_name'].avgAccelMoveBegin = addRowDict(
          'GearBoxNum_name',
          0,
          'Ср. акс., % от макс., всего',
          0,
          0,
          34,
        );
        rows['GearBoxNum_name'].avgAccelMoveBeginWithClutchSlip = addRowDict(
          'GearBoxNum_name',
          0,
          'Ср. акс., % от макс., при пробуксовке',
          0,
          0,
          34,
        );
      }
    }
    //пятая таблица СТРОКИ
    for (let i = 0; i < rows.header.transmissionLockArr.length; i++) {
      //для каждой блокировки создаем свои показатели
      rows[rows.header.transmissionLockArr[i].nameArr] = {};
      rows[rows.header.transmissionLockArr[i].nameArr].count = addRowDict(
        rows.header.transmissionLockArr[i].nameArr,
        12,
      );
      rows[rows.header.transmissionLockArr[i].nameArr].time = addRowDict(
        rows.header.transmissionLockArr[i].nameArr,
        4,
      );
      rows[rows.header.transmissionLockArr[i].nameArr].dist = addRowDict(
        rows.header.transmissionLockArr[i].nameArr,
        13,
      );
      rows[rows.header.transmissionLockArr[i].nameArr].distPercent = addRowDict(
        rows.header.transmissionLockArr[i].nameArr,
        6,
      );
      if (i == 0) {
        rows['transmissionLock_name'] = {}; //название строк
        rows['transmissionLock_name'].count = addRowDict(
          'transmissionLock_name',
          0,
          'число включений',
        );
        rows['transmissionLock_name'].time = addRowDict(
          'transmissionLock_name',
          0,
          'время работы',
        );
        rows['transmissionLock_name'].dist = addRowDict(
          'transmissionLock_name',
          0,
          'пробег, км',
        );
        rows['transmissionLock_name'].distPercent = addRowDict(
          'transmissionLock_name',
          0,
          '% от общ. пробега',
        );
      }
    }
    //шестая таблица СТРОКИ //190720
    rows.engineBreakCnt = {
      val: addRowDict('engineBreakCnt', 12, 0, 0, 2),
    };

    rows.engineBreakTime = {
      val: addRowDict('engineBreakTime', 4, 0, 0, 2),
    };

    rows.breakCount = {
      val: addRowDict('breakCount', 12, 0, 0, 2),
    };

    rows.breakTime = {
      val: addRowDict('breakTime', 4, 0, 0, 2),
    };

    rows.pedalBreakCount = {
      val: addRowDict('pedalBreakCount', 12, 0, 0, 2),
    };

    rows.pedalBreakTime = {
      val: addRowDict('pedalBreakTime', 4, 0, 0, 2),
    };

    rows.engineBreakCntPercent = {
      val: addRowDict('engineBreakCntPercent', 6, 0, 0, 2),
    };

    rows.engineBreakTimePercent = {
      val: addRowDict('engineBreakTimePercent', 6, 0, 0, 2),
    };

    rows.absCount = {
      val: addRowDict('absCount', 12, 0, 0, 2),
    };

    addHeaderRowDefaultDict(
      rows,
      'clutchWorkSummAvgStart',
      {
        Hname: 'Средняя работа при начале движения, Кдж',
        HminWidth: 11.5,
        Hcolspan: 9,
        Hrowspan: 0,
        HminHeight: 0,
        HalignmentH: -1,
        Rformat: 0,
        RalignmentH: -1,
      },
      { Rformat: 13, Rval: 0, Rrowspan: 0, Rrcolspan: 2 },
    ); //заголовок (шапка)
    addHeaderRowDefaultDict(
      rows,
      'pedalBreakTime100km',
      {
        Hname: 'Ножной тормоз, сработка при движении, время на 100км',
        HminWidth: 11.5,
        Hcolspan: 9,
        Hrowspan: 0,
        HminHeight: 0,
        HalignmentH: -1,
        Rformat: 0,
        RalignmentH: -1,
      },
      { Rformat: 4, Rval: 0, Rrowspan: 0, Rrcolspan: 2 },
    ); //заголовок (шапка)
    addHeaderRowDefaultDict(
      rows,
      'engineBreakTime100km',
      {
        Hname: 'Моторный тормоз, время работы при движении на 100км',
        HminWidth: 11.5,
        Hcolspan: 9,
        Hrowspan: 0,
        HminHeight: 0,
        HalignmentH: -1,
        Rformat: 0,
        RalignmentH: -1,
      },
      { Rformat: 4, Rval: 0, Rrowspan: 0, Rrcolspan: 2 },
    ); //заголовок (шапка)

    // какие показатели разбивать по сменам, добавлять еще и в rowsExperimentIndicators, см. chartSummData.summData.summOfPeriods
    rows.periodSplitRows = [
      'motoEngine',
      'motoEngineSpd',
      'motoEngineNoSpd',
      'distance',
      'canExpence',
      'canExpenceSpd',
      'canExpenceNoSpd',
      'ptoCnt',
      'ptoTime',
      'ptoEngineRpm800_1000Time',
      'ptoSpdDistance',
      'rpmMore1200PtoTime',
      'transmission_lock_1_dist',
      'clutchUseMoveStartWorkSumm',
      'clutchUseMoveStartExcessiveWorkSumm',
      'rpm1900_2200Time',
      'breakTime',
      'pedalBreakTime',
      'engineBreakTime',
      'kickdownCnt',
      'absCount',
      'lowOilLampCount',
      'hightCoolTempLampCount',
      'cnt_real_pos',
      'cnt_pos_missed',
      'serviceDistance',
      'clutchSlipVehicleSlipRpmCnt',
      'clutchSlipVehicleSlipWork',
      'clutchSlipCriticalCnt',
      'clutchSlipCriticalTime',
      'clutchWorkSumm',
      'clutchSlipExcessiveRpmCnt',
      'clutchUseMoveStartSlipLowCnt',
      'clutchUseMoveStartExcessiveWorkLow',
      'clutchUseMoveStartCnt',
      'clutchSlipVehicleSlipTime',
    ];

    // какие показатели разбивать по сменам и они зависят от других значений, см getRelativeValue_helper
    rows.periodSplitDependentRows = [
      'avgSpd',
      'canExpence100km',
      'canExpence100kmMove',
      'canExpenceLHourMove',
      'canExpenceLHourIdle',
      'ptoEngineRpm800_1000Percent',
      'rpmMore1200PtoPercent',
      'transmission_lock_1_distPercent',
      'clutchUseMoveStartExcessiveWorkPercent',
      'clutchUseMoveStartExcessiveWorkLowPercent',
      'rpm1900_2200Percent',
      'engineBreakTimePercent',
      'receivedPercent',
      'clutchWorkSummAvgStart',
      'kickdownCnt100km',
      'absCount100km',
      'ptoCnt100km',
      'pedalBreakTime100km',
      'engineBreakTime100km',
    ];

    return rows;
  },

  endSmena: function ({
    values,
    curSmena,
    periodNum,
    sum_val,
    arrKeyViolations,
    periods,
    rows_person,
    rows_experiment,
    violationSmenas,
    v_time,
    codeGearBoxNumNameFunction,
    setWebToTable,
    iobj,
    violationSummCalculate,
    transmissionLockArr,
    isGetSmenas,
    isInsideInPositions = true,
  } = {}) {
    const periodByNum = periodNum > -1 ? periods[periodNum] : {};

    if (isInsideInPositions) {
      values.periodNumWasCalc = periodNum;

      if (periodNum > -1) {
        values.periodCalculatedCount++;
        values.periodHasBeenCalculated.push(periodNum);
      }

      //Удельное время работы на х.х. Не более 10%  времени смены
      curSmena.engineMoto = sum_val['t2_motoengine'];
      curSmena.engineMotoIdling = sum_val['t2_motoenginenospd'];
      let percentIdle = 0;

      if (curSmena.engineMoto - curSmena.engineMoto_prev > 0) {
        percentIdle =
          (100 * (curSmena.engineMotoIdling - curSmena.engineMotoIdling_prev)) /
          (curSmena.engineMoto - curSmena.engineMoto_prev);
      }
      //var percentIdle = curSmena.engineMoto > 0 ? (100 * curSmena.engineMotoIdling / curSmena.engineMoto) : 0;

      if (percentIdle > 10) {
        // var interval = 'с ' + formatDateHelper(new Date(periods[periodNum].TimeBegin ), 'dd.mm.yyyy hh:nn') + ' по ' + formatDateHelper(new Date(periods[periodNum].TimeEnd), 'dd.mm.yyyy hh:nn');
        rows_person.person.specificIdling.DCount++; //количество нарушений
        rows_person.person.specificIdling.DForfeits++; //количество штрафных баллов за нарушения
        curSmena.violationCnt++;
      }

      curSmena.dist = sum_val['t2_distance'];

      // sum_val['t2_canExpence'];
      // sum_val['t2_canExpenceSpd'];
      // sum_val['t2_canExpenceNoSpd'];

      //calcViolation_ptoSpdDistanceG_can_pto_gearbox(sum_val);
      //curSmena.violationCnt += rows_person.person['ptoSpdDistanceG']['DCount'];

      // periods[periodNum]['forfeits'] = 0;
      // periods[periodNum]['forfeitsRelative'] = 0;

      /* суммируем прочие нарушения*/
      for (var zz = 0; zz < arrKeyViolations.length; zz++) {
        var keyArr = arrKeyViolations[zz].toLowerCase();

        if (!(keyArr in sum_val)) {
          continue;
        }

        // общее число нарушений
        curSmena.violationCnt += sum_val[keyArr];

        // развертка по всем нарушениям
        const prevValue = curSmena.violationsPrev[keyArr] || 0;
        const diffValue = sum_val[keyArr] - prevValue;

        // if (diffValue) {
        // periods[periodNum][keyArr] = diffValue;
        // перепишем предыдущее значение - оно больше не нужно
        curSmena.violationsPrev[keyArr] = diffValue;
        // }
        // суммируем сразу общее число
        // periods[periodNum]['forfeits'] += diffValue;
      }
    } else {
      curSmena.dist = curSmena.dist_prev;
      curSmena.engineMoto = curSmena.engineMoto_prev;
      curSmena.engineMotoIdling = curSmena.engineMotoIdling_prev;
    }

    const distDiff = curSmena.dist - curSmena.dist_prev;

    // добавим сумму моточасов и пробега в периоды
    if (isGetSmenas) {
      // periods[periodNum]['t2_motoengine'] = curSmena.engineMoto - curSmena.engineMoto_prev;
      // periods[periodNum]['t2_motoenginenospd'] = curSmena.engineMotoIdling - curSmena.engineMotoIdling_prev;
      // periods[periodNum]['t2_distance'] = distDiff;
      periodByNum['t2_motoengine'] =
        curSmena.engineMoto - curSmena.engineMoto_prev;
      periodByNum['t2_motoenginenospd'] =
        curSmena.engineMotoIdling - curSmena.engineMotoIdling_prev;
      periodByNum['t2_distance'] = distDiff;

      /* переложим результат в таблицы*/
      if (isInsideInPositions) {
        setWebToTable(
          sum_val,
          codeGearBoxNumNameFunction,
          iobj.gearboxCnt,
          transmissionLockArr,
        );
      }

      // передачи КПП, пробег по сменам
      rows_experiment.header.GearBoxNumNameArr.forEach((gearBoxArrName) => {
        const prevSmenaOfArrName =
          curSmena.rowsExperiment[gearBoxArrName] || {};
        const { dist: distPrev = 0 } = prevSmenaOfArrName;

        const dist = rows_experiment[gearBoxArrName].dist.val;

        if (!rows_experiment[gearBoxArrName].dist['periods']) {
          rows_experiment[gearBoxArrName].dist['periods'] = [];
        }

        if (periodNum > -1) {
          rows_experiment[gearBoxArrName].dist['periods'][periodNum] =
            dist - distPrev;
        }

        curSmena.rowsExperiment[gearBoxArrName] = {
          dist,
        };
      });

      rows_person.personForDisplay.forEach((group) => {
        group.forEach((arrName) => {
          if (!arrName.toLowerCase().includes('group', 0)) {
            // это НЕ название группировки
            const prevSmenaOfArrName = curSmena.rowsPerson[arrName] || {};

            const { DCount, DForfeits } = rows_person.person[arrName];
            const { DCount: DCountPrev = 0, DForfeits: DForfeitsPrev = 0 } =
              prevSmenaOfArrName;

            if (!rows_person.person[arrName]['periods']) {
              rows_person.person[arrName]['periods'] = [];
            }

            if (periodNum > -1) {
              rows_person.person[arrName]['periods'][periodNum] = {
                DCount: DCount - DCountPrev,
                DForfeits: DForfeits - DForfeitsPrev,
              };
            }

            curSmena.rowsPerson[arrName] = { DCount, DForfeits };
          }
        });
      });

      const rowsExperimentCurPeriod = {};

      // const {last_real_power, last_real_buttery, last_real_time} = sum_val;

      rows_experiment.periodSplitRows.forEach((arrName) => {
        const prevSmenaOfArrName = curSmena.rowsExperiment[arrName] || 0;

        const curValue = {
          val: 0,
          obj: {},
          periodValue: 0,
        };

        if (arrName === 'serviceDistance') {
          curSmena.rowsExperiment[arrName] = 0;
        }

        if (arrName === 'transmission_lock_1_dist') {
          const { transmission_lock_1 = {} } = rows_experiment;
          const { dist: distObject = {} } = transmission_lock_1;
          const { val: transmission_lock_1_dist = 0 } = distObject;

          curValue.val = transmission_lock_1_dist;
          curValue.obj = distObject;
        } else {
          curValue.val = rows_experiment[arrName].val.val;
          curValue.obj = rows_experiment[arrName];
        }

        if (!curValue.obj['periods']) {
          curValue.obj['periods'] = [];
        }

        const { val: curValue_val } = curValue;

        if (arrName === 'serviceDistance') {
          curValue.periodValue = curValue_val || prevSmenaOfArrName;
        } else {
          curValue.periodValue = curValue_val - prevSmenaOfArrName;
        }

        const { periodValue } = curValue;

        if (periodNum > -1) {
          curValue.obj['periods'][periodNum] = periodValue;
        }

        rowsExperimentCurPeriod[arrName] = periodValue;

        curSmena.rowsExperiment[arrName] = curValue_val;
      });

      rows_experiment.periodSplitDependentRows.forEach((arrName) => {
        let curObject = {};
        if (arrName === 'transmission_lock_1_distPercent') {
          const { transmission_lock_1 = {} } = rows_experiment;
          const { distPercent: distPercentObject = {} } = transmission_lock_1;

          curObject = distPercentObject;
        } else {
          curObject = rows_experiment[arrName];
        }

        if (!curObject['periods']) {
          curObject['periods'] = [];
        }

        // rows_experiment[arrName]['periods'][periodNum] = getRelativeValue_helper(arrName, rowsExperimentCurPeriod);
        if (periodNum > -1) {
          curObject['periods'][periodNum] = getRelativeValue_helper(
            arrName,
            rowsExperimentCurPeriod,
          );
        }
      });
    }

    /* если были изменения - значит в этой смене были нарушения*/
    if (
      curSmena.violationCnt - curSmena.violationCnt_prev > 0 &&
      periodNum > -1
    ) {
      var arr_ = [];
      arr_.nSmena = periodNum;
      arr_.violationCnt = curSmena.violationCnt - curSmena.violationCnt_prev;
      violationSmenas.push(arr_);
    }

    return this.reset_curSmena(curSmena, false, v_time);
  },

  reset_curSmena: function (curSmena, isAdd, v_time = 0) {
    if (isAdd) {
      curSmena = {};
      curSmena.begin = 0; //
      curSmena.dist_prev = 0; //пробег за смену
      curSmena.engineMoto_prev = 0;
      curSmena.engineMotoIdling_prev = 0;
      curSmena.violationCnt_prev = 0; //
      curSmena.violationsPrev = {};
      curSmena.rowsPerson = {};
      curSmena.rowsExperiment = {};
    } else {
      curSmena.begin = v_time;
      curSmena.dist_prev = curSmena.dist;
      curSmena.engineMoto_prev = curSmena.engineMoto;
      curSmena.engineMotoIdling_prev = curSmena.engineMotoIdling;
      curSmena.violationCnt_prev = curSmena.violationCnt;
      curSmena.violationsPrev = addCopyObj_helper(curSmena.violations);
    }

    curSmena.dist = 0; //
    curSmena.engineMoto = 0; //общая сумма
    curSmena.engineMotoIdling = 0; //хх
    curSmena.violationCnt = 0; //кол-во нарушений за смену
    return curSmena;
  },

  mathArray: function (
    arrOne,
    arrTwo,
    symbol,
    arrKeyNoSum,
    arrKeyNoSummGetLast,
    duration,
  ) {
    const mathArr = {};

    if (!('time' in arrOne)) {
      return arrTwo;
    }
    if (!('time' in arrTwo)) {
      return arrOne;
    }
    for (let key in arrOne) {
      // if (key in arrKeyNoSummGetLast) {
      if (arrKeyNoSummGetLast[key]) {
        /* эти значения не вычисляются*/
        if (duration === 'first') {
          mathArr[key] = arrOne[key];
        } else {
          mathArr[key] = arrTwo[key];
        }
        continue;
      }

      if (key in arrKeyNoSum) {
        /* эти значения не вычисляются*/
        mathArr[key] = arrTwo[key];
        continue;
      }
      if (symbol == '+') {
        mathArr[key] = arrOne[key] + arrTwo[key];
      }
      if (symbol == '-') {
        mathArr[key] = arrOne[key] - arrTwo[key];
      }
    }
    return mathArr;
  },

  posValuesTypes: {
    geo: 'json',
    geo_in: 'json',
    geo_out: 'json',
    basket: 'boolean',
    weight_summ_isload: 'boolean',
  },

  trueInDb: {
    t: true,
    true: true,
    y: true,
    yes: true,
    on: true,
    1: true,
    f: false,
    false: false,
    n: false,
    no: false,
    off: false,
    0: false,
  },

  getPosValues: function (position) {
    let arr = {};
    for (let key in position) {
      const keyType = this.posValuesTypes[key] ?? 'number';
      const val = position[key];
      switch (keyType) {
        case 'number':
          arr[key] = Number(val);
          break;
        case 'json':
          arr[key] = val ? JSON.parse(val) : val;
          break;
        case 'boolean':
          arr[key] = val ? this.trueInDb[val] : val;
          break;
        default:
          arr[key] = val;
          break;
      }
      // if(key == 'time'){
      //     /* перевод в правильную таймзону*/
      //     //arr[key] += (tz_diff * 3600);
      // }
    }
    return arr;
  },

  getFirtPosIsValOfTheDay: function (position, arrKeyNoSum, isValOfTheDay) {
    let arrFirst = {};
    for (let key in position) {
      if (!isValOfTheDay || key in arrKeyNoSum) {
        /* в начале - значения середины дня или это не вычисляемые значения*/
        arrFirst[key] = position[key];
        continue;
      }
      /* в началае - нулевые значения*/
      arrFirst[key] = 0;
    }
    return arrFirst;
  },

  getPeriodNum: function (v_time, periods, lastPeriodNum) {
    /* определим номер периода*/
    if (lastPeriodNum < 0) {
      lastPeriodNum = 0;
    }

    /* расчет номера смены */
    for (let zz = lastPeriodNum; zz < periods.length; zz++) {
      const timeBegin = periods[zz].TimeBegin / 1000;
      const timeEnd = periods[zz].TimeEnd / 1000;

      if (zz === 0 && v_time < timeBegin) {
        return -1; //возможно, нужно пропускать такие позиции
      }

      if (v_time === timeBegin && zz === 0) {
        return zz;
      }

      if (v_time > timeBegin && v_time <= timeEnd) {
        return zz; //определили номер периода
      }
    }

    return -1; //не удалось определить
  },

  getSmenas_createPeriod: function (v_time, arrSmena, idxWorkTime) {
    var Period = {};
    if (v_time) {
      Period.TimeBegin = new Date(
        Number(v_time.toOnlyDay()) + arrSmena[idxWorkTime].TimeBegin,
      );
      Period.TimeEnd = new Date(
        Number(v_time.toOnlyDay()) + arrSmena[idxWorkTime].TimeEnd,
      );
    }
    Period.nSmena = idxWorkTime;
    Period.isWebOpened = false;
    Period.isWebClosed = false;
    Period.webBegin = {};
    Period.webEnd = {};
    Period.webSumm = {};
    return Period;
  },

  prepareTzh(unix, beforeUse = true) {
    if (!unix) {
      throw new Error('[skillsManTemplate.prepareTzh] time is not defined');
    }

    if (unix instanceof Date) {
      unix = unix.getTime();
    }

    let offset = 0;
    if (this.tzh !== undefined) {
      offset = (this.tzh - SERVICE_TZH) * 3600 * 1000;
    }

    if (beforeUse) {
      return unix + offset;
    } else {
      return unix - offset;
    }
  },

  getSmenas: function (ReportBegin, ReportEnd, smenasFlag, smenasOrigin) {
    function getFirstSmenaIdx(startDayOfSmenas, arrSmena, ReportBegin) {
      const millisecondsByDay = 86400000;
      // оставляем внутри getSmenas

      for (let i = 0; i < WorkTimesCnt; i++) {
        // here the arrSmena[i].TimeBegin and the arrSmena[i].TimeEnd are 0 if the WorkTimesCnt is == 1
        if (arrSmena[i].TimeBegin > arrSmena[i].TimeEnd) {
          CurWorkTimeBegin =
            startDayOfSmenas + arrSmena[i].TimeBegin - millisecondsByDay;
          CurWorkTimeEnd =
            startDayOfSmenas + arrSmena[i].TimeEnd - millisecondsByDay;
        } else {
          CurWorkTimeBegin = startDayOfSmenas + arrSmena[i].TimeBegin;
          CurWorkTimeEnd = startDayOfSmenas + arrSmena[i].TimeEnd;
        }

        if (CurWorkTimeEnd <= CurWorkTimeBegin) {
          CurWorkTimeEnd += millisecondsByDay;
        }

        if (ReportBegin > CurWorkTimeBegin && ReportBegin < CurWorkTimeEnd) {
          // начало отчета лежит в этой смене, но смена начинается раньше начала отчета
          CurWorkTimeBegin = ReportBegin;
        }

        if (CurWorkTimeBegin <= ReportBegin && CurWorkTimeEnd >= ReportBegin) {
          return i; //idxWorkTime
        }
      }

      for (let i = 0; i < WorkTimesCnt; i++) {
        // here the arrSmena[i].TimeBegin and the arrSmena[i].TimeEnd are 0 if the WorkTimesCnt is == 1
        CurWorkTimeBegin = startDayOfSmenas + arrSmena[i].TimeBegin;
        CurWorkTimeEnd = startDayOfSmenas + arrSmena[i].TimeEnd;

        if (CurWorkTimeEnd <= CurWorkTimeBegin) {
          CurWorkTimeEnd += millisecondsByDay;
        }

        if (ReportBegin > CurWorkTimeBegin && ReportBegin < CurWorkTimeEnd) {
          // начало отчета лежит в этой смене, но смена начинается раньше начала отчета
          CurWorkTimeBegin = ReportBegin;
        }

        // if ((CurWorkTimeBegin >= ReportBegin) && (CurWorkTimeEnd >= ReportBegin)) {
        if (CurWorkTimeBegin >= ReportBegin && CurWorkTimeEnd >= ReportBegin) {
          return i; //idxWorkTime
        }
      }

      return -1;
    }

    const WorkTimesCnt = smenasOrigin.length || 1;
    const arrSmena = [];

    for (let s = 0; s < WorkTimesCnt; s++) {
      const TimeBegin =
        smenasFlag && s in smenasOrigin
          ? this.getSmenas_getTime(smenasOrigin[s]['begin'])
          : 0;
      const TimeEnd =
        smenasFlag && s in smenasOrigin
          ? this.getSmenas_getTime(smenasOrigin[s]['end'])
          : 86400 * 1000;
      arrSmena.push({ TimeBegin, TimeEnd });
    }

    let CurWorkTimeBegin;
    let CurWorkTimeEnd;
    let Periods = [];
    let idxWorkTime = 0;
    let idxWorkTimeNext;
    let Period = {};
    // j = 1;

    ReportBegin = this.prepareTzh(ReportBegin);
    ReportEnd = this.prepareTzh(ReportEnd);

    //находим первую полную смену за период
    let startDayOfReport = Number(new Date(ReportBegin).toOnlyDay());

    idxWorkTime = getFirstSmenaIdx(startDayOfReport, arrSmena, ReportBegin);

    if (idxWorkTime < 0) {
      // в этих сутках нет смены - берем следующие
      idxWorkTime = getFirstSmenaIdx(
        startDayOfReport + 86400000,
        arrSmena,
        ReportBegin,
      );
    }

    //раскладка смен на весь период
    var cntSP = 0;

    Period = this.getSmenas_createPeriod(false, arrSmena, idxWorkTime);
    Period.TimeBegin = new Date(CurWorkTimeBegin);
    Period.TimeEnd = new Date(CurWorkTimeEnd);
    Periods[0] = Period;

    let v_time;

    while (Period.TimeEnd < ReportEnd) {
      cntSP++;

      if (
        WorkTimesCnt == 1 &&
        arrSmena[idxWorkTime].TimeEnd > arrSmena[idxWorkTime].TimeBegin
      ) {
        // v_time = new Date(Number(Periods[cntSP - 1].TimeEnd.toOnlyDay()) + 24 * 3600 * 1000);
        const onlyDayTimeStamp = Number(Periods[cntSP - 1].TimeEnd.toOnlyDay());
        v_time = new Date(onlyDayTimeStamp);

        Period = this.getSmenas_createPeriod(v_time, arrSmena, idxWorkTime);

        if (+Periods[cntSP - 1].TimeEnd === +Period.TimeEnd) {
          v_time = new Date(86400000 + onlyDayTimeStamp);
          Period = this.getSmenas_createPeriod(v_time, arrSmena, idxWorkTime);
        }
      } else {
        idxWorkTimeNext = idxWorkTime < WorkTimesCnt - 1 ? idxWorkTime + 1 : 0;
        idxWorkTime = idxWorkTimeNext;
        v_time = Periods[cntSP - 1].TimeEnd.toOnlyDay();

        Period = this.getSmenas_createPeriod(v_time, arrSmena, idxWorkTime);

        if (Period.TimeEnd <= Period.TimeBegin) {
          Period.TimeEnd = new Date(Number(Period.TimeEnd) + 24 * 3600 * 1000);
        }

        var maxSmenasCol = 3;
        var cnt = 0;
        while (cnt < maxSmenasCol) {
          cnt++;
          if (cntSP - cnt < 0) {
            break;
          }
          if (
            +Periods[cntSP - cnt].TimeEnd == +Period.TimeEnd &&
            +Periods[cntSP - cnt].TimeBegin == +Period.TimeBegin
          ) {
            // такая смена уже есть - нужно перелистнуть сутки
            Period.TimeEnd = new Date(
              Number(Period.TimeEnd) + 24 * 3600 * 1000,
            );
            Period.TimeBegin = new Date(
              Number(Period.TimeBegin) + 24 * 3600 * 1000,
            );
            break;
          }
        }
      }

      if (Period.TimeBegin < ReportEnd) {
        Periods[cntSP] = Period;
      } else {
        cntSP--;
      }
    }

    if (Periods[cntSP].TimeBegin >= ReportEnd) {
      Periods.splice(cntSP, 1);
    }

    let smenas = [];

    if (smenasFlag && smenasOrigin.length) {
      for (let z = 0; z < WorkTimesCnt; z++) {
        const smena_name =
          'Смена' +
          (z + 1) +
          '(' +
          smenasOrigin[z]['begin'] +
          ' - ' +
          smenasOrigin[z]['end'] +
          ')';
        smenas.push(smena_name);
      }
    } else {
      const smena_name =
        'Расчет ведется по суткам (сменой считаются границы суток с 00:00:00 до 23:59:59)';
      smenas.push(smena_name);
    }

    // корректировка первого и посленего периода по периоду отчета при необходимости
    if (Periods.length) {
      if (+Periods[0].TimeBegin > ReportBegin) {
        Periods[0].TimeBegin = new Date(+ReportBegin);
      }

      if (+Periods[Periods.length - 1].TimeEnd > ReportEnd) {
        Periods[Periods.length - 1].TimeEnd = new Date(+ReportEnd);
      }
    }

    //проверка раскладки смен (цикл ниже)
    this.badSmenasFlag = false;
    for (let i = 0; i < Periods.length - 1; i++) {
      if (Periods[i].TimeEnd > Periods[i + 1].TimeBegin) {
        this.badSmenasFlag = true;
        break;
      }
    }

    Periods.map((period) => {
      period.TimeBegin = this.prepareTzh(period.TimeBegin.getTime(), false);
      period.TimeEnd = this.prepareTzh(period.TimeEnd.getTime(), false);
    });

    return [Periods, smenas, arrSmena];
  },

  getSmenas_getTime: function (timeStroke) {
    // seconds from begin day (secons of a day)
    return (
      (Number(timeStroke.split(':')[0]) * 60 +
        Number(timeStroke.split(':')[1])) *
      60000
    ); // в милисекундах
  },

  addDesignTable_summ_forGraph: function (objs, ReportBegin, ReportEnd) {
    //создание структуры таблиц статистики
    //шапка отчета
    function addHeaderDict(
      name,
      minWidth,
      colspan,
      rowspan,
      minHeight,
      alignmentH,
      format,
      rowAlignmentH,
    ) {
      return {
        name: name ? name : 'NULL',
        minWidth: minWidth ? minWidth : 0,
        minHeight: minHeight ? minHeight : 0,
        colspan: colspan ? colspan : 0,
        rowspan: rowspan ? rowspan : 0,
        alignmentH: alignmentH != undefined ? alignmentH : -1,
        format: format ? format : 0, //используется при выгрузке детализации
        rowAlignmentH: rowAlignmentH != undefined ? rowAlignmentH : -1, //используется при выгрузке детализации
        colnum: 0, //номер столбца в шапке таблицы, используется далее, тут оставлять 0
        rownum: 0, //номер троки в шапке таблицы, используется далее при транспонировании таблицы, тут оставлять 0
        cellStep: 0, //объединение ячеек по вертикали для строки, не являющейся шапкой, используется при расчетах, тут оставлять 0
      };
      /*
             wsws.Cell(row, 20).Style.Alignment.Horizontal = 7; //выравнивание по правому краю
             wsws.Cell(row, 20).Style.Alignment.Horizontal = 2; //выравнивание по ширине
             wsws.Cell(row, 20).Style.Alignment.Horizontal = 3; //выравнивание по левому краю
             wsws.Cell(row, 20).Style.Alignment.Horizontal = 0; //выравнивание по центру
             */
    }

    //строка
    function addRowDict(header, format, val, rowspan, colspan) {
      return {
        header: header ? header : false,
        format: format ? format : 0,
        val: val ? val : 0,
        rowspan: rowspan ? rowspan : 0,
        colspan: colspan ? colspan : 0,
      };
    }
    function addHeaderRowDefaultDict(
      rows,
      rowArrname,
      addHeaderDictArr,
      addRowDictArr,
    ) {
      rows.header[rowArrname] = addHeaderDict(
        addHeaderDictArr.Hname,
        addHeaderDictArr.HminWidth,
        addHeaderDictArr.Hcolspan,
        addHeaderDictArr.Hrowspan,
        addHeaderDictArr.HminHeight,
        addHeaderDictArr.HalignmentH,
        addHeaderDictArr.Rformat,
        addHeaderDictArr.RalignmentH,
      );
      if (addRowDictArr) {
        rows[rowArrname] = {};
        rows[rowArrname].val = addRowDict(
          rowArrname,
          addRowDictArr.Rformat,
          addRowDictArr.Rval,
          addRowDictArr.Rrowspan,
          addRowDictArr.Rrcolspan,
        );
      }
    }
    //создание сущности
    var rows = {};
    //объявление ШАПОК таблиц
    //объявление таблицы ДЕТАЛИЗАЦИЯ, строки для нее будут созданы далее
    rows.header = {};
    addHeaderRowDefaultDict(rows, 'GNum', {
      Hname: '№',
      HminWidth: 16,
      Hcolspan: 0,
      Hrowspan: 0,
      HminHeight: 0,
      HalignmentH: 0,
      Rformat: 0,
      RalignmentH: -1,
    }); //заголовок (шапка)
    addHeaderRowDefaultDict(rows, 'GInterval', {
      Hname: 'Период',
      HminWidth: 16,
      Hcolspan: 2,
      Hrowspan: 0,
      HminHeight: 0,
      HalignmentH: 0,
      Rformat: 0,
      RalignmentH: -1,
    }); //заголовок (шапка)
    addHeaderRowDefaultDict(rows, 'GSumm', {
      Hname: 'Сумма',
      HminWidth: 16,
      Hcolspan: 0,
      Hrowspan: 0,
      HminHeight: 0,
      HalignmentH: 0,
      Rformat: 5,
      RalignmentH: -1,
    }); //заголовок (шапка)
    rows.settingsSumm = ['GNum', 'GInterval', 'GSumm'];
    for (var z = 0; z < objs.length; z++) {
      var iobj_avtoNo = objs[z].avtoNo;
      if (!iobj_avtoNo) {
        iobj_avtoNo = 'г/н ' + notValue;
      }
      addHeaderRowDefaultDict(rows, 'GObj_' + z, {
        Hname: iobj_avtoNo,
        HminWidth: 16,
        Hcolspan: 0,
        Hrowspan: 0,
        HminHeight: 0,
        HalignmentH: 0,
        Rformat: 5,
        RalignmentH: -1,
      }); //заголовок (шапка)
      rows.settingsSumm.push('GObj_' + z);
    }

    rows.lines = [];
    rows.lines.push([]); //чтобы все строки были как одна детализация, потом можно это изменить
    var week_interval = 604800000;
    //(new Date).getTimezoneOffset() = -300 для GMT+5
    var first_week_interval =
      345600000 + new Date().getTimezoneOffset() * 60000; //с поправкой на таймзону
    var week_num = Math.floor(
      (ReportBegin - first_week_interval) / week_interval,
    ); //номер недели
    function getWeekBeginTime(week_num) {
      return week_num * week_interval + first_week_interval;
    }
    var line_begin;
    var line_begin_str;
    var line_end;
    var line_end_str;
    var num = 0;
    for (var z = week_num; getWeekBeginTime(z) < ReportEnd; z++) {
      num++;
      line_begin = getWeekBeginTime(z);
      if (ReportBegin > line_begin) {
        line_begin =
          ReportBegin instanceof Date ? ReportBegin.getTime() : ReportBegin;
        line_begin_str = formatDateHelper(new Date(line_begin), 'dd.mm hh:nn');
      } else {
        line_begin_str = formatDateHelper(new Date(line_begin), 'dd.mm');
      }
      line_end = getWeekBeginTime(z + 1);
      if (line_end > ReportEnd) {
        line_end = ReportEnd instanceof Date ? ReportEnd.getTime() : ReportEnd;
        line_end_str = formatDateHelper(new Date(line_end), 'dd.mm hh:nn');
      } else {
        line_end_str = formatDateHelper(new Date(line_end - 100), 'dd.mm');
      }
      var interval = "'" + line_begin_str + ' - ' + line_end_str;
      rows.lines[0].push({
        line_begin: line_begin,
        line_end: line_end,
        GNum: num,
        GSumm: 0,
        GInterval: interval,
      });
      //дополним словарь информацией по объектам
      for (var zz = 0; zz < objs.length; zz++) {
        rows.lines[0][rows.lines[0].length - 1]['GObj_' + zz] = 0;
      }
    }
    return rows;
  },

  arrKeyNoSum: {
    /* словарь не вычисляемых значений*/
    time: 0,
    line_id: 0,
    id: 0,
    seconds_of_the_day: 0,
    last_pos_num: 0,
    last_real_time: 0,
    last_offset_real_time: 0,
    can_odometer: 0,
    can_moto: 0,
    rfid: 0,
    change_rfid: 0,
    lat: 0,
    lon: 0,
    head: 0,
    geo: 0,
    geo_in: 0,
    geo_out: 0,
    basket: 0,
    reason: 0,
  },

  arrKeyNoSummGetLast: {
    //190720
    service_distance: 1,
    // geo: 1,
    // geo_in: 1,
    // geo_out: 1,
    // basket: 1,
    // reason: 1,
  },

  arrKeyViolations: [
    /* словарь нарушений*/
    /* таблица 1*/
    't1_tempOilAndAntifreeze',
    't1_tempOilInPallet',
    't1_starterWork',
    't1_tempMode',
    't1_specificIdling',
    't1_useRegulateRdc',
    't1_rightGearForStart',
    't1_timothyInManualMode',
    't1_timothyChangingD_Dx',
    't1_applicationDX',
    't1_slipping',
    't1_clutchSlipStart',
    't1_diffTransmOverload',
    't1_rpmStartSpdMore1200Cnt',
    't1_accelSharplyCnt',
    't1_swithClutchNoSpdCnt',
    't1_excessRpm',
    't1_gearshiftRpm',
    't1_upperToLowerRange',
    't1_upperToLowerRangeAndGearshift',
    't1_switchLock',
    't1_switchLower',
    't1_backAxlesLock',
    't1_frontAxlesLock',
    't1_backWheelsLock',
    't1_frontWheelsLock',
    't1_moveLockCrossWheel',
    't1_rpmSpdLess440CntG',
    't1_swithClutchInsufficientCntG',
    't1_engineStalledspdCntG',
    't1_swithGearBoxRewersSpdCnt',
    't1_ptoRpmViolation',
    't1_ptoKrenUnload',
    't1_rollforwardNeutral',
    't1_moveClutchOpen',
    't1_workCruiseAndSpeedLimiter',
    't1_ineffectiveRpmWithNoMotoBrake',
    't1_useMotoBrake',
    /* Неэффективная эксплуатация ТС:*/
    't2_rpm1900_2200Time',
    't2_swithClutchNoSpdCountTime',
    // 't2_rpmNo800_1000less1200Cnt',
    't2_rpmNo800_1000less1200Time',
    't2_swithGearBoxWrongCnt',
    't2_swithGearBoxWrongPercent',
    't2_swithClutchInsufficientCnt',
    /* Нарушения при эксплуатации ТС:*/
    't2_rpmMore2200Time',
    't2_rpmMore1200PtoTime',
    // 't2_clutchSlipCnt',
    // 't2_clutchSlipTime',
    't2_startSpdGearBoxMore2Cnt',
    't2_loweringSpdMore25Distance',
    't2_loweringSpdMore25Time',
    't2_crosswheelSpdMore7Distance',
    't2_crosswheelSpdMore7Time',
    't2_ptoSpdDistance',
    't2_ptoSpdTime',
  ],

  // setEndSmena: function ({
  //     sum_val,
  //     arrKeyViolations,
  //     values,
  //     valuesPrev,
  //     curSmena,
  //     periods,
  //     rows_person,
  //     rows_experiment,
  //     violationSmenas,
  //     v_time,
  //     codeGearBoxNumNameFunction,
  //     setWebToTable,
  //     iobj,
  //     violationSummCalculate,
  //     transmissionLockArr,
  //     isGetSmenas
  // } = {}) {
  //     /* проверка смены на завершение*/
  //     if (values.periodNum != valuesPrev.periodNum && ('time' in sum_val)) {
  //         //разрешаем соотвутствующим нарушениям вести подсчет нарушений в смене
  //         curSmena = this.endSmena({
  //                 values,
  //                 curSmena,
  //                 periodNum: valuesPrev.periodNum,
  //                 sum_val,
  //                 arrKeyViolations,
  //                 periods,
  //                 rows_person,
  //                 rows_experiment,
  //                 violationSmenas,
  //                 v_time,
  //                 codeGearBoxNumNameFunction,
  //                 setWebToTable,
  //                 iobj,
  //                 violationSummCalculate,
  //                 transmissionLockArr,
  //                 isGetSmenas
  //         });
  //     }
  //     return curSmena;
  // },

  // format: formatToDisplay_helper,
  // format: function(value, format) {
  //     let res;
  //     switch(format) {
  //         case 0.5:
  //             return value;
  //         case 1:
  //             return formatDateHelper(new Date(value), 'dd.mm.yyyy hh:nn:ss');
  //         case 1.5:
  //             return formatDateHelper(new Date(value), 'dd.mm.yyyy hh:nn:ss');
  //         case 2:
  //             return formatDateHelper(new Date(value), 'dd.mm.yyyy');
  //         case 3:
  //             return formatDateHelper(new Date(value), 'hh:nn:ss');
  //         case 4:
  //             return formatTimeHelper(value, 'tt:nn:ss');
  //         case 5:
  //             res = roundNumber_helper(value, 1);
  //             if (isNaN(res)) return value;
  //             return res;
  //         case 5.5:
  //             res = roundNumber_helper(value, 1);
  //             if (isNaN(res)) return value;
  //             return res;
  //         case 6:
  //             res = roundNumber_helper(value * 100, 1);
  //             if (isNaN(res)) return value;
  //             return res + ' %';
  //         case 7:
  //             return roundNumber_helper(value, 2);
  //         case 8:
  //             res = roundNumber_helper(value * 100, 2);
  //             if (isNaN(res)) return value;
  //             return res + ' %';
  //         case 9:
  //             return roundNumber_helper(value, 3);
  //         case 10:
  //             return roundNumber_helper(value * 100, 3) + ' %';
  //         case 11:
  //             return roundNumber_helper(value, 0) + ' %'; //число уже в процентах
  //         case 12:
  //             if (isNaN(+value)) {
  //                 return value;
  //             }
  //             return roundNumber_helper(value, 0);

  //         default:
  //             return value;
  //     }

  // }
};
