import { initCalculatingByDay } from '@/App/use/getElementPosition';
import { IAggregationPosition } from './../../../types/IAggregationPosition';
import { TCallback } from './../../../types/TCallBack';
import { IPosition } from './../../../types/IPosition';

import { useRequest } from '@/ManualApp/use/request';

import {
  formatDateHelper,
  getTzh,
  getVolationDesc_helper,
} from '@/helpers/main_helper';
import { violationsDescriptions } from '@/src/dataRequest/mainScript';
import { url_get_aggregated } from '@/src/dataRequest/ourAggregated';
import fetchJson from '@/src/dataRequest/fetch-json';

const LAT_LON_DELIMETER = 1_000_000_000_000_000;
const GPS_SIGNAL_MIN = 1;
const URL_POSITIONS_OUR = 'Api/other/getPositions';

const getFullDate = (val: number | string) => {
  return formatDateHelper(new Date(+val * 1000), 'dd.mm.yyyy hh:nn:ss');
};

const { send } = useRequest();

interface IInterval {
  from: number;
  to: number;
}

interface ISendObj {
  interval: number;
  id: number | string;
  timeBegin: number;
  timeOffset: number;
  allColumns: boolean;
  columns?: string[];
  levels?: string[];
}

interface IPreparedData {
  id: number | string;
  timeBegin: number;
  timeEnd: number;
  timeOffset: number;
  interval: number;
  allColumns: boolean;
  sends: ISendObj[];
  aggregation: null | {
    [key: string]: string | number;
    id: number | string;
    time_begin: number;
    time_end: number;
    option: 2;
    template_name: 'shiftWork';
    tzh: number;
  };
  columns?: string[];
  levels?: string[];
}

interface IGetPositionsFromOurOptions {
  objId: string;
  processCallback: TCallback;
  isOnMap: boolean;
  interval: IInterval;
  columns?: string[];
  levels?: string[];
}

interface ITimeCheck {
  begin: Date;
}

interface IPositionsResponseObject {
  json_agg: string;
}

interface IPositionsResponse {
  objName: string;
  objId: number | string;
  positions: IPositionsResponseObject[];
  getBegin: number;
  getEnd: number;
  memory_get_peak_usage: number;
  aggregation?: { posArr: IAggregationPosition[] };
}

interface IPositionsResult {
  [key: string]: any;
  objName: string;
  gearboxName: string;
  objId: string;
  getBegin: number;
  getEnd: number;
  memory_get_peak_usage_summ: number;
  positions: IPosition[];
  error: string;
  objConf: { [key: string]: any };
}

export interface IPoints {
  [key: string]: any;
  objName: string;
  latlngs: number[][];
  params: any[];
  values: IPosition[];
  allValues: IPosition[];
  levels: {
    [key: string]: {
      oiginal: number[];
      analyzed: number[];
      status: number[];
      text: string;
      purpose: string;
    };
  };
  violations_stat: { [key: string]: number; summ: number };
  consumptions_stat: {
    summ: number;
    [key: number]: {
      latlon: number[];
      time: number;
      value: string;
      status: number;
    };
  };
}

export interface ITreatmentPositions {
  objId: string;
  points: IPoints;
  trackInfo: string;
  infoForShow: string;
  getBegin: number | string;
  getEnd: number | string;
  trackBegin: string;
  trackEnd: string;
  stateNumber: string;
  objName: string;
  polylineColor: string;
}

function getPointsFromOur(positions: IPosition[], objName: string) {
  // обработка позиций для трека, плеера треков и графика по треку

  const points: IPoints = {
    objName,
    latlngs: [],
    params: [],
    values: [],
    allValues: [],
    levels: {},
    violations_stat: {
      // статистика по количеству нарушений
      summ: 0, // сумма всех нарушений
    },
    consumptions_stat: {
      // статистика по количеству заправок
      summ: 0, // сумма всех заправок
    },
    violations_text: [],
    violations_values: [],
  };

  let posIndex = 0;
  let maxSpeed = 0;
  let distReset = -1;
  let distSummPrev = 0;

  let column = 0;
  let violation_id = [];
  let violation_text = [];
  let violation_values: string | object = [];

  let curSpeed = 0;

  let prevRpm = 0;
  let prevActiveTime = 0;
  let prevEngineTime = 0;
  let hirpm_notactime = 0;

  const levels = points.levels;
  const leveCount = 10;
  const levelsDesc = [
    { purpose: 'consumption', purposeText: 'расходный' },
    { purpose: 'cistern', purposeText: 'цистерна' },
  ];
  const calculateDistSumm = initCalculatingByDay();
  const calculateEngineTime = initCalculatingByDay();
  const calculateActiveTime = initCalculatingByDay();

  for (let k = 0; k < positions.length; k++) {
    const {
      time,
      speed,
      gps,
      lat,
      lon,
      violation_1,
      violation_2,
      violation_3,
      clutch_time_uninterruptedly,
      pto_cnt_violation,
      spd_accel,
      head,
    } = positions[k];

    const allValues: {
      stateNumber?: string;
      distSumm?: number;
      hirpm_notactime?: number;
      maxSpeed?: number;
    } & IPosition = positions[k];

    const { dist, is_last_day_pos } = allValues;

    if (speed < 2500) {
      // 250 км/ч
      curSpeed = speed;
    }

    const distSumm = calculateDistSumm(k, dist, is_last_day_pos);

    allValues['distSumm'] = distSumm;

    const engine_time = calculateEngineTime(
      k,
      allValues['engine_time'],
      is_last_day_pos,
    );
    const active_time = calculateActiveTime(
      k,
      allValues['active_time'],
      is_last_day_pos,
    );

    if (
      prevRpm > 800 &&
      allValues['rpm'] > 800 &&
      active_time - prevActiveTime <= 0
    ) {
      hirpm_notactime += engine_time - prevEngineTime;
    }

    prevRpm = allValues['rpm'];
    prevActiveTime = active_time;
    prevEngineTime = engine_time;

    allValues['hirpm_notactime'] = hirpm_notactime;

    if (gps < GPS_SIGNAL_MIN || !(+lat > 0 && +lon > 0)) {
      allValues['maxSpeed'] = maxSpeed;
      points['allValues'][k] = allValues;
      continue;
    }

    points['latlngs'][posIndex] = [
      +lat / +LAT_LON_DELIMETER,
      +lon / +LAT_LON_DELIMETER,
    ];

    if (+speed > maxSpeed) {
      maxSpeed = +speed;
    }

    levelsDesc.forEach((lvDesc) => {
      const { purpose, purposeText } = lvDesc;

      for (let ii = 0; ii < leveCount; ii++) {
        const lvNum = ii + 1;
        const lvName = `${purpose}_${lvNum}`;
        const lvVal = positions[k][`original_${lvName}`] ?? false;

        if (lvVal === false || (lvVal === null && !(lvName in levels))) {
          continue;
        }

        const lvStatus = positions[k][`status_${lvName}`];
        const lvAnalyzed = positions[k][`analyzed_${lvName}`];

        if (!(lvName in levels)) {
          const summText = lvStatus & 8 ? ' (сумма)' : '';

          levels[lvName] = {
            oiginal: [],
            analyzed: [],
            status: [],
            text: `ДУТ ${purposeText} ${lvNum}${summText}`,
            purpose,
          };
        }

        const level = levels[lvName];
        level.oiginal[posIndex] = lvVal !== null ? lvVal / 1000 : lvVal;
        level.analyzed[posIndex] =
          lvAnalyzed !== null ? lvAnalyzed / 1000 : lvAnalyzed;
        level.status[posIndex] = lvStatus;

        if (lvStatus & 16 || lvStatus & 64) {
          const value = level.analyzed[posIndex] - level.analyzed[posIndex - 1];

          points['consumptions_stat'][posIndex] = {
            latlon: points['latlngs'][posIndex],
            time,
            value: value.toFixed(1),
            status: lvStatus,
          };
        }
      }
    });

    column = 0;
    violation_id = [];
    violation_text = [];
    violation_values = '';
    while ('violation_' + Number(++column) in positions[k]) {
      const col_name = 'violation_' + column;
      if (!(positions[k][col_name] > 0)) {
        continue;
      }

      for (let i = 0; i < 31; i++) {
        if (positions[k][col_name] & (1 << i)) {
          let viol_id = i + 1 + 31 * (column - 1);
          violation_id.push(viol_id);
          let violation_desc = getVolationDesc_helper(
            viol_id,
            violationsDescriptions.desc,
          );
          violation_text.push(violation_desc['description']);

          if (viol_id in points['violations_stat']) {
            points['violations_stat'][viol_id]++;
          } else {
            points['violations_stat'][viol_id] = 1;
          }

          points['violations_stat']['summ']++;
        }
      }

      violation_values = {
        clutch_time_unine: clutch_time_uninterruptedly,
        pto_cnt_violation: pto_cnt_violation,
        spd_accel: spd_accel,
        spd: curSpeed,
      };
    }

    points['allValues'][k] = allValues;
    // points['values'][posIndex] = {time, speed, head, gps, maxSpeed};
    points['values'][posIndex] = points['allValues'][k];

    points['params'][posIndex] = {
      time,
      viewTime: time / 1000,
      speed: curSpeed,
      violation_1,
      violation_2,
      violation_3,
      violation_id: violation_id.join(';'),
      violation_text: violation_text.join(';<br>'),
      violation_values: violation_values,
      head: head,
      gps,
      maxSpeed,
      violationsSumm: points['violations_stat']['summ'],
    };

    posIndex++;
  }

  return points;
}

function treatmentResponseOurAndSave(
  response: IPositionsResult,
  processCallback: TCallback,
) {
  // сохранение позиций и вставка трека на карту
  if (
    !('objId' in response) ||
    !('objName' in response) ||
    !('positions' in response)
  ) {
    processCallback('error', 'Ошибка: ответ получен, но он невалидный !!!');
    return;
  }

  processCallback('loading', 'Данные получены, обрабатываю ...', '', 100);
  const positions = response['positions'];

  const trackBegin = getFullDate(response['getBegin']);
  const trackEnd = getFullDate(response['getEnd']);

  const objName = response['objName'];
  const objId = response['objId'];

  const stateNumber =
    positions[0] && positions[0].stateNumber ? positions[0].stateNumber : '';

  const points = getPointsFromOur(positions, objName);

  points.aggregation = response.aggregation;

  return {
    objId,
    points: points,
    getBegin: response['getBegin'],
    getEnd: response['getEnd'],
    trackBegin,
    trackEnd,
    stateNumber,
    objName,
  } as ITreatmentPositions;
}

function ajaxPositionsDoneAllGetParam(
  result: IPositionsResult,
  jsonRes: IPosition,
  paramName: string,
) {
  if (!result[paramName] && jsonRes[paramName]) {
    result[paramName] = String(jsonRes[paramName]).trim();
  }
}

function ajaxPositionsDoneAll(
  piecesPositions: IPositionsResponse[],
  processCallback: TCallback,
) {
  if (!piecesPositions.length) {
    processCallback(
      'error',
      'Ошибка: данные отсутствуют, попробуйте обновить страницу. ',
    );
    return;
  }

  const result: IPositionsResult = {
    objName: '',
    gearboxName: '',
    objId: '',
    getBegin: 0,
    getEnd: 0,
    memory_get_peak_usage_summ: 0,
    positions: [],
    error: '',
    objConf: {},
    aggregation: null,
  };

  const positionsArr: { positions: IPosition[]; getBegin: number }[] = [];

  for (let i = 0; i < piecesPositions.length; i++) {
    const piecesPosition = piecesPositions[i];
    let jsonRes;

    if (!!piecesPosition.aggregation) {
      result.aggregation = piecesPosition.aggregation;
      continue;
    }

    if (piecesPosition !== null && typeof piecesPosition === 'object') {
      jsonRes = piecesPosition;
    } else {
      try {
        jsonRes = JSON.parse(piecesPosition);
      } catch (e) {
        console.error('not JSON', jsonRes);
      }
      piecesPositions.splice(i, 1); // free memory
    }

    if (!jsonRes) {
      console.error('ERROR: получен НЕ JSON в номере ответа ' + i);
      return;
    }

    if (
      !('objId' in jsonRes) ||
      !('objName' in jsonRes) ||
      !('positions' in jsonRes)
    ) {
      console.error(
        'Ошибка: ответ получен, но он невалидный !!! в номере ответа ' + i,
      );
      return;
    }

    if (
      !(0 in jsonRes['positions']) ||
      !('json_agg' in jsonRes['positions'][0])
    ) {
      console.error(
        'Ошибка: ответ получен, но в нем нет информации о позициях !!! в номере ответа ' +
          i,
      );
      return;
    }

    if ('objConf' in jsonRes && jsonRes['objConf']) {
      result['objConf'] = jsonRes['objConf'];
    }

    if ('error' in jsonRes && jsonRes['error']) {
      result['error'] += ' / ' + jsonRes['error'];
    }

    if (
      jsonRes['getBegin'] &&
      (!result['getBegin'] || result['getBegin'] > jsonRes['getBegin'])
    ) {
      result['getBegin'] = jsonRes['getBegin'];
    }

    if (
      jsonRes['getEnd'] &&
      (!result['getEnd'] || result['getEnd'] < jsonRes['getEnd'])
    ) {
      result['getEnd'] = jsonRes['getEnd'];
    }

    ajaxPositionsDoneAllGetParam(result, jsonRes, 'objName');
    ajaxPositionsDoneAllGetParam(result, jsonRes, 'objId');
    ajaxPositionsDoneAllGetParam(result, jsonRes, 'gearboxName');

    if (jsonRes['memory_get_peak_usage']) {
      result['memory_get_peak_usage_summ'] +=
        jsonRes['memory_get_peak_usage'] / 1000000; // usage for php script in server, Mb
    }

    if (jsonRes['positions'][0]['json_agg']) {
      const json_agg = jsonRes['positions'][0]['json_agg'];

      let positions;
      if (json_agg !== null && typeof json_agg === 'object') {
        positions = json_agg;
      } else {
        positions = JSON.parse(json_agg);
        jsonRes['positions'][0]['json_agg'] = ''; // free memory
      }

      positionsArr.push({
        positions,
        getBegin: jsonRes['getBegin'],
      });
    }
  }

  for (const key in result['objConf']) {
    result['objConf'][key] = String(result['objConf'][key]).trim();
  }

  positionsArr.sort(function (a, b) {
    return a['getBegin'] - b['getBegin'];
  });

  positionsArr.forEach((obj) => {
    if (obj.positions.length) {
      [].push.apply(result['positions'], obj.positions as any);
    }
  });

  // очистить позиции от null значений
  result['positions'].forEach((position, index) => {
    if (index) {
      for (const key in position) {
        if (position[key] === null) {
          delete position[key];
        }
      }
    }
  });

  return result;
}

function ajaxPositionsDoneAll_action(
  piecesPositions: IPositionsResponse[],
  processCallback: TCallback,
  sectionReportsDetail: string,
  isOnMap: boolean,
) {
  processCallback(
    'loading',
    'Все данные получены, обрабатываю результат... ',
    sectionReportsDetail,
    100,
  );

  const response = ajaxPositionsDoneAll(piecesPositions, processCallback);

  if (!response) return;

  if (isOnMap) {
    return treatmentResponseOurAndSave(response, processCallback);
  }
}

function ajaxPositionsFailAll(
  processCallback: TCallback,
  sectionReportsDetail: string,
) {
  //fail
  console.error('error');
  processCallback(
    'error',
    'Ошибка запроса. Выполните новый запрос',
    sectionReportsDetail,
  );
}

function preparePromises(
  {
    sends: preparingDetailData,
    aggregation: preparingAggregationData,
  }: IPreparedData,
  processCallback: TCallback,
  sectionReportsDetail: string,
) {
  const countOfAggregationResponses = 1;
  const queryCount = preparingDetailData.length + countOfAggregationResponses;

  let resCount = 0;
  const requests = preparingDetailData.map(async (preparingData) => {
    const data = preparingData;

    const res = await send(URL_POSITIONS_OUR || '', 'POST', data, {
      ['Content-Type']: 'text/plain;charset=UTF-8',
    });

    resCount++;

    const percent = Math.floor((100 * resCount) / queryCount);

    processCallback(
      'loading',
      `Загрузка: ${resCount} из ${queryCount} (${percent}%)`,
      sectionReportsDetail,
      percent,
    );

    return await res;
  });

  const aggregationRequestUrl = new URL(url_get_aggregated);
  for (const key in preparingAggregationData) {
    const value = preparingAggregationData[key];
    aggregationRequestUrl.searchParams.append(key, value + '');
  }
  const aggregationRequest = fetchJson(aggregationRequestUrl, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json;charset=utf-8',
    },
  }).then((response) => {
    resCount++;

    const percent = Math.floor((100 * resCount) / queryCount);

    processCallback(
      'loading',
      `Загрузка: ${resCount} из ${queryCount} (${percent}%)`,
      sectionReportsDetail,
      percent,
    );

    return { aggregation: response };
  });

  requests.push(aggregationRequest);

  return requests;
}

async function getPicesPositions(
  preparedData: IPreparedData,
  processCallback: TCallback,
  sectionReportsDetail: string,
  isOnMap: boolean,
) {
  const promises = preparePromises(
    preparedData,
    processCallback,
    sectionReportsDetail,
  );

  return await Promise.all(promises)
    .then((responses: IPositionsResponse[]) => {
      return ajaxPositionsDoneAll_action(
        responses,
        processCallback,
        sectionReportsDetail,
        isOnMap,
      );
    })
    .catch((error) => {
      console.error(error);
      ajaxPositionsFailAll(processCallback, sectionReportsDetail);
    });
}

function getCallOurPosotionsPrepareDataSends(preparedData: IPreparedData) {
  const step = 28800; // 28800 - 8 часов
  preparedData.sends = [];

  preparedData.aggregation = {
    id: preparedData.id,
    time_begin: preparedData.timeBegin,
    time_end: preparedData.timeEnd,
    option: 2,
    template_name: 'shiftWork',
    tzh: getTzh(),
  };

  for (
    let i = 0;
    i < preparedData.timeEnd - preparedData.timeBegin;
    i += step
  ) {
    let resBegin = preparedData.timeBegin + i;

    if (i) resBegin++;

    let resEnd = preparedData.timeBegin + i + step;
    if (resEnd > preparedData.timeEnd) {
      resEnd = preparedData.timeEnd;
    }

    const resInterval = resEnd - resBegin;
    if (resInterval <= 0) continue;

    preparedData.sends.push({
      interval: resInterval,
      id: preparedData.id,
      timeBegin: resBegin,
      timeOffset: preparedData.timeOffset,
      allColumns: preparedData.allColumns,
      columns: preparedData.columns,
      levels: preparedData.levels,
    });
  }
}

function preparingDataFromGetPos(
  objId: string,
  processCallback: TCallback,
  isOnMap: boolean,
  interval: IInterval,
  columns?: string[],
  levels?: string[],
): IPreparedData | undefined {
  const preparedData: IPreparedData = {
    id: objId,
    timeBegin: 0,
    timeEnd: 0,
    timeOffset: 0,
    interval: 0,
    allColumns: false,
    sends: [],
    aggregation: null,
  };

  if (!interval.from) {
    processCallback('error', 'Неверно указаны дата или время начала запроса.');
    return;
  }
  if (!interval.to) {
    processCallback(
      'error',
      'Неверно указаны дата или время окончания запроса.',
    );
    return;
  }

  preparedData.timeBegin = interval.from / 1000;
  preparedData.timeEnd = interval.to / 1000;

  if (preparedData.timeBegin >= preparedData.timeEnd) {
    processCallback(
      'error',
      'Неверно выбран интервал запроса, исправьте ошибку.',
    );
    return;
  }

  preparedData.timeOffset = -new Date().getTimezoneOffset() / 60;

  preparedData.interval = preparedData.timeEnd - preparedData.timeBegin; //86400 переводит секунды в часы
  preparedData.allColumns = !isOnMap;
  if (columns) {
    preparedData.columns = columns;
  }
  if (levels) {
    preparedData.levels = levels;
  }

  return preparedData;
}

export async function getPositionsFromOur({
  objId,
  processCallback,
  isOnMap,
  interval,
  columns,
  levels,
}: IGetPositionsFromOurOptions) {
  const preparedData = preparingDataFromGetPos(
    objId,
    processCallback,
    isOnMap,
    interval,
    columns,
    levels,
  );

  if (!preparedData) return;

  // подготовка для асинохронного запроса
  getCallOurPosotionsPrepareDataSends(preparedData);

  const sectionReportsDetail = isOnMap ? '' : 'section-reports-detail';
  processCallback('loading', 'Запрос отправлен...', sectionReportsDetail, 0);

  return await getPicesPositions(
    preparedData,
    processCallback,
    sectionReportsDetail,
    isOnMap,
  );
}
