import fetchJson from '@/src/dataRequest/fetch-json';
import CalculateRequestDataworker from '@/workers/CalculateRequestData.worker.js';
import fetchToTemplateService from '@/src/dataRequest/fetchToTemplateService.js';
import {formatTimeHelper2} from '@/helpers/main_helper'

export class FuelInOutTemplate {
  api = `${
    process.env.VUE_APP_BACKEND_URL || window.location.origin + '/'
  }Api/fuelInOut/get`;
  isError = false;

  constructor(templateName, ourAggregated, data, globalObjectsList) {
    this.templateName = templateName;
    this.ourAggregated = ourAggregated;
    this.areaId = data.areaId;
    this.buttonId = data.buttonId;
    this.button = document.getElementById(data.buttonId);
    this.host = `http://${document.location.host}`;
    this.form_desc = data.form_desc
    this.isCistern = templateName.includes('cistern');
    this.globalObjectsList = globalObjectsList;
  }

  geoDelimeter = 1000000000000000;
  databaseColumnsType = {
    line_id: 'int',
    obj_id: 'int',
    lv_code: 'smallint',
    lv_num: 'smallint',
    lv_importance: 'smallint',
    time: 'bigint',
    begin: 'bigint',
    end: 'bigint',
    out: 'bool',
    vol: 'int',
    level_begin: 'int',
    speed: 'smallint',
    gps: 'smallint',
    move: 'bool',
    lat: 'bigint',
    lon: 'bigint',
  };

  databaseColumnsMultipler = {
    time: 1000,
    begin: 1000,
    end: 1000,
  };

  databaseColumnsDelimeter = {
    vol: 1000,
    level_begin: 1000,
    speed: 10,
    lat: this.geoDelimeter,
    lon: this.geoDelimeter,
  };

  submitEvent() {
    this.that.submit();
  }

  async submit() {
    const { areaId, host, buttonId } = this;

    const targetSpan = document.getElementsByClassName(buttonId + "-target-id")[0];

    if (!this.form_desc.objects.length) {
      infoShowText_helper(targetSpan, 'Не выбран ни один объект.', areaId);
      setElementColor_helper(this.button, 'orange');
      return;
    }

    if (this.form_desc.beginTime >= this.form_desc.endTime) {
      infoShowText_helper(targetSpan, 'Неверно выбрано время запроса.', areaId);
      setElementColor_helper(this.button, 'orange');
      return;
    }

    infoShowText_helper(
      targetSpan,
      'Отправляю запрос для отчета по сливам и заправкам.',
      areaId,
    );
    setElementColor_helper(this.button, 'blue');
    const objectsDescription = this.form_desc.objects.reduce((acc, objId) => {
      const objRow = this.globalObjectsList[objId];
      acc[objId] = {};

      acc[objId].name = objRow.name;
      acc[objId].stateNumber = objRow.state_number;

      return acc;
    }, {});

    const time_begin = this.form_desc.beginTime / 1000;
    const time_end = this.form_desc.endTime / 1000;

    fetchToTemplateService({
      templateName: this.templateName,
      form_desc: this.form_desc,
      options: {
        summ_levels_only: 1,
        objectsDescription,
        isCistern: this.isCistern,
      },
      areaId,
      successCallback: this.drawReport({
        areaId,
        time_begin,
        time_end,
        targetSpan,
      }),
      errorCallback: () =>
        this.oldFetchAndDrawReport({
          host,
          objIdArr: this.form_desc.objects,
          areaId,
          targetSpan,
          time_begin,
          time_end,
        }),
    });
  }

  oldFetchAndDrawReport({
    host,
    objIdArr,
    areaId,
    targetSpan,
    time_begin,
    time_end,
  }) {
    const promises = this.getDataPromises({
      host,
      objIdArr,
      areaId,
      targetSpan,
      time_begin,
      time_end,
    });

    const worker = new CalculateRequestDataworker();

    Promise.all(promises)
      .then((responses) => {
        responses.sort((a, b) => a.objId.localeCompare(b.objId));
        infoShowText_helper(
          targetSpan,
          'Данные получены, обрабатываю результат',
          areaId,
        );
        responses = responses.filter((response) => response !== undefined)

        responses.map((response) => {
            const objRow = this.globalObjectsList[response.objId];
            response.name = objRow['name'];
            response.stateNumber = objRow['stateNumber'];
        });

        worker.postMessage({
          templateName: this.templateName,
          data: { responses },
        });
      })
      .catch((err) => {
        console.error(err);
        infoShowText_helper(
          targetSpan,
          'Произошла ошибка, попробуйте еще раз или обновите страницу.',
          areaId,
        );
        setElementColor_helper(this.button, 'red');
      });

    worker.addEventListener('message', (e) => {
      const data = e.data;

      if (data.message) {
        infoShowText_helper(targetSpan, data.message);
        return;
      }

      const drawReportAction = this.drawReport({
        areaId,
        time_begin,
        time_end,
        targetSpan,
      });

      drawReportAction(data);

      worker.terminate();
    });

    worker.addEventListener('error', ({ message }) => {
      console.error(message);
      worker.terminate();
    });
  }

  drawReport({ areaId, time_begin, time_end, targetSpan }) {
    return (data) => {
      const groupRows = data;

      const area = document.getElementById(areaId);

      area.innerHTML = this.getTemplate({
        groupRows,
        columns: this.tableColumns,
        timeBegin: time_begin,
        timeEnd: time_end,
      });

      infoShowText_helper(
        targetSpan,
        'Отчет построен. Выполнить новый запрос?',
      );
      setElementColor_helper(this.button, 'green');
    };
  }

  getDataPromises({
    host,
    objIdArr,
    targetSpan,
    areaId,
    time_begin,
    time_end,
  } = {}) {
    const queryCount = objIdArr.length;
    let receivedCount = 0;

    this.isError = false;

    return objIdArr.map((objId) => {
      const trObject = this.globalObjectsList[objId];
      const lastTimeAggregation = parseInt(trObject['last_time_aggregation'])
      // if (lastTimeAggregation < time_begin) {
      //   return new Promise((resolve) => {
      //     receivedCount = this.showReceivedPercent({
      //       targetSpan,
      //       receivedCount,
      //       queryCount,
      //       areaId,
      //     });
      //     resolve({
      //       objId,
      //       begin: time_begin,
      //       end: time_end,
      //       error: 0,
      //       lines: [],
      //     });
      //   });
      // }

      const url = new URL(this.api, host);
      url.searchParams.set('id', objId);
      url.searchParams.set('time_begin', time_begin);
      url.searchParams.set('time_end', time_end);
      url.searchParams.set('template_name', 'fuelInOut');
      url.searchParams.set('summ_levels_only', 1);
      if (this.isCistern) {
        url.searchParams.set('consumption', 0);
      } else {
        url.searchParams.set('cistern', 1);
      }

      return new Promise((resolve, reject) => {

        fetchJson(url, {})
          // .then((res) => res.json())
          .then((res) => {
            // receivedCount++;
            // const percent = roundNumber_helper(100 * receivedCount / queryCount, 1);
            // infoShowText_helper(targetSpan, `Получен ответ ${receivedCount} из ${queryCount} (${percent}%)`, areaId);
            receivedCount = this.showReceivedPercent({
              targetSpan,
              receivedCount,
              queryCount,
              areaId,
            });

            const { begin, end, error } = res;
            const lines = error
              ? []
              : res.lines.map((line) => this.getLineFromDbType(line));

              resolve( {
              objId,
              begin,
              end,
              error,
              lines,
            });
          })
          .catch(() => {
            this.isError = true;
          })
      });
    });
  }

  showReceivedPercent({ targetSpan, receivedCount, queryCount, areaId } = {}) {
    receivedCount++;
    if (this.isError) {
      return receivedCount;
    }
    const percent = roundNumber_helper((100 * receivedCount) / queryCount, 1);
    infoShowText_helper(
      targetSpan,
      `Получен ответ ${receivedCount} из ${queryCount} (${percent}%)`,
      areaId,
    );
    return receivedCount;
  }

  getLineFromDbType(line) {
    const res = {};
    const {
      databaseColumnsType,
      databaseColumnsMultipler,
      databaseColumnsDelimeter,
    } = this;

    for (const [key, val] of Object.entries(line)) {
      const type = databaseColumnsType[key] ?? 'text';
      let value = this.getValueByType(val, type);

      const multiplier = databaseColumnsMultipler[key] || 0;
      if (multiplier) {
        value *= multiplier;
      }

      const delimeter = databaseColumnsDelimeter[key] || 0;
      if (delimeter) {
        value /= delimeter;
      }

      res[key] = value;
    }

    return res;
  }

  getValueByType(val, type) {
    switch (type) {
      case 'int':
      case 'smallint':
      case 'bigint':
        return parseInt(val);
      case 'bool':
      case 'boolean':
        if (val === 'f' || val === 'false' || val === '0' || !val) {
          return false;
        }

        return true;
      case 'text':
      default:
        return val;
    }
  }

  getTemplate({ groupRows, columns, timeBegin, timeEnd } = {}) {
    const simpleTableHelper = new SimpleTableHelper();
    const templateName = this.templateName;
    const headerName = this.isCistern ? 'в цистернах' : 'в баках';

    return `
        <button class="not-print float-left" onclick="html_to_excel_helper.export( this, 'fuel_in_out_template', 'Отчет по заправкам и сливам ${headerName}', 'portrait' )">
            Экспорт&nbsp;в&nbsp;EXCEL 
        </button>
        <div class="temlate-report" id="fuel_in_out_template">
            <div data-excelsheet="Отчет по заправкам и сливам ${headerName}"></div>
            <h6 class="text-center font-weight-bold">Отчет по заправкам и сливам ${headerName} за период c ${formatDateHelper(
      new Date(timeBegin * 1000),
      'dd.mm.yyyy hh:nn:ss',
    )} по ${formatDateHelper(
      new Date(timeEnd * 1000),
      'dd.mm.yyyy hh:nn:ss',
    )}</h6>
            <p class="text-center excel-bottom-indent">
              Отчет сформирован ${formatTimeHelper2(new Date())}
            </p>
            <table class="table-simple">
                <thead class="sticky">
                    ${simpleTableHelper.getTheadTemplate({ columns })}
                </thead>
                <tbody>
                    ${simpleTableHelper.getTbodyTemplate({ groupRows })}
                </tbody>
            </table>
        </div>
        `;
  }

  tableColumns = {
    name: {
      text: 'Наименование',
      dataminwidth: 15,
    },
    stateNumber: {
      text: 'Гос. номер',
      dataminwidth: 15,
    },
    time: {
      text: 'Дата/время',
      formatNum: 1.1, // 'dd.mm.yyyy hh:nn:ss'
      isDataValue: true,
      dataminwidth: 17,
    },
    vol: {
      text: 'Объем слива / заправки',
      formatNum: 5,
      dataminwidth: 10,
      isDataValue: true,
    },
    vol_out: {
      text: 'Объем слива',
      formatNum: 5,
      dataminwidth: 7,
      isDataValue: true,
    },
    vol_in: {
      text: 'Объем заправки',
      formatNum: 5,
      dataminwidth: 8.3,
      isDataValue: true,
    },
    level_begin: {
      text: 'Начальный уровень',
      formatNum: 5,
      dataminwidth: 10,
      isDataValue: true,
    },
    level_end: {
      text: 'Конечный уровень',
      formatNum: 5,
      dataminwidth: 9.1,
      isDataValue: true,
    },
    lv_num: {
      text: 'Номер уровнемера',
      formatNum: 0.5,
      dataminwidth: 11,
    },
    lv_target: {
      text: 'Назначение уровнемера',
      dataminwidth: 14,
    },
    is_move: {
      text: 'Движение',
      dataminwidth: 9.4,
    },
    location: {
      text: 'Местоположение',
      dataminwidth: 15.6,
    },

    columnsForDisplay: [
      'stateNumber',
      'time',
      'level_begin',
      'vol_out',
      'vol_in',
      'level_end',
      'is_move',
      'lv_num',
      'lv_target',
      'location',
    ],
  };
}
