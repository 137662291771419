<template>
  <div data-excelsheet="Суммарные показатели"></div>

  <h6 class="text-center font-weight-bold mb-3">
    Превышение скорости
    {{ t_interval }}
    ({{ speedOnText }})
  </h6>
  <p class="text-center excel-bottom-indent">
    Отчет сформирован {{ formatTimeHelper2(new Date()) }}
  </p>

  <overspeed-template-summary-table
    :trigger="props.trigger"
    :data="props.data"
  />

  <div v-for="objData of props.data">
    <div :data-excelsheet="objectsList[objData.oid].stateNumber"></div>

    <h6
      class="font-weight-bold d-none"
      :class="{
        'text-center': objData.events.length,
      }"
    >
      Превышение скорости
      {{ t_interval }}
      ({{ speedOnText }})
    </h6>

    <span style="padding: 4px 0 0 2px; color: #6c757d">
      {{
        `События превышения скорости ТС "${objectsList[objData.oid].client}" ${
          objectsList[objData.oid].stateNumber
        } / ${
          objectsList[objData.oid].model
        } за период ${t_interval} (${speedOnText})`
      }}
    </span>

    <table
      v-if="!objData.events.length"
      border="1"
      cellpadding="5"
      data-excelautofilter
      style="
        border-collapse: collapse;
        border: 1px solid black;
        caption-side: top;
        margin-right: 5px;
      "
      class="sortable-table freezeTable-table d-none"
    >
      <thead class="sticky">
        <tr>
          <th
            colspan="20"
            class="d-none"
            :class="{ 'text-center': objData.events.length }"
          >
            Превышений скорости не зафиксировано
          </th>
        </tr>
      </thead>
    </table>

    <div
      v-if="!objData.events.length"
      class="not-excel"
      style="padding: 0 2px"
    >
      <span> Превышений скорости не зафиксировано </span>
    </div>

    <table
      v-if="objData.events.length"
      border="1"
      cellpadding="5"
      data-excelautofilter
      style="
        border-collapse: collapse;
        border: 1px solid black;
        caption-side: top;
        margin-right: 5px;
      "
      class="sortable-table freezeTable-table"
    >
      <thead class="sticky">
        <tr class="border-bottom-tr print-repeat print-frozen-row">
          <template
            v-for="(obj, key) in TABLE_HEADER"
            :key="key"
          >
            <th
              :data-minwidth="obj.excelWidth"
              :data-formatnum="obj.formatNum"
              data-excelalignh="center"
              data-excelalignv="middle"
              :style="obj.style"
              v-html="obj.html"
            ></th>
          </template>
        </tr>
      </thead>
      <tbody>
        <tr
          v-for="(row, index) of objData.events"
          :class="{
            'active': activeRowHash == `${index}-${objData.oid}`,
            'hover': props.params.detailWithMaps,
            'my-cursor-pointer': props.params.detailWithMaps,
          }"
          @click="() => displayTrack(row, index, objData.oid)"
        >
          <td
            v-for="(obj, key) in TABLE_HEADER"
            :data-formatnum="obj.formatNum"
            :data-value="obj.getExcelValue ? obj.getExcelValue(row) : ''"
            :style="obj.style"
          >
            {{ obj.get ? obj.get(row, objData.oid) : row[key] }}
            <template v-if="obj.getAHref">
              <a
                class="no-link"
                target="_blank"
                :href="obj.getAHref(row, objData.oid)"
                @click.prevent
              >
                {{ obj.getAText(row) }}
              </a>
            </template>
          </td>
        </tr>
      </tbody>
    </table>
    <div class="not-print excel-bottom-indent margin-bottom-small"></div>
  </div>
</template>

<script setup>
import { computed, inject, ref, watch } from 'vue';
import {formatTimeHelper2} from '@/helpers/main_helper'

import OverspeedTemplateSummaryTable from './OverspeedTemplateSummaryTable.vue';

const props = defineProps({
  trigger: Boolean,
  data: { type: Array, default: [] },
  params: { type: Object, default: {} },
});

const emit = defineEmits(['displayTrack']);

const LAT_LNG_MULTIPLIER = 1000000000000000;

const TABLE_HEADER = {
  // client: {
  //   html: 'Владелец',
  //   excelWidth: 20,
  //   formatNum: 0,
  //   style: {
  //     'min-width': '150px',
  //   },
  //   get(row, oid) {
  //     const obj = objectsList[oid];
  //     if (!obj || !obj.client) return 'Не доступен';
  //     return obj.client;
  //   },
  // },
  // name: {
  //   html: 'Объект',
  //   excelWidth: 20,
  //   formatNum: 0,
  //   style: {
  //     'min-width': '200px',
  //   },
  //   get(row, oid) {
  //     const obj = objectsList[oid];
  //     if (!obj || !obj.name) return 'Не доступен';
  //     return obj.name;
  //   },
  // },
  // number: {
  //   html: 'Гос. номер',
  //   excelWidth: 15,
  //   formatNum: 0,
  //   style: {
  //     'min-width': '150px',
  //   },
  //   get(row, oid) {
  //     const obj = objectsList[oid];
  //     if (!obj || !obj.stateNumber) return 'Не доступен';
  //     return obj.stateNumber;
  //   },
  // },

  date: {
    html: 'Дата',
    excelWidth: 11,
    formatNum: 2,
    style: {
      'min-width': '100px',
    },
    getExcelValue: ({ t1 }) => {
      return t1
        ? (t1 * 1000) / 86400000 + 25569 - new Date().getTimezoneOffset() / 1440
        : '';
    },
    get(row) {
      if (row.t1 > 0) {
        return formatDateHelper(new Date(row.t1 * 1000), 'dd.mm.yyyy');
      }
      return row.t1;
    },
  },
  timeBegin: {
    html: 'Время начала превышения',
    excelWidth: 15,
    formatNum: 3,
    style: {
      'min-width': '150px',
    },
    getExcelValue: ({ t1 }) => {
      return t1
        ? (t1 * 1000) / 86400000 + 25569 - new Date().getTimezoneOffset() / 1440
        : '';
    },
    get(row) {
      if (row.t1 > 0) {
        return formatDateHelper(new Date(row.t1 * 1000), 'hh:nn:ss');
      }
      return row.t1;
    },
  },
  timeEnd: {
    html: 'Время окончания превышения',
    excelWidth: 16,
    formatNum: 3,
    style: {
      'min-width': '150px',
    },
    getExcelValue: ({ t2 }) => {
      return t2
        ? (t2 * 1000) / 86400000 + 25569 - new Date().getTimezoneOffset() / 1440
        : '';
    },
    get(row) {
      if (row.t2 > 0) {
        return formatDateHelper(new Date(row.t2 * 1000), 'hh:nn:ss');
      }
      return row.t2;
    },
  },
  tmspd: {
    html: 'Время макc. скорости',
    excelWidth: 13,
    formatNum: 3,
    style: {
      'min-width': '175px',
    },
    getExcelValue: ({ tmspd }) => {
      return tmspd
        ? (tmspd * 1000) / 86400000 +
            25569 -
            new Date().getTimezoneOffset() / 1440
        : '';
    },
    get(row) {
      if (row.tmspd > 0) {
        return formatDateHelper(new Date(row.tmspd * 1000), 'hh:nn:ss');
      }
      return row.tmspd;
    },
  },
  interval: {
    html: 'Длительность превышения',
    excelWidth: 14,
    formatNum: 4,
    style: {
      'min-width': '100px',
    },
    getExcelValue: ({ ti }) => {
      return ti ? (ti * 1000) / 86400000 : '';
    },
    get(row) {
      return formatTimeHelper(row.ti / 86400, 'tt:nn:ss');
    },
  },
  dist: {
    html: 'Пробег по GPS/ГЛОНАСС, м.',
    excelWidth: 16,
    formatNum: 12,
    style: {
      'min-width': '150px',
    },
    get(row) {
      return !isNaN(row.dist) ? myRoundNumber_helper(row.dist, 2) : row.dist;
    },
  },
  maxSpeed: {
    html: 'Макс. зафиксированная скорость',
    excelWidth: 22,
    formatNum: 12,
    style: {
      'min-width': '200px',
    },
    get(row) {
      return !isNaN(row.mspd) ? myRoundNumber_helper(row.mspd, 1) : row.mspd;
    },
  },
  overspeed: {
    html: 'Превышение на, км/ч',
    excelWidth: 16,
    formatNum: 12,
    style: {
      'min-width': '200px',
    },
    get(row) {
      return row.mspd - row.sspd;
    },
  },
  sspd: {
    html: 'Макс. установленная скорость при обсчете, км/ч',
    excelWidth: 27,
    formatNum: 12,
    style: {
      'min-width': '225px',
    },
  },
  maxGeoSpeed: {
    html: 'Макс. установленная скорость при формировании отчета, км/ч',
    excelWidth: 30,
    formatNum: 12,
    style: {
      'min-width': '250px',
    },
    get(row) {
      const geo = allGeofencesById.value[row.gid];
      return !isNaN(geo.speedLimit)
        ? myRoundNumber_helper(geo.speedLimit, 1)
        : geo.speedLimit;
    },
  },
  geoName: {
    html: 'Наименование геозоны',
    excelWidth: 30,
    formatNum: 0,
    style: {
      'min-width': '200px',
    },
    get(row) {
      const geo = allGeofencesById.value[row.gid];
      if (!geo) return 'Недоступная геозона';
      return geo.name;
    },
  },
  coords: {
    html: 'Координаты превышения',
    excelWidth: 30,
    formatNum: 0,
    style: {
      'min-width': '150px',
    },
    getAHref(row, oid) {
      const lat = myRoundNumber_helper(row.mlat / LAT_LNG_MULTIPLIER, 5);
      const lon = myRoundNumber_helper(row.mlon / LAT_LNG_MULTIPLIER, 5);

      return `${
        process.env.VUE_APP_BACKEND_URL || window.location.origin
      }/track-motion?type=position&lat=${lat}&lon=${lon}`;
    },
    getAText(row) {
      const lat = myRoundNumber_helper(row.mlat / LAT_LNG_MULTIPLIER, 5);
      const lon = myRoundNumber_helper(row.mlon / LAT_LNG_MULTIPLIER, 5);
      return `${lat}, ${lon}`;
    },
  },
};

const speedOn = inject('speedOnKey');
const allGeofences = inject('allGeofences');
const objectsList = inject('globalObjectsList');

const activeRowHash = ref(null);

function displayTrack(event, index, oid) {
  activeRowHash.value = `${index}-${oid}`;
  event.oid = oid;
  emit('displayTrack', event);
}

const t_interval = computed(() => {
  const toDate = (unix) => {
    return formatDateHelper(new Date(unix * 1000), 'dd.mm.yyyy hh:nn:ss');
  };

  if (props.data[0].from && props.data[0].to) {
    return `с ${toDate(props.data[0].from)} по ${toDate(props.data[0].to)}`;
  }

  return ' - ';
});

const speedOnText = computed(() =>
  speedOn.value == 'gspd' ? 'GPS/ГЛОНАСС' : 'CAN',
);

const allGeofencesById = computed(() => {
  return allGeofences.reduce((acc, geo) => {
    acc[geo.id] = geo;
    return acc;
  }, {});
});

watch(
  () => props.trigger,
  () => {
    activeRowHash.value = '';
  },
);

watch(
  () => props.params,
  (params) => {
    TABLE_HEADER.coords.style.display = params.linkToGoogleMaps
      ? 'table-cell'
      : 'none';
  },
  { immediate: true, deep: true },
);
</script>

<style lang="scss" scoped>
.no-link {
  color: #000;
  text-decoration: none;
}
.no-link:hover {
  color: #000;
  text-decoration: none;
}
.active,
.hover:hover {
  background: rgba(aqua, 0.2);
}
.my-cursor-pointer {
  cursor: pointer;
}
</style>
