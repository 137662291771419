import { IFuels } from './../App/store/IFuelSetting';
import {
  formatDateHelper,
  formatTimeHelper,
  myRoundNumber_helper,
  ucFirst_helper,
} from '@/helpers/main_helper';
import { IPoints } from './../App/TemplateComponents/ExcavatorOperation/getPositionsFromOur';
import { initCalculatingByDay } from '@/App/use/getElementPosition';

type TLabels = Date[];

type TMoto1Fill = { y: boolean; interval: number }[];
type TLevels = {
  [key: string]: {
    oiginal: number[];
    analyzed: number[];
    status: number[];
    text: string;
    purpose: string;
  };
};

type TLevelPosData = {
  x: Date;
  y: number;
  text?: string;
  textPosition?: string;
  bgColor?: string;
  textColor?: string;
  lvIn?: number;
  lvOut?: number;
};

type TAxesData = { [key: string]: Date | number; x: Date; y: number }[];

type TLegendValueFormatArgs = {
  data: TAxesData;
  index: number;
};

type TSelectionValuesByAngleArgs = {
  data: TAxesData;
  indexBegin: number;
  indexEnd: number;
  label: string;
};

type TChartTemplate = { label: string; columns: string[] };

type T_CHARTS_TEMPLATE = {
  [key: string]: TChartTemplate;
};

type TDatasetsFromOurPositions = {
  labels: TLabels;
  speed: number[];
  distSumm: number[];
  gps: number[];
  gsm: number[];
  kren: number[];
  tang: number[];
  rpm: number[];
  pwr: number[];
  bat: number[];
  engine_time: number[];
  active_time: number[];
  hirpm_notactime: number[];
  moto1Fill: TMoto1Fill;
  levels: TLevels;
  required: { val: boolean; because: string }[];
};

export type TChart<DataType> = {
  type: string;
  name: string;
  data: { x: Date; y: any; interval?: number }[];
  isStartHidden: boolean;
  colorStroke: string;
  colorFill?: string;
  strokeWidth: number;
  opacity: number;
  opacityFill?: number;
  isLine: boolean;
  isAreaFull: boolean;
  label: string;
  xAxis: string;
  yAxis: string;
  isBezierCurve: boolean;
  isSelectionValues?: boolean;
  legendValueSymbolsCount: number;
  legendValueFormat: ({ data, index }: TLegendValueFormatArgs) => string;
  selectionValuesFunction?: (params: {
    chart: any;
    data: DataType;
    indexBegin: number;
    indexEnd: number;
    label: string;
  }) => string;
};

type TCharts = {
  engineWorking: (
    labels: TLabels,
    moto1Fill: TMoto1Fill,
    isStartHidden: boolean,
  ) => TChart<TMoto1Fill>;
  speed: (
    labels: TLabels,
    speed: number[],
    isStartHidden: boolean,
  ) => TChart<number[]>;
  rpm: (
    labels: TLabels,
    rpm: number[],
    isStartHidden: boolean,
  ) => TChart<number[]>;
  gps: (
    labels: TLabels,
    gps: number[],
    isStartHidden: boolean,
  ) => TChart<number[]>;
  gsm: (
    labels: TLabels,
    gsm: number[],
    isStartHidden: boolean,
  ) => TChart<number[]>;
  kren: (
    labels: TLabels,
    kren: number[],
    isStartHidden: boolean,
  ) => TChart<TAxesData>;
  tang: (
    labels: TLabels,
    tang: number[],
    isStartHidden: boolean,
  ) => TChart<TAxesData>;
  pwr: (
    labels: TLabels,
    pwr: number[],
    isStartHidden: boolean,
  ) => TChart<number[]>;
  bat: (
    labels: TLabels,
    bat: number[],
    isStartHidden: boolean,
  ) => TChart<number[]>;
  engine_time: (
    labels: TLabels,
    engine_time: number[],
    isStartHidden: boolean,
  ) => TChart<number[]>;
  active_time: (
    labels: TLabels,
    active_time: number[],
    isStartHidden: boolean,
  ) => TChart<number[]>;
  hirpm_notactime: (
    labels: TLabels,
    hirpm_notactime: number[],
    isStartHidden: boolean,
  ) => TChart<number[]>;
  distance: (
    labels: TLabels,
    distSumm: number[],
    isStartHidden: boolean,
  ) => TChart<number[]>;
  consumptionAndAnalyzedCharts: (
    labels: TLabels,
    levels: TLevels,
    isStartHidden: boolean,
  ) => TChart<TLevelPosData[]>[];
};

export type TAxis = {
  id: string;
  label: string;
  isTicks: boolean;
  isGrids?: boolean;
  isTime?: boolean;
  orientText?: string;
  orientScale?: string;
  titleIntervalFormat?: (milliseconds: number) => string;
};
type TXAxis = TAxis & {
  min?: Date;
  max?: Date;
  tickFormat?: (date: Date) => string;
  titleFormat?: (date: Date) => string;
};

type TAxes = {
  [axesName: string]: (...args: any[]) => TAxis;
  xDateTime: (
    minMax: { min: Date; max: Date },
    chartLabels: string[],
  ) => TXAxis;
  ySpeed: (chartLabels: string[]) => TAxis;
  yDistance: (chartLabels: string[]) => TAxis;
  yGpsGsmPwr: (chartLabels: string[]) => TAxis;
  yRpm: (chartLabels: string[]) => TAxis;
  yLevelConsumption: (chartLabels: string[]) => TAxis;
  yHours: (chartLabels: string[]) => TAxis;
};

interface IUseChart {
  CHARTS_TEMPLATE: T_CHARTS_TEMPLATE;
  getColumnsByChartNames: (chartNames: string[]) => string[];
  getDatasetsFromOurPositions: (
    points: IPoints,
    fuelSettings?: IFuels,
  ) => TDatasetsFromOurPositions;
  charts: TCharts;
  axes: TAxes;
}

function calculateSplitByDay(
  arr: { [key: string]: any; is_last_day_pos: boolean }[] = [],
  paramName: string,
): number[] {
  const result = [];

  let reset = 0;
  let begin = 0;
  let end = 0;

  for (let i = 0; i < arr.length; i++) {
    const obj = arr[i];
    const val = obj[paramName];

    if (i === 0) {
      begin = val;
      continue;
    }

    end = val;

    result.push(end - begin + reset);

    if (obj.is_last_day_pos) {
      reset = val;
    }
  }

  return result;
}

export const useChart: IUseChart = {
  CHARTS_TEMPLATE: {
    // cspeed: 'Скорость по CAN',
    engineWorking: { label: 'Работа ДВС (заливка)', columns: ['moto_mask'] },
    speed: { label: 'Скорость', columns: ['speed'] },
    rpm: { label: 'Обороты ДВС', columns: ['rpm'] },
    gps: { label: 'ГЛОНАСС', columns: ['gps'] },
    gsm: { label: 'GSM', columns: ['gsm'] },
    kren: { label: 'Крен', columns: ['kren'] },
    tang: { label: 'Тангаж', columns: ['tang'] },
    pwr: { label: 'Борт. напряжение', columns: ['pwr'] },
    bat: { label: 'Внутр. заряд', columns: ['bat'] },
    distance: { label: 'Пробег', columns: ['dist', 'is_last_day_pos'] },
    engine_time: { label: 'Работа ДВС', columns: ['engine_time'] },
    // engine_move: { label: 'Работа ДВС в движении', columns: ['engine_move'] },
    active_time: {
      label: 'Активная работа (по инклинометру)',
      columns: ['active_time'],
    },
    hirpm_notactime: {
      label: 'Повышенные обороты без активной работы',
      columns: ['engine_time', 'active_time', 'rpm'],
    },
  },
  getColumnsByChartNames(chartNames) {
    const uniqColumns: Set<string> = new Set();

    for (const name of chartNames) {
      if (name in this.CHARTS_TEMPLATE) {
        this.CHARTS_TEMPLATE[name].columns.forEach((col) => {
          uniqColumns.add(col);
        });
      }
    }

    return [...uniqColumns];
  },
  getDatasetsFromOurPositions(points, fuelSettings) {
    const { allValues } = points;

    const result: TDatasetsFromOurPositions = {
      labels: [],
      speed: [],
      distSumm: [],
      gps: [],
      gsm: [],
      kren: [],
      tang: [],
      rpm: [],
      pwr: [],
      bat: [],
      engine_time: [],
      active_time: [],
      hirpm_notactime: [],
      moto1Fill: [],
      levels: {},
      required: [],
    };

    let curSpeed = 0;

    const levels = result.levels;
    const leveCount = 10;
    const levelsDesc = [
      { purpose: 'consumption', purposeText: 'расходный' },
      { purpose: 'cistern', purposeText: 'цистерна' },
    ];

    const intervalMoto1 = {
      value: 0,
      prev: false,
      prevTime: 0,
    };

    let calculateEngineTime = initCalculatingByDay();
    let calculateActiveTime = initCalculatingByDay();

    let prevGps = 0;

    allValues.forEach((values, index) => {
      const {
        time,
        speed,
        distSumm: distSummOrigin,
        gsm = null,
        gps = null,
        rpm,
        kren,
        tang,
        pwr,
        bat,
        moto_mask,
        engine_time,
        active_time,
        hirpm_notactime,
        is_last_day_pos,
      } = values;

      result.required[index] = { val: false, because: '' };

      if (speed < 2500) {
        // 250 км/ч
        curSpeed = speed;
      }

      if (intervalMoto1.prev) {
        intervalMoto1.value += time - intervalMoto1.prevTime;
      }

      const moto1isWork = Boolean(moto_mask & 1);

      if (intervalMoto1.prev !== moto1isWork) {
        result.required[index - 1] = { val: true, because: 'moto1Fill - 1' };
        result.required[index] = { val: true, because: 'moto1Fill' };
      }

      result.labels[index] = new Date(time);

      result.engine_time[index] =
        calculateEngineTime(index, engine_time, is_last_day_pos) / 3600;

      result.active_time[index] =
        calculateActiveTime(index, active_time, is_last_day_pos) / 3600;

      result.hirpm_notactime[index] = hirpm_notactime / 3600;

      result.speed[index] = curSpeed / 10;
      result.distSumm[index] = distSummOrigin / 1000;
      if (gps !== null) {
        result.gps[index] = gps;
        if (gps !== prevGps) {
          result.required[index - 1] = { val: true, because: 'gps - 1' };
          result.required[index] = { val: true, because: 'gps' };
          prevGps = gps;
        }
      }
      if (gsm !== null) {
        result.gsm[index] = gsm;
      }
      result.rpm[index] = rpm > -1 ? rpm : 0;
      result.kren[index] = kren / 1000;
      result.tang[index] = tang / 1000;
      result.pwr[index] = pwr / 1000;
      result.bat[index] = bat / 1000;
      result.moto1Fill[index] = {
        y: moto1isWork,
        interval: intervalMoto1.value,
      };

      intervalMoto1.prev = moto1isWork;
      intervalMoto1.prevTime = time;

      levelsDesc.forEach((lvDesc) => {
        const { purpose, purposeText } = lvDesc;

        for (let ii = 0; ii < leveCount; ii++) {
          const lvNum = ii + 1;
          const lvName = `${purpose}_${lvNum}`;
          const lvVal = values[`original_${lvName}`] ?? false;

          if (lvVal === false || (lvVal === null && !(lvName in levels))) {
            continue;
          }

          const lvStatus = values[`status_${lvName}`];
          const lvAnalyzed = values[`analyzed_${lvName}`];

          if (!(lvName in levels)) {
            const summText = lvStatus & 8 ? ' (сумма)' : '';

            let text = '';

            if (!!fuelSettings) {
              for (const settingKey in fuelSettings) {
                for (const setting of fuelSettings[settingKey]) {
                  const { navigator } = setting;
                  if (settingKey === purpose && navigator.num === lvNum) {
                    text = ucFirst_helper(navigator.name);
                    break;
                  }
                }
              }
            }

            if (!text) text = `ДУТ ${purposeText} ${lvNum}${summText}`;

            levels[lvName] = {
              oiginal: [],
              analyzed: [],
              status: [],
              text,
              purpose,
            };
          }

          const level = levels[lvName];
          level.oiginal[index] = lvVal !== null ? lvVal / 1000 : lvVal;
          level.analyzed[index] =
            lvAnalyzed !== null ? lvAnalyzed / 1000 : lvAnalyzed;
          level.status[index] = lvStatus;

          if (lvStatus & 16 || lvStatus & 64) {
            result.required[index - 1] = { val: true, because: 'status - 1' };
            result.required[index] = { val: true, because: 'status' };
          }
        }
      });
    });

    return result;
  },
  charts: {
    engineWorking: (labels, moto1Fill, isStartHidden) => ({
      type: 'areaFull',
      name: 'engineWorking',
      data: moto1Fill.map((val, index) => {
        const { y, interval } = val;
        return {
          x: labels[index],
          y,
          interval,
        };
      }),
      isStartHidden,
      colorStroke: 'rgba(68, 255, 0, 0.2)',
      colorFill: 'rgba(68, 255, 0, 0.2)',
      strokeWidth: 1.5,
      opacity: 0.4,
      opacityFill: 0.4,
      isLine: false,
      isAreaFull: true,
      isBezierCurve: false,
      label: 'Работа ДВС (заливка):',
      xAxis: 'xDateTime',
      yAxis: 'ySpeed',
      legendValueSymbolsCount: 8,
      legendValueFormat: ({ data, index }) => {
        const { y: value = null } = data[index] ?? {};
        if (value === null || isNaN(value)) {
          return ' -';
        }
        return value ? 'работает' : 'выключен';
      },
      selectionValuesFunction({ data, indexBegin, indexEnd, label }) {
        const length = data.length;
        if (indexBegin > length - 1 || indexEnd > length - 1) {
          return `${label} -`;
        }

        const interval = data[indexEnd].interval - data[indexBegin].interval;
        const intervalFormatted = formatTimeHelper(
          interval / 86400000,
          'dd - hh:nn:ss',
        );
        return `${label} ${intervalFormatted}`;
      },
    }),
    speed: (labels, speed, isStartHidden) => ({
      type: 'line',
      name: 'speed',
      data: speed.map((val, index) => ({
        x: labels[index],
        y: val,
      })),
      colorStroke: '#000000',
      strokeWidth: 1,
      opacity: 0.75,
      label: 'Скорость:',
      isStartHidden,
      legendValueFormat: ({ data, index }) => {
        const { y: value = null } = data[index] ?? {};
        if (value === null || isNaN(value)) {
          return ' -';
        }
        return value + ' (км/ч)';
      },
      selectionValuesFunction: ({ chart, data, indexBegin, indexEnd }) => {
        const title = 'Средняя скорость:';
        const maxIndex = data.length - 1;

        const speedChart =
          chart.charts.find((item: any) => item.name === 'speed') || {};
        const { data: speedData = [] } = speedChart;
        const speedMaxIndex = speedData.length - 1;

        if (
          indexBegin > maxIndex ||
          indexEnd > maxIndex ||
          indexBegin > speedMaxIndex ||
          indexEnd > speedMaxIndex
        ) {
          return `${title} -`;
        }

        const periodData = speedData.slice(indexBegin, indexEnd);
        const sumSpeed = periodData.reduce((acc: number, cur: {y:number}) => {
          return acc + cur['y'];
        }, 0);

        if (periodData.length < 1) {
          return `${title} 0 (км/ч)`;
        }

        const average = sumSpeed / periodData.length;

        return `${title} ${Math.round(100 * average) / 100} (км/ч)`;
      },
      xAxis: 'xDateTime',
      yAxis: 'ySpeed',
      isDot: false,
      isLine: true,
      isAreaFull: false,
      isBezierCurve: false,
      legendValueSymbolsCount: 8,
    }),
    rpm: (labels, rpm, isStartHidden) => ({
      type: 'line',
      name: 'rpm',
      label: 'Обороты ДВС:',
      yAxis: 'yRpm',
      xAxis: 'xDateTime',
      strokeWidth: 1,
      // colorStroke: '#006400',
      colorStroke: '#FFD700',
      opacity: 0.75,
      isLine: true,
      data: rpm.map((val, index) => ({
        x: labels[index],
        y: val,
      })),
      isStartHidden,
      legendValueFormat: ({ data, index }: TLegendValueFormatArgs) => {
        const { y: value = null } = data[index] ?? {};
        if (value === null || isNaN(value)) {
          return ' -';
        }
        return value + ' (об/мин)';
      },
      isAreaFull: false,
      isBezierCurve: false,
      isSelectionValues: false,
      legendValueSymbolsCount: 14,
    }),
    gps: (labels, gps, isStartHidden) => ({
      type: 'line',
      name: 'gps',
      label: 'ГЛОНАСС:',
      yAxis: 'yGpsGsmPwr',
      xAxis: 'xDateTime',
      strokeWidth: 1,
      colorStroke: '#C71585',
      opacity: 0.75,
      isLine: true,
      isBezierCurve: false,
      isAreaFull: false,
      data: gps.map((val, index) => ({
        x: labels[index],
        y: val,
      })),
      isStartHidden,
      legendValueFormat: ({ data, index }: TLegendValueFormatArgs) => {
        const { y: value = null } = data[index] ?? {};
        if (value === null || isNaN(value)) {
          return ' -';
        }
        return value + ' (ед.)';
      },
      isSelectionValues: false,
      legendValueSymbolsCount: 6,
    }),
    gsm: (labels, gsm, isStartHidden) => ({
      type: 'line',
      name: 'gsm',
      label: 'GSM:',
      yAxis: 'yGpsGsmPwr',
      xAxis: 'xDateTime',
      strokeWidth: 1,
      colorStroke: '#00FF7F',
      opacity: 0.75,
      isLine: true,
      isAreaFull: false,
      isBezierCurve: false,
      data: gsm.map((val, index) => ({
        x: labels[index],
        y: val,
      })),
      isStartHidden,
      legendValueFormat: ({ data, index }: TLegendValueFormatArgs) => {
        const { y: value = null } = data[index] ?? {};
        if (value === null || isNaN(value)) {
          return ' -';
        }
        return value + ' (у.е.)';
      },
      isSelectionValues: false,
      legendValueSymbolsCount: 6,
    }),
    kren: (labels, kren, isStartHidden) => ({
      type: 'line',
      name: 'kren',
      label: 'Крен:',
      yAxis: 'yGpsGsmPwr',
      xAxis: 'xDateTime',
      strokeWidth: 1,
      colorStroke: '#778899',
      opacity: 0.75,
      fill: false,
      isLine: true,
      isAreaFull: false,
      isBezierCurve: false,
      data: kren.map((val, index) => ({
        x: labels[index],
        y: val,
      })),
      isStartHidden,
      legendValueFormat: ({ data, index }: TLegendValueFormatArgs) => {
        const { y: value = null } = data[index] ?? {};
        if (value === null || isNaN(value)) {
          return ' -';
        }
        return Math.round(value * 100) / 100 + ' (град)';
      },
      selectionValuesFunction: (
        argumentsObject: TSelectionValuesByAngleArgs,
      ) => {
        return selectionValuesByAngle(argumentsObject);
      },
      legendValueSymbolsCount: 10,
    }),
    tang: (labels, tang, isStartHidden) => ({
      type: 'line',
      name: 'tang',
      label: 'Тангаж:',
      yAxis: 'yGpsGsmPwr',
      xAxis: 'xDateTime',
      strokeWidth: 1,
      colorStroke: '#FA8072',
      opacity: 0.75,
      isLine: true,
      isAreaFull: false,
      isBezierCurve: false,
      data: tang.map((val, index) => ({
        x: labels[index],
        y: val,
      })),
      isStartHidden,
      legendValueFormat: ({ data, index }: TLegendValueFormatArgs) => {
        const { y: value = null } = data[index] ?? {};
        if (value === null || isNaN(value)) {
          return ' -';
        }
        return Math.round(value * 100) / 100 + ' (град)';
      },
      selectionValuesFunction: (
        argumentsObject: TSelectionValuesByAngleArgs,
      ) => {
        return selectionValuesByAngle(argumentsObject);
      },
      legendValueSymbolsCount: 10,
    }),
    pwr: (labels, pwr, isStartHidden) => ({
      type: 'line',
      name: 'pwr',
      label: 'Борт. напряжение:',
      yAxis: 'yGpsGsmPwr',
      xAxis: 'xDateTime',
      strokeWidth: 1,
      colorStroke: '#008000',
      opacity: 0.75,
      isLine: true,
      isAreaFull: false,
      isBezierCurve: false,
      data: pwr.map((val, index) => ({
        x: labels[index],
        y: val,
      })),
      isStartHidden,
      legendValueFormat: ({ data, index }: TLegendValueFormatArgs) => {
        const { y: value = null } = data[index] ?? {};
        if (value === null || isNaN(value)) {
          return ' -';
        }
        return Math.round(value * 100) / 100 + ' (В)';
      },
      isSelectionValues: false,
      legendValueSymbolsCount: 6,
    }),
    bat: (labels, bat, isStartHidden) => ({
      type: 'line',
      name: 'bat',
      label: 'Внутр. заряд:',
      yAxis: 'yGpsGsmPwr',
      xAxis: 'xDateTime',
      strokeWidth: 1,
      colorStroke: '#32CD32',
      opacity: 0.75,
      isLine: true,
      isAreaFull: false,
      isBezierCurve: false,
      data: bat.map((val, index) => ({
        x: labels[index],
        y: val,
      })),
      isStartHidden,
      legendValueFormat: ({ data, index }: TLegendValueFormatArgs) => {
        const { y: value = null } = data[index] ?? {};
        if (value === null || isNaN(value)) {
          return ' -';
        }
        return Math.round(value * 100) / 100 + ' (В)';
      },
      isSelectionValues: false,
      legendValueSymbolsCount: 6,
    }),
    engine_time: (labels, engine_time, isStartHidden) => ({
      type: 'line',
      name: 'engine_time',
      data: engine_time.map((val, index) => {
        return {
          x: labels[index],
          y: val,
        };
      }),
      colorStroke: '#0000ff',
      strokeWidth: 1,
      opacity: 0.75,
      label: 'Работа ДВС:',
      legendValueFormat: ({ data, index }: { data: any; index: number }) => {
        const { y: value = null } = data[index] ?? {};
        if (value === null || isNaN(value)) {
          return ' -';
        }
        return myRoundNumber_helper(value, 1) + ' (час)';
      },
      isStartHidden,
      xAxis: 'xDateTime',
      yAxis: 'yHours',
      isDot: false,
      isLine: true,
      isAreaFull: false,
      isBezierCurve: false,
      labelWidth: 120,
      legendValueSymbolsCount: 8,
    }),
    active_time: (labels, active_time, isStartHidden) => ({
      type: 'line',
      name: 'active_time',
      data: active_time.map((val, index) => ({
        x: labels[index],
        y: val,
      })),
      colorStroke: '#ff0000',
      strokeWidth: 2,
      opacity: 0.75,
      label: 'Активная работа (по инклинометру):',
      legendValueFormat: ({ data, index }: { data: any; index: number }) => {
        const { y: value = null } = data[index] ?? {};
        if (value === null || isNaN(value)) {
          return ' -';
        }
        return myRoundNumber_helper(value, 1) + ' (час)';
      },
      isStartHidden,
      xAxis: 'xDateTime',
      yAxis: 'yHours',
      isDot: false,
      isLine: true,
      isAreaFull: false,
      isBezierCurve: true,
      legendValueSymbolsCount: 8,
    }),
    hirpm_notactime: (labels, hirpm_notactime, isStartHidden) => ({
      type: 'line',
      name: 'hirpm_notactime',
      data: hirpm_notactime.map((val, index) => {
        return {
          x: labels[index],
          y: val,
        };
      }),
      colorStroke: '#000000',
      strokeWidth: 1,
      opacity: 0.75,
      label: 'Повышенные обороты без активной работы:',
      legendValueFormat: ({ data, index }: { data: any; index: number }) => {
        const { y: value = null } = data[index] ?? {};
        if (value === null || isNaN(value)) {
          return ' -';
        }
        return myRoundNumber_helper(value, 1) + ' (час)';
      },
      isStartHidden,
      xAxis: 'xDateTime',
      yAxis: 'yHours',
      isDot: false,
      isLine: true,
      isBar: false,
      isAreaFull: false,
      isBezierCurve: false,
      legendValueSymbolsCount: 8,
    }),
    distance: (labels, distSumm, isStartHidden) => ({
      label: 'Пробег:',
      type: 'line',
      name: 'distance',
      data: distSumm.map((val, index) => ({
        x: labels[index],
        y: val,
      })),
      isStartHidden,
      legendValueFormat: ({ data, index }: TLegendValueFormatArgs) => {
        const { y: value = null } = data[index] ?? {};
        if (value === null || isNaN(value)) {
          return ' -';
        }
        return Math.round(value * 100) / 100 + ' (км)';
      },
      strokeWidth: 2,
      colorStroke: '#F4A460',
      opacity: 0.75,
      yAxis: 'yDistance',
      xAxis: 'xDateTime',
      isLine: true,
      isDot: false,
      isAreaFull: false,
      isBezierCurve: false,
      legendValueSymbolsCount: 12,
    }),
    consumptionAndAnalyzedCharts: (labels, levels, isStartHidden) => {
      const charts: TChart<TLevelPosData[]>[] = [];

      for (const lvPurposeNum in levels) {
        const { oiginal, analyzed, status, text, purpose } =
          levels[lvPurposeNum];
        const colorStroke = purpose === 'consumption' ? '#000080' : '#000000';

        [oiginal, analyzed].forEach((levelValues, idx) => {
          const originText = idx ? '' : ' исх.';
          const isSelectionValues = Boolean(idx);
          const strokeWidth = idx ? 2 : 1;
          const lvInOut = { in: 0, out: 0 };

          let prevVal: number;

          charts.push({
            type: 'line',
            name: `${lvPurposeNum}`,
            opacity: 0.75,
            isAreaFull: false,
            label: `${text}${originText}:`,
            isStartHidden,
            yAxis: 'yLevelConsumption',
            xAxis: 'xDateTime',
            strokeWidth,
            isLine: true,
            colorStroke,
            isBezierCurve: false,
            data: levelValues.map((val, index) => {
              const lvStatus = status[index];
              const posValues: TLevelPosData = {
                x: labels[index],
                y: val,
              };

              if (idx && lvStatus & 16) {
                const lvIn = val - prevVal;
                lvInOut.in += lvIn;
                posValues.text = (Math.round(10 * lvIn) / 10).toString();
                posValues.textPosition = 'top';
                posValues.bgColor = '#3CB371';
                posValues.textColor = 'white';
              }

              if (idx && lvStatus & 64) {
                const lvOut = val - prevVal;
                lvInOut.out += lvOut;
                posValues.text = (Math.round(10 * lvOut) / 10).toString();
                posValues.textPosition = 'bottom';
                posValues.bgColor = '#B22222';
                posValues.textColor = 'white';
              }

              posValues.lvIn = lvInOut.in;
              posValues.lvOut = lvInOut.out;

              prevVal = val;

              return posValues;
            }),
            legendValueSymbolsCount: 16,
            legendValueFormat: ({ data, index }) => {
              const { y: value = null } = data[index] ?? {};
              if (value === null || isNaN(value)) {
                return ' -';
              }
              return Math.round(value * 10) / 10 + ' (л)';
            },
            isSelectionValues,
            selectionValuesFunction: ({
              data,
              indexBegin,
              indexEnd,
              label,
            }) => {
              const length = data.length;
              if (indexBegin > length - 1 || indexEnd > length - 1) {
                return `${label} -`;
              }

              const dataBegin = data[indexBegin] ?? null;
              const dataEnd = data[indexEnd] ?? null;

              if (dataBegin === null || dataEnd === null) {
                return `${label} -`;
              }

              const lvIn = Number(dataEnd.lvIn) - Number(dataBegin.lvIn);
              const lvOut = Number(dataEnd.lvOut) - Number(dataBegin.lvOut);

              const expence = dataBegin.y - dataEnd.y + lvIn + lvOut;
              const expenceFormatted = Math.round(100 * expence) / 100;
              const lvInFormatted = Math.round(100 * lvIn) / 100;
              const lvOutFormatted = Math.round(100 * lvOut) / 100;

              return `${label} ${expenceFormatted} / ${lvInFormatted} / ${lvOutFormatted} (расход/заправка/слив, л)`;
            },
          });
        });
      }

      return charts;
    },
  },
  axes: {
    xDateTime: (minMax, chartLabels = []) => ({
      id: 'xDateTime',
      label: 'Дата / время',
      min: minMax.min,
      max: minMax.max,
      isTicks: true,
      isTime: true,
      tickFormat: (date: Date) => {
        if (date.getHours() || date.getMinutes() || date.getSeconds()) {
          return formatDateHelper(date, 'hh:nn');
        }

        return formatDateHelper(date, 'dd.mm.yy');
      },
      titleFormat: (date: Date) => {
        return formatDateHelper(date, 'hh:nn:ss dd.mm.yy');
      },
      titleIntervalFormat: (milliseconds: number) => {
        return formatTimeHelper(milliseconds / 86400000, 'dd - hh:nn:ss');
      },
    }),
    ySpeed: (chartLabels = []) => ({
      id: 'ySpeed',
      label: 'Км/ч',
      isTicks: true,
      tooltipHtml: getAxisTooltipHtml(chartLabels),
    }),
    yDistance: (chartLabels = []) => ({
      id: 'yDistance',
      label: 'Км',
      orientText: 'right',
      orientScale: 'right',
      isTicks: true,
      isGrids: false,
      tooltipHtml: getAxisTooltipHtml(chartLabels),
    }),
    yGpsGsmPwr: (chartLabels = []) => ({
      id: 'yGpsGsmPwr',
      label: 'Вольт',
      orientText: 'left',
      orientScale: 'left',
      isTicks: true,
      isGrids: false,
      tooltipHtml: getAxisTooltipHtml(chartLabels),
    }),
    yRpm: (chartLabels = []) => ({
      id: 'yRpm',
      label: 'Об/мин',
      orientText: 'right',
      orientScale: 'right',
      isTicks: true,
      isGrids: false,
      tooltipHtml: getAxisTooltipHtml(chartLabels),
    }),
    yLevelConsumption: (chartLabels = []) => ({
      id: 'yLevelConsumption',
      label: 'Литр',
      orientText: 'right',
      orientScale: 'right',
      isTicks: true,
      isGrids: false,
      tooltipHtml: getAxisTooltipHtml(chartLabels),
    }),
    yHours: (chartLabels = []) => ({
      id: 'yHours',
      label: 'Час',
      orientText: 'left',
      orientScale: 'left',
      isTicks: true,
      isGrids: false,
      tooltipHtml: getAxisTooltipHtml(chartLabels),
    }),
  },
};

function getAxisTooltipHtml(chartLabels: string[]) {
  return chartLabels.join(',</br>').replaceAll(':', '') || ' - ';
}

export function selectionValuesByAngle({
  data = [],
  indexBegin,
  indexEnd,
  label,
}: TSelectionValuesByAngleArgs) {
  const { min = null, max = null } = minMaxOfDataCalculate({
    data,
    indexBegin,
    indexEnd,
    key: 'y',
  });
  if (min === null || max === null) {
    return `${label} -`;
  }

  const dispersion = max - min;
  return `${label} min: ${Math.round(min * 100) / 100}, max: ${
    Math.round(max * 100) / 100
  }, разброс: ${Math.round(dispersion * 100) / 100} (град)`;
}

export function minMaxOfDataCalculate({
  data,
  indexBegin,
  indexEnd,
  key = 'y',
}: {
  data: TAxesData;
  indexBegin: number;
  indexEnd: number;
  key: string;
}) {
  const minMax: { min?: any; max?: any } = {};
  for (let i = indexBegin; i < indexEnd + 1 && i < data.length - 1; i++) {
    const val = data[i][key];
    if (i === indexBegin) {
      minMax.min = val;
      minMax.max = val;
      continue;
    }
    if (val > minMax.max) {
      minMax.max = val;
    }
    if (val < minMax.min) {
      minMax.min = val;
    }
  }

  return minMax;
}
